import { all, takeLatest } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './constants'
import { BASE_URL, request } from '../../utils/http'
import { sagasRunner } from '../../utils/redux'

function getProfile () {
  return request.get('/api/user/profile/')
}

function handleGetProfile () {
  return sagasRunner({
    successType: actions.APP_GET_PROFILE_SUCCESS,
    errorType: actions.APP_GET_PROFILE_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: 'Unable to get profile data.',
    callFunc: getProfile,
    alertError: false,
    isProfile: true
  })
}

function addUsersAccount ({values}) {
  return request.post('/api/user/', values)
}

function handleAddUsersAccount ({values}) {
  return sagasRunner({
    successType: actions.APP_ADD_USERS_SUCCESS,
    errorType: actions.APP_ADD_USERS_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: 'Unable to get profile data.',
    callFunc: addUsersAccount,
    updateType: actions.APP_GET_ALL_USERS_REQUEST,
    callData: { values },
    alertError: false,
    isProfile: true
  })
}

function getAllUsers () {
  return request.get('/api/user/')
}

function handleGetAllUsers () {
  return sagasRunner({
    successType: actions.APP_GET_ALL_USERS_SUCCESS,
    errorType: actions.APP_GET_ALL_USERS_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: 'Unable to get profile data.',
    callFunc: getAllUsers,
    alertError: false,
    isProfile: true
  })
}

function editUser ({values}) {
  return request.patch(`/api/user/${values?.userId}`, values)
}

function handleEditUser ({values}) {
  return sagasRunner({
    successType: actions.APP_EDIT_USERS_SUCCESS,
    errorType: actions.APP_EDIT_USERS_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: 'Unable to get profile data.',
    callFunc: editUser,
    callData: { values },
    updateType: actions.APP_GET_ALL_USERS_REQUEST,
    alertError: false,
    isProfile: true
  })
}

function deleteUser ({values}) {
  return request.delete(`/api/user/${values?.userId}`)
}

function handleDeleteUser ({values}) {
  return sagasRunner({
    successType: actions.APP_DELETE_USERS_SUCCESS,
    errorType: actions.APP_DELETE_USERS_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: 'Unable to get profile data.',
    callFunc: deleteUser,
    callData: { values },
    updateType: actions.APP_GET_ALL_USERS_REQUEST,
    alertError: false,
    isProfile: true
  })
}

function resetUserPassword ({values}) {
  return request.post('/api/user/reset-password', values)
}

function handleResetUserPassword ({values}) {
  return sagasRunner({
    successType: actions.APP_RESET_PASSWORD_SUCCESS,
    errorType: actions.APP_RESET_PASSWORD_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: 'Unable to get profile data.',
    callFunc: resetUserPassword,
    callData: { values },
    updateType: actions.APP_GET_ALL_USERS_REQUEST,
    alertError: false,
    isProfile: true
  })
}

function addServices ({values}) {
  return request.post('/api/service/', values)
}

function handleAddServices ({values}) {
  return sagasRunner({
    successType: actions.APP_ADD_SERVICES_SUCCESS,
    errorType: actions.APP_ADD_SERVICES_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: 'Unable to get profile data.',
    callFunc: addServices,
    callData: { values },
    alertError: false,
    isProfile: true
  })
}

function deleteServices ({values}) {
  return request.delete(`/api/service/${values?.serviceId}`)
}

function handleDeleteServices ({values}) {
  return sagasRunner({
    successType: actions.APP_DELETE_SERVICES_SUCCESS,
    errorType: actions.APP_DELETE_SERVICES_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: 'Unable to get profile data.',
    callFunc: deleteServices,
    callData: { values },
    alertError: false,
    isProfile: true
  })
}

function editServices ({values}) {
  return request.patch(`/api/service/${values?.serviceId}`, values)
}

function handleEditServices ({values}) {
  return sagasRunner({
    successType: actions.APP_EDIT_SERVICES_SUCCESS,
    errorType: actions.APP_EDIT_SERVICES_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: 'Unable to get profile data.',
    callFunc: editServices,
    callData: { values },
    alertError: false,
    isProfile: true
  })
}

function addCalcConversion ({values}) {
  return request.post('/api/iaai/', values)
}

function handleAddCalcConversion ({values}) {
  return sagasRunner({
    successType: actions.APP_ADD_CALC_CONVERSION_SUCCESS,
    errorType: actions.APP_ADD_CALC_CONVERSION_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: 'Unable to get profile data.',
    callFunc: addCalcConversion,
    callData: { values },
    alertError: false,
    isProfile: true
  })
}

function editCalcConversion ({values}) {
  return request.patch(`/api/iaai/${values?.id}`, values)
}

function handleEditCalcConversion ({values}) {
  return sagasRunner({
    successType: actions.APP_EDIT_CALC_CONVERSION_SUCCESS,
    errorType: actions.APP_EDIT_CALC_CONVERSION_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: 'Unable to get profile data.',
    callFunc: editCalcConversion,
    callData: { values },
    alertError: false,
    isProfile: true
  })
}

function deleteCalcConversion ({values}) {
  return request.delete(`/api/iaai/${values?.id}`)
}

function handleDeleteCalcConversion ({values}) {
  return sagasRunner({
    successType: actions.APP_DELETE_CALC_CONVERSION_SUCCESS,
    errorType: actions.APP_DELETE_CALC_CONVERSION_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: 'Unable to get profile data.',
    callFunc: deleteCalcConversion,
    callData: { values },
    alertError: false,
    isProfile: true
  })
}

function deleteAuctionsCars ({values}) {
  return request.delete(`/api/auctions/cars/${values?.carsId}`)
}

function handleDeleteAuctionsCars ({values}) {
  return sagasRunner({
    successType: actions.APP_DELETE_AUCTIONS_CARS_SUCCESS,
    errorType: actions.APP_DELETE_AUCTIONS_CARS_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: 'Unable to get profile data.',
    callFunc: deleteAuctionsCars,
    callData: { values },
    alertError: false,
    isProfile: true
  })
}

function addAuctionsCars ({ values, token }) {
  const formKeys = Object.keys(values)
  let data = new FormData();

  formKeys.forEach((k) => {
      data.append(k, values[k]);
    })

  return axios({
    method: 'post',
    url: `${BASE_URL}/api/auctions/cars/`,
    headers: {
      Authorization: token,
      'Content-Type': 'multipart/form-data'
    },
    data,
  });
}

function handleAddAuctionsCars ({ values }) {
  return sagasRunner({
    successType: actions.APP_ADD_AUCTIONS_CARS_SUCCESS,
    errorType: actions.APP_ADD_AUCTIONS_CARS_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    alertError: false,
    sendToken: true,
    callFunc: addAuctionsCars,
    callData: { values },
    isProfile: true
  });
}


function editAuctionsCars ({ values, token }) {
  const formKeys = Object.keys(values)
  let data = new FormData();

  formKeys.forEach((k) => {
      data.append(k, values[k]);
    })

  return axios({
    method: 'patch',
    url: `${BASE_URL}/api/auctions/cars/${values?.id}`,
    headers: {
      Authorization: token,
      'Content-Type': 'multipart/form-data'
    },
    data,
  });
}

function handleEditAuctionsCars ({ values }) {
  return sagasRunner({
    successType: actions.APP_EDIT_AUCTIONS_CARS_SUCCESS,
    errorType: actions.APP_EDIT_AUCTIONS_CARS_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    alertError: false,
    sendToken: true,
    callFunc: editAuctionsCars,
    callData: { values },
    isProfile: true
  });
}

function editPageContent ({ values, token, id }) {
  let data = new FormData();

  function appendFormData(data, formData, parentKey) {
    if (typeof data === 'object' && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        const newKey = parentKey ? `${parentKey}.${key}` : key;
        appendFormData(data[key], formData, newKey);
      });
    } else {
      formData.append(parentKey, data);
    }
  }

  appendFormData(values, data);
  return axios({
    method: 'patch',
    url: `${BASE_URL}/api/pageTexts/${id}`,
    headers: {
      Authorization: token,
      'Content-Type': 'multipart/form-data'
    },
    data,
  });
}

function handleEditPageContent ({ values, id }) {
  return sagasRunner({
    successType: actions.APP_EDIT_PAGES_CONTENT_SUCCESS,
    errorType: actions.APP_EDIT_PAGES_CONTENT_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    alertError: false,
    sendToken: true,
    callFunc: editPageContent,
    callData: { values, id },
    isProfile: true
  });
}

function getClientCars () {
  return request.get('/api/client/cars/')
}

function handleGetClientCars () {
  return sagasRunner({
    successType: actions.APP_GET_CLIENT_CARS_SUCCESS,
    errorType: actions.APP_GET_CLIENT_CARS_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: 'Unable to get profile data.',
    callFunc: getClientCars,
    alertError: false,
    isProfile: true
  })
}

function addClientCars ({ values, token }) {
  let data = new FormData();

  function appendFormData(data, formData, parentKey) {
    if (typeof data === 'object' && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        const newKey = parentKey ? `${parentKey}.${key}` : key;
        appendFormData(data[key], formData, newKey);
      });
    } else {
      formData.append(parentKey, data);
    }
  }

  appendFormData(values, data);

  return axios({
    method: 'post',
    url: `${BASE_URL}/api/client/cars/`,
    headers: {
      Authorization: token,
      'Content-Type': 'multipart/form-data'
    },
    data,
  });
}

function handleAddClientCars ({ values, token }) {
  return sagasRunner({
    successType: actions.APP_ADD_CLIENT_CARS_SUCCESS,
    errorType: actions.APP_ADD_CLIENT_CARS_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: 'Unable to get profile data.',
    callFunc: addClientCars,
    updateType: actions.APP_GET_CLIENT_CARS_REQUEST,
    callData: { values, token },
    alertError: false,
    isProfile: true
  })
}

function deleteClientCars ({ id }) {
  return request.delete(`/api/client/cars/${id}`)
}

function handleDeleteClientCars ({ id }) {
  return sagasRunner({
    successType: actions.APP_DELETE_CLIENT_CARS_SUCCESS,
    errorType: actions.APP_DELETE_CLIENT_CARS_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: 'Unable to get profile data.',
    callFunc: deleteClientCars,
    updateType: actions.APP_GET_CLIENT_CARS_REQUEST,
    callData: { id },
    alertError: false,
    isProfile: true
  })
}


function editClientCars ({ values, token, id }) {
  let data = new FormData();

  function appendFormData(data, formData, parentKey) {
    if (typeof data === 'object' && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        const newKey = parentKey ? `${parentKey}.${key}` : key;
        appendFormData(data[key], formData, newKey);
      });
    } else {
      formData.append(parentKey, data);
    }
  }

  appendFormData(values, data);

  return axios({
    method: 'patch',
    url: `${BASE_URL}/api/client/cars/${id}`,
    headers: {
      Authorization: token,
      'Content-Type': 'multipart/form-data'
    },
    data,
  });
}

function handleEditClientCars ({ values, token, id }) {
  return sagasRunner({
    successType: actions.APP_EDIT_CLIENT_CARS_SUCCESS,
    errorType: actions.APP_EDIT_CLIENT_CARS_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: 'Unable to get profile data.',
    callFunc: editClientCars,
    updateType: actions.APP_GET_CLIENT_CARS_REQUEST,
    callData: { values, token, id },
    alertError: false,
    isProfile: true
  })
}

function addLocalPlace ({values}) {
  return request.post('/api/calculator/localplace', values)
}

function handleAddLocalPlace ({values}) {
  return sagasRunner({
    successType: actions.APP_ADD_LOCAL_PLACE_SUCCESS,
    errorType: actions.APP_ADD_LOCAL_PLACE_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: 'Unable to get profile data.',
    callFunc: addLocalPlace,
    callData: { values },
    alertError: false,
    isProfile: true
  })
}

function editLocalPlace ({values}) {
  return request.patch(`/api/calculator/localplace/${values?.id}`, values)
}

function handleEditLocalPlace ({values}) {
  return sagasRunner({
    successType: actions.APP_EDIT_LOCAL_PLACE_SUCCESS,
    errorType: actions.APP_EDIT_LOCAL_PLACE_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: 'Unable to get profile data.',
    callFunc: editLocalPlace,
    callData: { values },
    alertError: false,
    isProfile: true
  })
}

function deleteLocalPlace ({values}) {
  return request.delete(`/api/calculator/localplace/${values?.id}`)
}

function handleDeleteLocalPlace ({values}) {
  return sagasRunner({
    successType: actions.APP_DELETE_LOCAL_PLACE_SUCCESS,
    errorType: actions.APP_DELETE_LOCAL_PLACE_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: 'Unable to get profile data.',
    callFunc: deleteLocalPlace,
    callData: { values },
    alertError: false,
    isProfile: true
  })
}

function addCalcCopart ({values}) {
  return request.post('/api/copart/', values)
}

function handleAddCalcCopart ({values}) {
  return sagasRunner({
    successType: actions.APP_ADD_COPART_SUCCESS,
    errorType: actions.APP_ADD_COPART_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: 'Unable to get profile data.',
    callFunc: addCalcCopart,
    callData: { values },
    alertError: false,
    isProfile: true
  })
}

function editCalcCopart ({values}) {
  return request.patch(`/api/copart/${values?.id}`, values)
}

function handleEditCalcCopart ({values}) {
  return sagasRunner({
    successType: actions.APP_EDIT_COPART_SUCCESS,
    errorType: actions.APP_EDIT_COPART_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: 'Unable to get profile data.',
    callFunc: editCalcCopart,
    callData: { values },
    alertError: false,
    isProfile: true
  })
}

function deleteCalcCopart ({values}) {
  return request.delete(`/api/copart/${values?.id}`)
}

function handleDeleteCalcCopart ({values}) {
  return sagasRunner({
    successType: actions.APP_DELETE_COPART_SUCCESS,
    errorType: actions.APP_DELETE_COPART_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: 'Unable to get profile data.',
    callFunc: deleteCalcCopart,
    callData: { values },
    alertError: false,
    isProfile: true
  })
}

function editConfigsSettings ({id, values}) {
  return request.patch(`/api/web/settings/${id}`, values)
}

function handleEditConfigSettings ({id, values}) {
  return sagasRunner({
    successType: actions.APP_CONFIG_SETTINGS_SUCCESS,
    errorType: actions.APP_CONFIG_SETTINGS_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: 'Unable to get profile data.',
    callFunc: editConfigsSettings,
    callData: { id, values },
    alertError: false,
    isProfile: true
  })
}

export default all([
  takeLatest(actions.APP_GET_PROFILE_REQUEST, handleGetProfile),
  takeLatest(actions.APP_ADD_USERS_REQUEST, handleAddUsersAccount),
  takeLatest(actions.APP_GET_ALL_USERS_REQUEST, handleGetAllUsers),
  takeLatest(actions.APP_EDIT_USERS_REQUEST, handleEditUser),
  takeLatest(actions.APP_DELETE_USERS_REQUEST, handleDeleteUser),
  takeLatest(actions.APP_RESET_PASSWORD_REQUEST, handleResetUserPassword),
  takeLatest(actions.APP_ADD_SERVICES_REQUEST, handleAddServices),
  takeLatest(actions.APP_DELETE_SERVICES_REQUEST, handleDeleteServices),
  takeLatest(actions.APP_EDIT_SERVICES_REQUEST, handleEditServices),
  takeLatest(actions.APP_ADD_CALC_CONVERSION_REQUEST, handleAddCalcConversion),
  takeLatest(actions.APP_EDIT_CALC_CONVERSION_REQUEST, handleEditCalcConversion),
  takeLatest(actions.APP_DELETE_CALC_CONVERSION_REQUEST, handleDeleteCalcConversion),
  takeLatest(actions.APP_ADD_AUCTIONS_CARS_REQUEST, handleAddAuctionsCars),
  takeLatest(actions.APP_DELETE_AUCTIONS_CARS_REQUEST, handleDeleteAuctionsCars),
  takeLatest(actions.APP_EDIT_AUCTIONS_CARS_REQUEST, handleEditAuctionsCars),
  takeLatest(actions.APP_EDIT_PAGES_CONTENT_REQUEST, handleEditPageContent),
  takeLatest(actions.APP_GET_CLIENT_CARS_REQUEST, handleGetClientCars),
  takeLatest(actions.APP_ADD_CLIENT_CARS_REQUEST, handleAddClientCars),
  takeLatest(actions.APP_DELETE_CLIENT_CARS_REQUEST, handleDeleteClientCars),
  takeLatest(actions.APP_EDIT_CLIENT_CARS_REQUEST, handleEditClientCars),
  takeLatest(actions.APP_ADD_LOCAL_PLACE_REQUEST, handleAddLocalPlace),
  takeLatest(actions.APP_DELETE_LOCAL_PLACE_REQUEST, handleDeleteLocalPlace),
  takeLatest(actions.APP_EDIT_LOCAL_PLACE_REQUEST, handleEditLocalPlace),
  takeLatest(actions.APP_ADD_COPART_REQUEST, handleAddCalcCopart),
  takeLatest(actions.APP_EDIT_COPART_REQUEST, handleEditCalcCopart),
  takeLatest(actions.APP_DELETE_COPART_REQUEST, handleDeleteCalcCopart),
  takeLatest(actions.APP_CONFIG_SETTINGS_REQUEST, handleEditConfigSettings)
])
