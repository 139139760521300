import * as actions from './constants'

const initialState = {
  user: null,
  accessToken: null,
  profile: {},
  services: {},
  calculatorConversion: {},
  auctionsCarsList:{},
  pagesContent: {},
  exchangeRate: {},
  localPlace: {},
  copart: {},
  settings: {},
  errors: {
    SignIn: null,
  }
}

export const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.AUTH_GET_SETTINGS_SUCCESS:
      return {
      ...state,
      settings: action.payload,
     }
    case actions.AUTH_GET_COPART_SUCCESS:
      return {
        ...state,
        copart: action.payload,
      }
    case actions.AUTH_GET_LOCAL_PLACE_SUCCESS:
      return {
        ...state,
        localPlace: action.payload,
      }
    case actions.AUTH_GET_EXCHANGE_RATE_SUCCESS:
      return {
        ...state,
        exchangeRate: action.payload,
      }
    case actions.AUTH_GET_ALL_PAGES_CONTENT_SUCCESS:
      return {
        ...state,
        pagesContent: action.payload,
      }
    case actions.AUTH_GET_ALL_AUCTIONS_CARS_SUCCESS:
      return {
        ...state,
        auctionsCarsList: action.payload,
      }
    case actions.AUTH_GET_ALL_CALC_CONVERSION_SUCCESS:
      return {
        ...state,
        calculatorConversion: action.payload,
      }
    case actions.AUTH_GET_ALL_SERVICES_SUCCESS:
      return {
        ...state,
        services: action.payload,
      }
    case actions.AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        accessToken: action.accessToken,
        errors: { SignIn: null }
      }
    case actions.AUTH_LOGIN_ERROR:
      return {
        ...state,
        errors: { SignIn: action.error }
      }
    case actions.AUTH_USER:
      return {
        ...state,
        user: action.user,
        accessToken: action.token
      }
    case actions.AUTH_LOGOUT:
      return initialState
    default:
      return state
  }
}
