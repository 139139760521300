/* eslint-disable no-unused-vars */
import { translate } from "../src/utils/helpers";
import moment from "moment";

// {
//   "Seattle": 2875,
//   "LosAngeles": 2625,
//   "Miami": 2100,
//   "Chicago": 2275,
//   "Norfolk": 2075,
//   "Houston": 2275,
//   "NewYork": 2075,
//   "Savannah": 2075,
// }

export const COPART_FEE = [
  { "-- Choose Copart Location -- ": null },
  { "ABILENE - Texas": 2625 }, // 350 + Houston (2275)
  { "ADELANTO - California": 2925 }, // 300 + LosAngeles
  { "ADP TOWING MAUI - Hawaii": 4775 }, // 2150 + LosAngeles
  { "ALBANY - New York": 2350 }, // 275 + NewYork
  { "ALBUQUERQUE - New Mexico": 2875 }, // 600 + Houston
  { "ALTOONA - Pennsylvania": 2525 }, // 450 + NewYork
  { "AMARILLO - Texas": 2800 }, // 525 + Houston
  { "ANCHORAGE - Alaska": "Anchorage Call ???????????" }, // ??????
  { "ANDREWS - Texas": 2700 }, // 425 + Houston
  { "ANTELOPE - California": 3025 }, // 400 + LosAngeles
  { "APPLETON - Wisconsin": 2600 }, // 325 + Chicago
  { "ATLANTA EAST - Georgia": 2375 }, // 300 + Savannah
  { "ATLANTA NORTH - Georgia": 2400 }, // 325 + Savannah
  { "ATLANTA SOUTH - Georgia": 2350 }, // 275 + Savannah
  { "ATLANTA WEST - Georgia": 2350 }, // 275 + Savannah
  { "AUSTIN - Texas": 2525 }, // 250 + Houston
  { "AUGUSTA - Georgia": 2350 }, // 275 + Savannah
  { "BAKERSFIELD - California": 2975 }, // 350 + LosAngeles
  { "BALTIMORE - Maryland": 2400 }, // 325 + Norfolk
  { "BALTIMORE EAST - Maryland": 2400 }, // 325 + Norfolk
  { "BATON ROUGE - Louisiana": 2500 }, // 425 + Savannah
  { "BILLINGS - Montana": 3725 }, // 850 + Seattle
  { "BIRMINGHAM - Alabama": 2450 }, // 375 + Savannah
  { "BISMARCK - North Dakota": "Chicago Call ?????????????" }, // ?????
  { "BOISE - Idaho": 3325 }, // 450 + Seattle
  { "BUFFALO - New York": 2575 }, // 500 + NewYork
  { "CALGARY - Alberta": 1450 + "TORONTO ?????" }, // ?????
  { "CANDIA - New Hampshire": 2475 }, // 400 + NewYork
  { "CARTERSVILLE - Georgia": 2375 }, // 300 + Savannah
  { "CASPER - Wyoming": "Chicago Call for price" }, // ??????
  { "CHAMBERSBURG - Pennsylvania": 2425 }, // 350 + NewYork
  { "CHARLESTON - West Virginia": 2700 }, // 625 + Norfolk
  { "CHICAGO NORTH - Illinois": 2455 }, // 180 + Chicago
  { "CHICAGO SOUTH - Illinois": 2455 }, // 180 + Chicago
  { "CHICAGO SOUTH-Woodmar/Heights sublot - Illinois": 2455 }, // 180 + Chicago
  { "CHINA GROVE - North Carolina": 2375 }, // 300 + Norfolk
  { "CICERO - Indiana": 2575 }, // 300 + Chicago
  { "CLEVELAND EAST - Ohio": 2625 }, // 550 + Norfolk
  { "CLEVELAND WEST - Ohio": 2625 }, // 550 + Norfolk
  { "COLORADO SPRINGS - Colorado": 2925 }, // 650 + Chicago
  { "COLUMBUS - Ohio": 2625 }, // 550 + Norfolk
  { "CONCORD - North Carolina": 2375 }, // 300 + Norfolk
  { "CORPUS CHRISTI - Texas": 2550 }, // 275 + Houston
  { "CRASHEDTOYS ATLANTA - Georgia": 2350 }, // 275 + Savannah
  { "CRASHEDTOYS DALLAS - Texas": 2500 }, // 225 + Houston
  { "CRASHEDTOYS EAST BETHEL - Minnesota": 2725 }, // 450 + Chicago
  { "CRASHEDTOYS ELDRIDGE - Iowa": 2600 }, // 325 + Chicago
  { "CRASHEDTOYS MINNEAPOLIS - Minnesota": 2725 }, // 450 + Chicago
  { "CRASHEDTOYS SACRAMENTO - California": 2625 + "Empty ????????" }, // ??? + LosAngeles
  { "DALLAS - Texas": 2550 }, // 275 + Houston
  { "DALLAS SOUTH - Texas": 2550 }, // 275 + Houston
  { "DANVILLE - Virginia": 2375 }, // 300 + Norfolk
  { "DAVENPORT - 169 Davenport sublot - Iowa": 2600 }, // 325 + Chicago
  { "DAVENPORT - Iowa": 2600 }, // 325 + Chicago
  { "DAYTON - Ohio": 2625 }, // 550 + Norfolk
  { "DENVER - Colorado": 2925 }, // 650 + Chicago
  { "DENVER CENTRAL - Colorado": 2925 }, // 650 + Chicago
  { "DENVER SOUTH - Colorado": 2925 }, // 650 + Chicago
  { "DES MOINES - Iowa": 2675 }, // 400 + Chicago
  { "DETROIT - Michigan": 2675 }, // 400 + Chicago
  { "DK TOWING KAUAI - Hawaii": 1750 + "Hononulu ????????" }, // ???????/
  { "DK TOWING KAUAI - Hawaii": 5375 }, // 2750 + LosAngeles
  { "DOTHAN - Alabama": 2450 }, // 375 + Savannah
  { "DYER - Indiana": 2455 }, // 180 + Chicago
  { "EARLINGTON - Kentucky": 2750 }, // 475 + Chicago
  { "EDMONTON - Alberta": 1450 + "Toronto ?????????" }, // ???????
  { "EL PASO - Texas": 2700 }, //  425 + Houston
  { "EUGENE - Oregon": 3250 }, // 375 + Seattle
  { "EXETER - Rhode Island": 2425 }, // 350 + NewYork
  { "FAIRBURN - Georgia": 2350 }, // 275 + Savannah
  { "FAYETTEVILLE - Arkansas": 2750 }, // 475 + Houston
  { "FLINT - Michigan": 2725 }, // 450 + Chicago
  { "FORT WAYNE - Indiana": 2575 }, // 300 + Chicago
  { "FREDERICKSBURG - Virginia": 2325 }, // 250 + Norfolk
  { "FREETOWN - Massachusetts": 2450 }, // 375 + NewYork
  { "FRESNO - California": 3000 }, // 375 + LosAngeles
  { "FT. PIERCE - Florida": 2300 }, // 200 + Miami
  { "FT. WORTH - Texas": 2550 }, // 275 + Houston
  { "GASTONIA - North Carolina": 2400 }, // 325 + Norfolk
  { "GLASSBORO EAST - New Jersey": 2275 }, // 200 + NewYork
  { "GLASSBORO WEST - New Jersey": 2275 }, // 200 + NewYork
  { "GRAHAM - Washington": 3025 }, // 150 + Seattle
  { "GREER - South Carolina": 2350 }, // 275 + Savannah
  { "HALIFAX - Nova Scotia": 1100 + "Toronto ?????" }, // ???????
  { "HAMMOND - Indiana": 2455 }, // 180 + Chicago
  { "HAMPTON - Virginia": 2225 }, // 150 + Norfolk
  { "HARRISBURG - Pennsylvania": 2350 }, // 275 + NewYork
  { "HARTFORD - Connecticut": 2300 }, // 225 + NewYork
  { "HARTFORD CITY - Indiana": 2575 }, // 300 + Chicago
  { "HARTFORD SPRINGFIELD - Connecticut": 2325 }, // 250 + NewYork
  { "HAYWARD - California": 3025 }, // 400 + LosAngeles
  { "HELENA - Montana": 3625 }, // 750 + Seattle
  { "HONOLULU - Hawaii": 100 + "Honolulu ???????????" }, // ???????
  { "HOUSTON - Texas": 2455 }, // 180 + Houston
  { "HOUSTON EAST - Texas": 2455 }, // 180 + Houston
  { "INDIANAPOLIS - Indiana": 2575 }, // 300 + Chicago
  { "IONIA - Michigan": 2675 }, // 400 + Chicago
  { "JACKSON - Mississippi": 2500 }, // 425 + Savannah
  { "JACKSONVILLE EAST - Florida": 2300 }, // 225 + Savannah
  { "JACKSONVILLE NORTH - Florida": 2300 }, // 225 + Savannah
  { "JACKSONVILLE WEST - Florida": 2300 }, // 225 + Savannah
  { "KANSAS CITY - Kansas": 2700 }, // 625 + Savannah
  { "KENS TOWING HILO - Hawaii": 4625 }, // 2000 + LosAngeles
  { "KINCHELOE - Michigan": "Chicago Call ?????????????" }, // ???????
  { "KNOXVILLE - Tennessee": 2475 }, // 400 + Savannah
  { "LANSING - Michigan": 2675 }, // 400 + Chicago
  { "LAS VEGAS - Nevada": 2950 }, // 325 + LosAngeles
  { "LEXINGTON EAST - Kentucky": 2700 }, // 425 + Chicago
  { "LEXINGTON WEST - Kentucky": 2700 }, // 425 + Chicago
  { "LINCOLN - Nebraska": 2725 }, // 450 + Chicago
  { "LITTLE ROCK - Arkansas": 2725 }, // 450 + Houston
  { "LONDON - Ontario": 250 + "Toronto ??????" }, // ??????
  { "LONG BEACH - California": 2775 }, // 150 + LosAngeles
  { "LONG ISLAND - New York": 2325 }, // 250 + NewYork
  { "LONGVIEW - Texas": 2550 }, // 275 + Houston
  { "LOS ANGELES - California": 2785 }, // 160 + LosAngeles
  { "LOUISVILLE - Kentucky": 2700 }, // 425 + Chicago
  { "LUFKIN - Texas": 2550 }, // 275 + Houston
  { "LUMBERTON - North Carolina": 2375 }, // 300 + Norfolk
  { "LYMAN - Maine": 2550 }, // 475 + NewYork
  { "MACON - Georgia": 2325 }, // 250 + Savannah
  { "MADISON - Wisconsin": 2575 }, // 300 + Chicago
  { "MADISON SOUTH - Wisconsin": 2575 }, // 300 + Chicago
  { "MARTINEZ - California": 3025 }, // 400 + LosAngeles
  { "MCALLEN - Texas": 2625 }, // 350 + Houston
  { "MEBANE - North Carolina": 2375 }, // 300 + Norfolk
  { "MEMPHIS - Tennessee": 2575 }, // 500 + Savannah
  { "MENTONE - California": 2925 }, // 300 + LosAngeles
  { "MIAMI CENTRAL - Florida": 2225 }, // 125 + Miami
  { "MIAMI NORTH - Florida": 2225 }, // 125 + Miami
  { "MIAMI SOUTH - Florida": 2225 }, // 150 + Miami
  { "MILWAUKEE - Stutervant sublot - Wisconsin": 2500 }, // 225 + Chicago
  { "MILWAUKEE - Waukesha sublot - Wisconsin": 2500 }, // 225 + Chicago
  { "MILWAUKEE - Wisconsin": 2500 }, // 225 + Chicago
  { "MILWAUKEE NORTH - Wisconsin": 2500 }, // 225 + Chicago
  { "MILWAUKEE SOUTH - Wisconsin": 2500 }, // 225 + Chicago
  { "MILWAUKEE- Yard 339 Milwaukee sublot - Wisconsin": 2500 }, // 225 + Chicago
  { "MINNEAPOLIS - Minnesota": 2725 }, // 450 + Chicago
  { "MINNEAPOLIS NORTH - Minnesota": 2725 }, // 450 + Chicago
  { "MO - COLUMBIA - Missouri": 2700 }, // 625 + Savannah
  { "MOBILE - Alabama": 2475 }, // 400 + Savannah
  { "MOBILE SOUTH - Alabama": 2475 }, // 400 + Savannah
  { "MOCKSVILLE - North Carolina": 2375 }, // 300 + Norfolk
  { "MONCTON - New Brunswick": 1200 + "Toronto ????????" }, // ??????
  { "MONTGOMERY - Alabama": 2475 }, // 400 + Savannah
  { "MONTREAL - Quebec": 475 + "Toronto ???????" }, // ??????
  { "N.Boston-ROWLEY Sublot - Massachusetts": 2525 }, // 450 + NewYork
  { "NASHVILLE - Tennessee": 2475 }, // 400 + Savannah
  { "NEW ORLEANS - Louisiana": 2500 }, // 425 + Savannah
  { "NEWBURGH - New York": 2300 }, // 225 + NewYork
  { "NORTH BOSTON - Massachusetts": 2450 }, // 375 + NewYork
  { "NORTH CHARLESTON - South Carolina": 2350 }, // 275 + Savannah
  { "NORTH SEATTLE - Washington": 3075 }, // 200 + Seattle
  { "OCALA - Florida": 2400 }, // 300 + Miami
  { "OGDEN - Utah": 3175 }, // 550 + LosAngeles
  { "OKLAHOMA CITY - Oklahoma": 2675 }, // 400 + Houston
  { "ORLANDO - Florida": 2350 }, // 250 + Miami
  { "ORLANDO NORTH - Florida": 2350 }, // 250 + Miami
  { "ORLANDO SOUTH - Florida": 2350 }, // 250 + Miami
  { "PASCO - Washington": 3225 }, // 350 + Seattle
  { "PEORIA - Illinois": 2575 }, // 300 + Chicago
  { "PHILADELPHIA - Pennsylvania": 2275 }, // 200 + NewYork
  { "PHILADELPHIA EAST-SUBLOT - Pennsylvania": 2275 }, // 200 + NewYork
  { "PHOENIX - Arizona": 2975 }, // 350 + LosAngeles
  { "PITTSBURGH EAST - Pennsylvania": 2475 }, // 400 + NewYork
  { "PITTSBURGH NORTH - Pennsylvania": 2525 }, // 450 + NewYork
  { "PITTSBURGH SOUTH - Pennsylvania": 2525 }, // 450 + NewYork
  { "PITTSBURGH WEST - Pennsylvania": 2525 }, // 450 + NewYork
  { "PORTLAND NORTH - Oregon": 3125 }, // 250 + Seattle
  { "PORTLAND SOUTH - Oregon": 3175 }, // 300 + Seattle
  { "PUNTA GORDA - Florida": 2325 }, // 225 + Miami
  { "PUNTA GORDA SOUTH - Florida": 2375 }, // 275 + Miami
  { "RALEIGH - North Carolina": 2375 }, // 300 + Norfolk
  { "RANCHO CUCAMONGA - California": 2825 }, // 200 + LosAngeles
  { "REDDING - California": 3425 }, // 800 + LosAngeles
  { "RENO - Nevada": 3175 }, // 550 + LosAngeles
  { "RICHMOND - Virginia": 2250 }, // 175 + Norfolk
  { "RICHMOND EAST - Virginia": 2250 }, // 175 + Norfolk
  { "ROCHESTER - New York": 2525 }, // 450 + NewYork
  { "SACRAMENTO - California": 3025 }, // 400 + LosAngeles
  { "SALT LAKE CITY - Utah": 3275 }, // 650 + LosAngeles
  { "SALT LAKE CITY NORTH - Utah": 3175 }, // 650 + LosAngeles
  { "SAN ANTONIO - Texas": 2575 }, // 300 + Houston
  { "SAN BERNARDINO - California": 2825 }, // 200 + LosAngeles
  { "SAN DIEGO - California": 2855 }, // 230 + LosAngeles
  { "SAN JOSE - California": 3025 }, // 400 + LosAngeles
  { "SAVANNAH - Georgia": 2200 }, // 125 + Savannah
  { "SAVANNAH / VERTIA SUBLOT-Georgia Copart - Georgia": 2250 }, // 175 + Savannah
  { "SC - COLUMBIA - South Carolina": 2350 }, // 275 + Savannah
  { "SCRANTON - Pennsylvania": 2325 }, // 250 + NewYork
  { "SEAFORD - Delaware": 2400 }, // 325 + NewYork
  { "SHREVEPORT - Louisiana": 2590 }, // 315 + Houston
  { "SIKESTON - Missouri": 2750 }, // 475 + Chicago
  { "SO SACRAMENTO - California": 3025 }, // 400 + LosAngeles
  { "SOMERVILLE - New Jersey": 2225 }, // 150 + NewYork
  { "SOUTH BOSTON - Massachusetts": 2450 }, // 375 + NewYork
  { "SPARTANBURG - South Carolina": 2375 }, // 300 + Savannah
  { "SPOKANE - Washington": 3225 }, // 350 + Seattle
  { "SPRINGFIELD - Missouri": 2700 }, // 625 + Savannah
  { "ST. CLOUD - Minnesota": 2775 }, // 500 + Chicago
  { "ST. JOHN'S - Newfoundland and Lab": 1800 + "Toronto ????????" }, // ????
  { "ST. LOUIS - Missouri": 2650 }, // 575 + Savannah
  { "SUN VALLEY - California": 2825 }, // 200 + LosAngeles
  { "SYRACUSE - New York": 2400 }, // 325 + NewYork
  { "Southern Illinois - Illinois": 2650 }, // 575 + Savannah
  { "TALLAHASSEE - Florida": 2400 }, // 325 + Savannah
  { "TAMPA SOUTH - Florida": 2325 }, // 225 + Miami
  { "TAMPA SOUTH - Mulberry Sublot - Florida": 2375 }, // 275 + Miami
  { "TANNER - Alabama": 2500 }, // 425 + Savannah
  { "TIFTON - Georgia": 2350 }, // 275 + Savannah
  { "TORONTO - Ontario": 200 + "Toronto ???????" }, // ???????
  { "TOW GUYS KAMUELA - Hawaii": 4825 }, // 2200 + LosAngeles
  { "TRENTON - New Jersey": 2225 }, // 150 + NewYork
  { "TUCSON - Arizona": 3025 }, // 400 + LosAngeles
  { "TULSA - Oklahoma": 2750 }, // 475 + Houston
  { "VALLEJO - California": 3025 }, // 400 + LosAngeles
  { "VAN NUYS - California": 2825 }, // 200 + LosAngeles
  { "WACO - Texas": 2600 }, // 325 + Houston
  { "WALTON - Kentucky": 2700 }, // 425 + Chicago
  { "WASHINGTON DC - Maryland": 2375 }, // 300 + Norfolk
  { "WEBSTER - New Hampshire": 2475 }, // 400 + NewYork
  { "WEST PALM BEACH - Florida": 2270 }, // 170 + Miami
  { "WEST WARREN - Massachusetts": 2450 }, // 375 + NewYork
  { "WHEELING - Illinois": 2455 }, // 180 + Chicago
  { "WICHITA - Kansas": 2825 }, // 550 + Houston
  { "YORK HAVEN - Pennsylvania": 2350 }, // 275 + NewYork
  {
    "Dyer Auto Auction - Indiana - Dyer Auto Auction":
      180 + "Chicago" + "???????????",
  }, // ????????????????? Copart or IAAI
];

const COPART_FOB = [
  { "0-49.99": 1 },
  { "50.00-99.99": 1 },
  { "100.00-199.99": 25 },
  { "200.00-299.99": 60 },
  { "300.00-349.99": 80 },
  { "350.00-399.99": 90 },
  { "400.00-449.99": 120 },
  { "450.00-499.99": 130 },
  { "500.00-549.99": 140 },
  { "550.00-599.99": 150 },
  { "600.00-699.99": 165 },
  { "700.00-799.99": 185 },
  { "800.00-899.99": 200 },
  { "900.00-999.99": 215 },
  { "1000.00-1199.99": 230 },
  { "1200.00-1299.99": 255 },
  { "1300.00-1399.99": 275 },
  { "1400.00-1499.99": 280 },
  { "1500.00-1599.99": 290 },
  { "1600.00-1699.99": 305 },
  { "1700.00-1799.99": 315 },
  { "1800.00-1999.99": 325 },
  { "2000.00-2399.99": 355 },
  { "2400.00-2499.99": 380 },
  { "2500.00-2999.99": 400 },
  { "3000.00-3499.99": 450 },
  { "3500.00-3999.99": 500 },
  { "4000.00-4499.99": 600 },
  { "4500.00-4999.99": 625 },
  { "5000.00-5999.99": 650 },
  { "6000.00-6499.99": 675 },
  { "6500.00-6999.99": 675 },
  { "7000.00-7499.99": 700 },
  { "7500.00-7999.99": 700 },
  { "8000.00-8499.99": 725 },
  { "8500.00-8999.99": 725 },
  { "9000.00-9999.99": 725 },
  { "10000.00-10499.99": 750 },
  { "10500.00-10999.99": 750 },
  { "11000.00-11499.99": 750 },
  { "11500.00-11999.99": 760 },
  { "12000.00-12499.99": 775 },
  { "12500.00-14999.99": 790 },
  { "15000.00": 6 / 100 },
];

export const IAAI_FEE = [
  { "-- Choose IAAI Location -- ": null },
  { "ABILENE - Texas": 2625 }, // 350 + Houston
  { "ACE - Carson - California": 2785 }, // 160 + LosAngeles
  { "ACE - PERRIS 2 - California": 2900 }, // 275 + LosAngeles
  { "ACE - Perris - California": 2875 }, // 250 + LosAngeles
  { "AKRON-CANTON - Ohio": 2625 }, // 550 + Norfolk
  { "ALBANY - New York": 2350 }, // 275 + NewYork
  { "ALBUQUERQUE - New Mexico": 2875 }, // 600 + Houston
  { "ALTOONA - Pennsylvania": 2525 }, // 450 + NewYork
  { "AMARILLO - Texas": 2800 }, // 525 + Houston
  { "ANAHEIM - California": 2825 }, // 200 + LosAngeles
  { "ANCHORAGE - Alaska": "Anchorage Call ?????????" }, // ?????????
  { "APPLETON - Wisconsin": 2600 }, // 325 + Chicago
  { "ASHEVILLE - North Carolina": 2475 }, // 400 + Norfolk
  { "ASHLAND - Kentucky": "Chicago Call ??????" }, // ????????
  { "ATLANTA - Georgia": 2375 }, // 300 + Savannah
  { "ATLANTA EAST - Georgia": 2400 }, // 325 + Savannah
  { "ATLANTA NORTH - Georgia": 2375 }, // 300 + Savannah
  { "ATLANTA SOUTH - Georgia": 2375 }, // 300 + Savannah
  { "AUSTIN - Texas": 2525 }, // 250 + Houston
  { "AVENEL NEW JERSEY - New Jersey": 2200 }, // 125 + NewYork
  { "BALTIMORE - Maryland": 2425 }, // 350 + Norfolk
  { "BATON ROUGE - Louisiana": 2550 }, // 475 + Savannah
  { "BILLINGS - Montana": 3725 }, // 850 + Seattle
  { "BIRMINGHAM - Alabama": 2450 }, // 375 + Savannah
  { "BOISE - Idaho": 3325 }, // 450 + Seattle
  { "BOSTON - SHIRLEY - Massachusetts": 2450 }, // 375 + NewYork
  { "BOWLING GREEN - Kentucky": "Chicago Call ??????" }, // ??????????
  { "BRIDGEPORT - Pennsylvania": 2275 }, // 200 + NewYork
  { "BUCKHANNON - West Virginia": 2700 }, // 625 + Norfolk
  { "BUFFALO - New York": 2575 }, // 500 + NewYork
  { "BURLINGTON - Vermont": 2575 }, // 500 + NewYork
  { "CASPER - Wyoming": "Chicago Call ??????" }, // ?????????
  { "CENTRAL NEW JERSEY - New Jersey": 2225 }, // 150 + NewYork
  { "CHARLESTON - South Carolina": 2350 }, // 275 + Savannah
  { "CHARLOTTE - North Carolina": 2375 }, // 300 + Norfolk
  { "CHATTANOOGA - Tennessee": 2550 }, // 475 + Savannah
  { "CHICAGO-NORTH - Illinois": 2455 }, // 180 + Chicago
  { "CHICAGO-SOUTH - Illinois": 2455 }, // 180 + Chicago
  { "CHICAGO-WEST - Illinois": 2455 }, // 180 + Chicago
  { "CINCINNATI - Ohio": 2625 }, // 550 + Norfolk
  { "CINCINNATI SOUTH - Ohio": 2625 }, // 550 + Norfolk
  { "CLEARWATER - Florida": 2350 }, // 250 + Miami
  { "CLEVELAND - Ohio": 2625 }, // 550 + Norfolk
  { "COLTON - California": 2825 }, // 200 + LosAngeles
  { "COLUMBIA - South Carolina": 2350 }, // 275 + Savannah
  { "COLUMBUS - Ohio": 2625 }, // 550 + Norfolk
  { "CONCORD - North Carolina": 2375 }, // 300 + Norfolk
  { "CORPUS CHRISTI - Texas": 2550 }, // 275 + Houston
  { "CULPEPER - Virginia": 2325 }, // 250 + Norfolk
  { "Colorado Springs - Colorado": 2925 }, // 650 + Chicago
  { "D&D TOWING INC MAUI - Hawaii": 4925 }, // 2300 + LosAngeles
  { "DALLAS - Texas": 2550 }, // 275 + Houston
  { "DAVENPORT - Iowa": 2600 }, // 325 + Chicago
  { "DAYTON - Ohio": 2625 }, // 550 + Norfolk
  { "DC TOWING HILO - Hawaii": 4875 }, // 2000 + LosAngeles
  { "DENVER - Colorado": 2925 }, // 650 + Chicago
  { "DENVER EAST - Colorado": 2925 }, // 650 + Chicago
  { "DES MOINES - Iowa": 2675 }, // 400 + Chicago
  { "DETROIT - Michigan": 2675 }, // 400 + Chicago
  { "DOTHAN - Alabama": 2450 }, // 375 + Savannah
  { "DUNDALK - Maryland": 2375 }, // 300 + Norfolk
  { "Dallas/Ft Worth - Texas": 2550 }, // 275 + Houston
  { "EAST BAY - California": 3025 }, // 400 + LosAngeles
  { "EL PASO - Texas": 2700 }, // 425 + Houston
  { "ERIE - Pennsylvania": 2575 }, // 500 + NewYork
  { "EUGENE - Oregon": 3250 }, // 375 + Seattle
  { "Elkton - Maryland": 2425 }, // 350 + Norfolk
  { "Englishtown - New Jersey": 2225 }, // 150 + NewYork
  { "FARGO - North Dakota": 2925 }, // 650 + Chicago
  { "FAYETTEVILLE - Arkansas": 2750 }, // 475 + Houston
  { "FLINT - Michigan": 2725 }, // 450 + Chicago
  { "FONTANA - California": 2825 }, // 200 + LosAngeles
  { "FORT MYERS - Florida": 2350 }, // 250 + Miami
  { "FORT PIERCE - Florida": 2300 }, // 200 + Miami
  { "FREMONT - California": 3025 }, // 400 + LosAngeles
  { "FRESNO - California": 3000 }, // 375 + LosAngeles
  { "Fort Worth North - Texas": 2550 }, // 275 + Houston
  { "Fredericksburg-South - Virginia": 2325 }, // 250 + Norfolk
  { "GRAND RAPIDS - Michigan": 2675 }, // 400 + Chicago
  { "GREENSBORO - North Carolina": 2375 }, // 300 + Norfolk
  { "GREENVILLE - South Carolina": 2375 }, // 300 + Savannah
  { "GRENADA - Mississippi": 2500 }, // 425 + Savannah
  { "GULF COAST - Mississippi": 2475 }, // 400 + Savannah
  { "HARTFORD - Connecticut": 2300 }, // 225 + NewYork
  { "HARTFORD-SOUTH - Connecticut": 2300 }, // 225 + NewYork
  { "HIGH DESERT - California": 2925 }, // 300 + LosAngeles
  { "HONOLULU - Hawaii": 100 + "Honolulu ???????" }, // ?????
  { "HOUSTON - Texas": 2455 }, // 180 + Houston
  { "HOUSTON SOUTH - Texas": 2455 }, // 180 + Houston
  { "HOUSTON-NORTH - Texas": 2440 }, // 165 + Houston
  { "HUNTSVILLE - Alabama": 2525 }, // 450 + Savannah
  { "IAA CAT HOUSTON - Texas": 2475 }, // 200 + Houston
  { "INDIANAPOLIS - Indiana": 2575 }, // 300 + Chicago
  { "Indianapolis South - Indiana": 2700 }, // 425 + Chicago
  { "JACKSON - Mississippi": 2500 }, // 425 + Savannah
  { "JACKSONVILLE - Florida": 2300 }, // 225 + Savannah
  { "KANSAS CITY - Kansas": 2700 }, // 625 + Savannah
  { "KNOXVILLE - Tennessee": 2475 }, // 400 + Savannah
  { "Kansas City East - Missouri": 2700 }, // 625 + Savannah
  { "LAFAYETTE - Louisiana": 2475 }, // 400 + Savannah
  { "LAS VEGAS - Nevada": 2950 }, // 325 + LosAngeles
  { "LAUREL - Maryland": 2375 }, // 300 + Norfolk
  { "LEE'S TOWING KAUAI - Hawaii": 1750 + "Honolulu ?????" }, // ????????
  { "LEE'S TOWING KAUAI - Hawaii": 5375 }, // 2750 + LosAngeles
  { "LINCOLN - Illinois": 2575 }, // 300 + Chicago
  { "LITTLE ROCK - Arkansas": 2725 }, // 450 + Houston
  { "LONG ISLAND - New York": 2325 }, // 250 + NewYork
  { "LONGVIEW - Texas": 2550 }, // 275 + Houston
  { "LOS ANGELES - California": 2785 }, // 160 + LosAngeles
  { "LOUISVILLE - Kentucky": 2700 }, // 425 + Chicago
  { "LOUISVILLE NORTH - Kentucky": 2700 }, // 425 + Chicago
  { "LUBBOCK - Texas": 2725 }, // 450 + Houston
  { "Lexington - South Carolina": 2325 }, // 250 + Savannah
  { "Los Angeles South - California": 2775 }, // 150 + LosAngeles
  { "MACON - Georgia": 2350 }, // 275 + Savannah
  { "MANCHESTER - New Hampshire": 2475 }, // 400 + NewYork
  { "MCALLEN - Texas": 2625 }, // 350 + Houston
  { "MEMPHIS - Tennessee": 2575 }, // 500 + Savannah
  { "METRO DC - Maryland": 2375 }, // 300 + Norfolk
  { "MIAMI - Florida": 2225 }, // 125 + Miami
  { "MIAMI-NORTH - Florida": 2250 }, // 150 + Miami
  { "MILWAUKEE - Wisconsin": 2500 }, // 225 + Chicago
  { "MINNEAPOLIS/ST. PAUL - Minnesota": 2725 }, // 450 + Chicago
  { "MISSOULA - Montana": 3475 }, // 600 + Seattle
  { "Minneapolis South - Minnesota": 2725 }, // 450 + Chicago
  { "NASHVILLE - Tennessee": 2475 }, // 400 + Savannah
  { "NEW CASTLE - Delaware": 2325 }, // 250 + NewYork
  { "NEW ORLEANS - Louisiana": 2500 }, // 425 + Savannah
  { "NEW ORLEANS EAST - Louisiana": 2500 }, // 425 + Savannah
  { "NEWBURGH - New York": 2300 }, // 225 + NewYork
  { "NORTH HOLLYWOOD - California": 2825 }, // 200 + LosAngeles
  { "NORTHERN NEW JERSEY - New Jersey": 2200 }, // 125 + NewYork
  { "NORTHERN VIRGINIA - Virginia": 2325 }, // 250 + Norfolk
  { "New Orleans and Louisiana Flood - Louisiana": 2500 }, // 425 + Savannah
  { "OKLAHOMA CITY - Oklahoma": 2675 }, // 400 + Houston
  { "OMAHA - Nebraska": 2725 }, // 450 + Chicago
  { "ORLANDO - Florida": 2350 }, // 250 + Miami
  { "ORLANDO-NORTH - Florida": 2350 }, // 250 + Miami
  { "PADUCAH - Kentucky": "Chicago Call ??????" }, // ??????????
  { "PENSACOLA - Florida": 2500 }, // 400 + Miami
  { "PERMIAN BASIN - Texas": 2725 }, // 450 + Houston
  { "PHILADELPHIA - Pennsylvania": 2275 }, // 200 + NewYork
  { "PHOENIX - Arizona": 2975 }, // 350 + LosAngeles
  { "PITTSBURGH - Pennsylvania": 2525 }, // 450 + NewYork
  { "PITTSBURGH-NORTH - Pennsylvania": 2525 }, // 450 + NewYork
  { "PORT MURRAY - New Jersey": 2250 }, // 175 + NewYork
  { "PORTAGE - Wisconsin": 2575 }, // 300 + Chicago
  { "PORTLAND - GORHAM - Maine": 2550 }, // 475 + NewYork
  { "PORTLAND - Oregon": 3125 }, // 250 + Seattle
  { "PROVIDENCE - Rhode Island": 2425 }, // 350 + NewYork
  { "PULASKI - Virginia": 2475 }, // 400 + Norfolk
  { "Philadelphia East - Pennsylvania": 2275 }, // 200 + NewYork
  { "Portland West - Oregon": 3125 }, // 250 + Seattle
  { "RALEIGH - North Carolina": 2375 }, // 300 + Norfolk
  { "RENO - Nevada": 3175 }, // 550 + LosAngeles
  { "RICHMOND - Virginia": 2275 }, // 200 + Norfolk
  { "ROANOKE - Virginia": 2450 }, // 375 + Norfolk
  { "ROCHESTER - New York": 2525 }, // 450 + NewYork
  { "ROSEDALE - Maryland": 2425 }, // 350 + Norfolk
  { "SACRAMENTO - California": 3025 }, // 400 + LosAngeles
  { "SALT LAKE CITY - Utah": 3175 }, // 550 + LosAngeles
  { "SAN ANTONIO - Texas": 2575 }, // 300 + Houston
  { "SAN ANTONIO-SOUTH - Texas": 2575 }, // 300 + Houston
  { "SAN BERNARDINO - California": 2875 }, // 250 + LosAngeles
  { "SAN DIEGO - California": 2855 }, // 230 + LosAngeles
  { "SAVANNAH - Georgia": 2200 }, // 125 + Savannah
  { "SCRANTON - Pennsylvania": 2325 }, // 250 + NewYork
  { "SEATTLE - Washington": 3050 }, // 175 + Seattle
  { "SHADY SPRING - West Virginia": 2700 }, // 625 + Norfolk
  { "SHREVEPORT - Louisiana": 2590 }, // 315 + Houston
  { "SIOUX FALLS - South Dakota": 2925 }, // 650 + Chicago
  { "SOUTH BEND - Indiana": 2525 }, // 250 + Chicago
  { "SOUTHERN NEW JERSEY - New Jersey": 2275 }, // 200 + NewYork
  { "SPOKANE - Washington": 3225 }, // 350 + Seattle
  { "SPRINGFIELD - Missouri": 2700 }, // 625 + Savannah
  { "ST. LOUIS - Illinois": 2650 }, // 575 + Savannah
  { "SYRACUSE - New York": 2400 }, // 325 + NewYork
  { "Sayreville - New Jersey": 2225 }, // 150 + NewYork
  { "Suffolk - Virginia": 2225 }, // 150 + Norfolk
  { "TAMPA - Florida": 2350 }, // 250 + Miami
  { "TAUNTON - Massachusetts": 2450 }, // 375 + NewYork
  { "TIDEWATER - Virginia": 2225 }, // 150 + Norfolk
  { "TIFTON - Georgia": 2350 }, // 275 + Savannah
  { "TUCSON - Arizona": 3025 }, // 400 + LosAngeles
  { "TULSA - Oklahoma": 2750 }, // 475 + Houston
  { "Tampa North - Florida": 2375 }, // 275 + Miami
  { "Templeton - Massachusetts": 2450 }, // 375 + NewYork
  { "WEST HAWAII TOWING & REPAIR INC KONA - Hawaii": 4825 }, // 2200 + LosAngeles
  { "WEST PALM BEACH - Florida": 2280 }, // 180 + Miami
  { "WESTERN COLORADO - Colorado": "Chicago Call ???" }, // ???????
  { "WICHITA - Kansas": 2825 }, // 550 + Houston
  { "WILMINGTON - North Carolina": 2450 }, // 375 + Norfolk
  { "YORK SPRINGS - Pennsylvania": 2350 }, // 275 + NewYork
];

const IAAI_FOB = [
  { "0-49.99": 1 },
  { "50.00-99.99": 1 },
  { "100.00-199.99": 25 },
  { "200.00-299.99": 50 },
  { "300.00-399.99": 75 },
  { "400.00-499.99": 120 },
  { "500.00-549.99": 135 },
  { "550.00-599.99": 145 },
  { "600.00-699.99": 155 },
  { "700.00-799.99": 170 },
  { "800.00-899.99": 185 },
  { "900.00-999.99": 200 },
  { "1000.00-1099.99": 215 },
  { "1100.00-1199.99": 230 },
  { "1200.00-1299.99": 245 },
  { "1300.00-1399.99": 260 },
  { "1400.00-1499.99": 275 },
  { "1500.00-1599.99": 290 },
  { "1600.00-1699.99": 300 },
  { "1700.00-1799.99": 310 },
  { "1800.00-1999.99": 325 },
  { "2000.00-2199.99": 340 },
  { "2200.00-2399.99": 350 },
  { "2400.00-2599.99": 360 },
  { "2600.00-2799.99": 375 },
  { "2800.00-2999.99": 400 },
  { "3000.00-3499.99": 450 },
  { "3500.00-3999.99": 500 },
  { "4000.00-4499.99": 600 },
  { "4500.00-4999.99": 625 },
  { "5000.00-5999.99": 650 },
  { "6000.00-6999.99": 675 },
  { "7000.00-7999.99": 700 },
  { "8000.00-9999.99": 725 },
  { "10000.00-14999.99": 750 },
  { "15000.00<": 6 / 100 }, // 6%
];

const gasolineSedan = {
  "0_3_year": {
    "0-999": [15],
    "1000-1499": [15],
    "1500-1799": [15],
    "1800-2299": [15],
    "2300-2800": [15],
    "2801-3000": [12.5],
    "3001-": [12.5],
  },
  "3_5_year": {
    "0-999": [20, 0.36],
    "1000-1499": [20, 0.4],
    "1500-1799": [20, 0.36],
    "1800-2299": [20, 0.44],
    "2300-3000": [20, 0.44],
    "3001-": [20, 0.8],
  },
  "5_7_year": {
    "0-999": [20, 0.36],
    "1000-1499": [20, 0.4],
    "1500-1799": [20, 0.36],
    "1800-2299": [20, 0.44],
    "2300-3000": [20, 0.44],
    "3001-": [20, 0.8],
  },
  "7_high_year": {
    "0-999": [1.4],
    "1000-1499": [1.5],
    "1500-1799": [1.6],
    "1800-2299": [2.2],
    "2300-3000": [2.2],
    "3001-": [3.2],
  },
};

// const hybridSedan = {
//   "0_3_year": {
//     "0-999": [15],
//     "1000-1499": [15],
//     "1500-1799": [15],
//     "1800-2299": [15],
//     "2300-2799": [15],
//     "2800-3000": [12.5],
//     "3001-": [12.5],
//   },
//   "3_5_year": {
//     "0-999": [20, 0.36],
//     "1000-1499": [20, 0.4],
//     "1500-1799": [20, 0.36],
//     "1800-2299": [20, 0.44],
//     "2300-3000": [20, 0.44],
//     "3001-": [20, 0.8],
//   },
//   "5_7_year": {
//     "0-999": [20, 0.36],
//     "1000-1499": [20, 0.4],
//     "1500-1799": [20, 0.36],
//     "1800-2299": [20, 0.44],
//     "2300-3000": [20, 0.44],
//     "3001-": [20, 0.8],
//   },
//   "7_high_year": {
//     "0-999": [1.4],
//     "1000-1499": [1.5],
//     "1500-1799": [1.6],
//     "1800-2299": [2.2],
//     "2300-3000": [2.2],
//     "3001-": [3.2],
//   },
// };

const dieselSedan = {
  "0_3_year": {
    "0-1499": [15],
    "1500-2500": [15],
    "2501-": [15],
  },
  "3_5_year": {
    "0-1499": [20, 0.32],
    "1500-2500": [20, 0.4],
    "2501-": [20, 0.8],
  },
  "5_7_year": {
    "0-1499": [20, 0.32],
    "1500-2500": [20, 0.4],
    "2501-": [20, 0.8],
  },
  "7_high_year": {
    "0-1499": [1.5],
    "1500-2500": [2.2],
    "2501-": [3.2],
  },
};

// const electricSedan = {
//   new: [15],
//   used: [0],
// };

const gasolineSUV = {
  "0_3_year": {
    "0-999": [15],
    "1000-1499": [15],
    "1500-1799": [15],
    "1800-2299": [15],
    "2300-2799": [15],
    "2800-3000": [15],
    "3001-3500": [15],
    "3501-4200": [12.5],
    "4201-": [10],
  },
  "3_5_year": {
    "0-999": [20, 0.36],
    "1000-1499": [20, 0.4],
    "1500-1799": [20, 0.36],
    "1800-2299": [20, 0.44],
    "2300-3000": [20, 0.44],
    "3001-": [20, 0.8],
  },
  "5_7_year": {
    "0-999": [20, 0.36],
    "1000-1499": [20, 0.4],
    "1500-1799": [20, 0.36],
    "1800-2299": [20, 0.44],
    "2300-3000": [20, 0.44],
    "3001-": [20, 0.8],
  },
  "7_high_year": {
    "0-999": [1.4],
    "1000-1499": [1.5],
    "1500-1799": [1.6],
    "1800-2299": [2.2],
    "2300-3000": [2.2],
    "3001-": [3.2],
  },
};

const dieselSUV = {
  "0_3_year": {
    "0-1499": [15],
    "1500-2500": [15],
    "2501-": [15],
  },
  "3_5_year": {
    "0-1499": [20, 0.32],
    "1500-2500": [20, 0.4],
    "2501-": [20, 0.8],
  },
  "5_7_year": {
    "0-1499": [20, 0.32],
    "1500-2500": [20, 0.4],
    "2501-": [20, 0.8],
  },
  "7_high_year": {
    "0-1499": [1.5],
    "1500-2500": [2.2],
    "2501-": [3.2],
  },
};

// const hybridSUV = {
//   "0_3_year": {
//     "0-999": [15],
//     "1000-1499": [15],
//     "1500-1799": [15],
//     "1800-2299": [15],
//     "2300-2799": [15],
//     "2800-3000": [12.5],
//     "3001-": [12.5],
//   },
//   "3_5_year": {
//     "0-999": [20, 0.36],
//     "1000-1499": [20, 0.4],
//     "1500-1799": [20, 0.36],
//     "1800-2299": [20, 0.44],
//     "2300-3000": [20, 0.44],
//     "3001-": [20, 0.8],
//   },
//   "5_7_year": {
//     "0-999": [20, 0.36],
//     "1000-1499": [20, 0.4],
//     "1500-1799": [20, 0.36],
//     "1800-2299": [20, 0.44],
//     "2300-3000": [20, 0.44],
//     "3001-": [20, 0.8],
//   },
//   "7_high_year": {
//     "0-999": [1.4],
//     "1000-1499": [1.5],
//     "1500-1799": [1.6],
//     "1800-2299": [2.2],
//     "2300-3000": [2.2],
//     "3001-": [3.2],
//   },
// };

// const electricSUV = {
//   new: [15],
//   used: [0],
// };

export const optionsFuel = [
  {
    name: "-- Choose Fuel Type --",
    value: "",
  },
  {
    name: translate("forward_web_calculator_selected_car_fuel_gasoline"),
    value: "gasoline",
  },
  {
    name: translate("forward_web_calculator_selected_car_fuel_hybrid"),
    value: "hybrid",
  },
  {
    name: translate("forward_web_calculator_selected_car_fuel_electric"),
    value: "electric",
  },
  {
    name: translate("forward_web_calculator_selected_car_fuel_diesel"),
    value: "diesel",
  },
];

export const optionsCarType = [
  {
    name: "-- Choose Vehicle Type --",
    value: "",
  },
  {
    name: translate("forward_web_calculator_selected_car_type_sedan"),
    value: "sedan",
  },
  {
    name: translate("forward_web_calculator_selected_car_type_suv"),
    value: "suv",
  },
  // {
  //   name: translate("forward_web_calculator_selected_car_type_pickup"),
  //   value: "pickup",
  // },
];

export const optionsElectric = [
  {
    name: "-- Choose Vehicle Year --",
    value: null,
  },
  {
    name: "used",
    value: "used",
  },
  {
    name: "new",
    value: "new",
  },
];

export const optionsMonth = [
  {
    name: "-- Choose Month --",
    value: null,
  },
  {
    name: "January",
    value: 0,
  },
  {
    name: "February",
    value: 1,
  },
  {
    name: "March",
    value: 2,
  },
  {
    name: "April",
    value: 3,
  },
  {
    name: "May",
    value: 4,
  },
  {
    name: "June",
    value: 5,
  },
  {
    name: "July",
    value: 6,
  },
  {
    name: "August",
    value: 7,
  },
  {
    name: "September",
    value: 8,
  },
  {
    name: "October",
    value: 9,
  },
  {
    name: "November",
    value: 10,
  },
  {
    name: "December",
    value: 11,
  },
];

export const optionsYear = () => {
  const currentYear = new Date().getFullYear();
  let arr = [
    {
      name: "-- Choose Year --",
      value: null,
    },
  ];
  for (let i = 0; i <= 6; i++) {
    arr.push({
      name: `${currentYear - i}`,
      value: currentYear - i,
    });
  }
  return arr;
};

export const forPDF = (
  auctionName,
  carPrice,
  auctionFee,
  shippingFee,
  customDuty,
  VAT,
  ecoTax,
  exchangeAMD,
  exchangeUSD,
  exchangeRUB
) => {
  return {
    title: {
      company: "Forward LLC",
      phone: "+374 94 000 000",
      address: "Armenia, Yerevan Charentsi St. 50/1",
    },
    header: {
      auction: "Auction",
      auctionName: auctionName || "",
      space: " ",
      spac: " ",
    },
    body: {
      "Car price": `$${carPrice}` || "",
      "Auction fee": `$${auctionFee}` || "",
      "Shipping ": `$${shippingFee}` || "",
      "Custom duty": `$${customDuty}` || "",
      "VAT ": `$${VAT}` || "",
      "Eco Tax": `$${ecoTax}` || "",
    },
    footer: {
      "Total price": "Total price",
      AMD: `AMD ${exchangeAMD}`, // "\u058F" --> unicode
      USD: `$ ${exchangeUSD}`,
      RUB: `RUB ${exchangeRUB}`, // "\u20BD" --> unicode
    },
  };
};

export const maxaturq = (
  carPrice,
  auctionFee,
  carShippingFee,
  maxayinRate,
  engine,
  euroToUsd
) => {
  if (maxayinRate.length > 1) {
    const byPercent =
      ((Number(carPrice) + Number(auctionFee) + Number(carShippingFee)) *
        maxayinRate[0]) /
      100;
    const byRate = maxayinRate[1] * Number(engine) * euroToUsd;
    return byPercent >= byRate
      ? Number(byPercent.toFixed(2))
      : Number(byRate.toFixed(2));
  } else {
    const res =
      ((Number(carPrice) + Number(auctionFee) + Number(carShippingFee)) *
        maxayinRate[0]) /
      100;
    return Number(res.toFixed(2));
  }
};

export const AAH = (carPrice, auctionFee, carShippingFee, maqsayin) => {
  const aahPercent = 20;
  const res =
    ((Number(carPrice) +
      Number(auctionFee) +
      Number(carShippingFee) +
      Number(maqsayin)) *
      aahPercent) /
    100;
  return Number(res.toFixed(2));
};

export const achurdiMijnordavchar = (carPrice, auction) => {
  const price = Number(carPrice);
  let copart_Additional_Fee = 0;
  let iaai_Additional_Fee = 0;

  if (auction.toLowerCase() === "copart") {
    let live_bid = 0;
    if (price >= 100 && price <= 499.99) {
      live_bid = 49;
    } else if (price >= 500 && price <= 999.99) {
      live_bid = 59;
    } else if (price >= 1000 && price <= 1499.99) {
      live_bid = 79;
    } else if (price >= 1500 && price <= 1999.99) {
      live_bid = 89;
    } else if (price >= 2000 && price <= 3999.99) {
      live_bid = 99;
    } else if (price >= 4000 && price <= 5999.99) {
      live_bid = 109;
    } else if (price >= 6000 && price <= 7999.99) {
      live_bid = 139;
    } else if (price >= 8000) {
      live_bid = 149;
    } else {
      live_bid = 0;
    }
    const GATE = 79;
    const ENVIRONMENTAL_FEE = 10;
    copart_Additional_Fee = live_bid + GATE + ENVIRONMENTAL_FEE;
  } else if (auction === "iaai") {
    let live_bid = 0;
    if (price >= 100 && price <= 499.99) {
      live_bid = 39;
    } else if (price >= 500 && price <= 999.99) {
      live_bid = 49;
    } else if (price >= 1000 && price <= 1499.99) {
      live_bid = 69;
    } else if (price >= 1500 && price <= 1999.99) {
      live_bid = 79;
    } else if (price >= 2000 && price <= 3999.99) {
      live_bid = 89;
    } else if (price >= 4000 && price <= 5999.99) {
      live_bid = 99;
    } else if (price >= 6000 && price <= 7999.99) {
      live_bid = 119;
    } else if (price >= 8000) {
      live_bid = 129;
    } else {
      live_bid = 0;
    }
    const SERVICE_FEE = 79;
    const Premium_Vehicle_Detail_Fee = 15;
    const Environmental_Fee = 10;
    const Broker_Fee = 35;
    iaai_Additional_Fee =
      live_bid +
      SERVICE_FEE +
      Premium_Vehicle_Detail_Fee +
      Environmental_Fee +
      Broker_Fee;
  }
  const FOB =
    auction.toLowerCase() === "copart"
      ? COPART_FOB
      : auction === "iaai"
      ? IAAI_FOB
      : [];
  if (FOB.length > 0) {
    for (let i of FOB) {
      const item = Object.keys(i);
      const tempValue = item[0].split("-");
      const minValue = tempValue[0];
      const maxValue = tempValue[1];
      const additional_fee =
        auction.toLowerCase() === "copart"
          ? copart_Additional_Fee
          : iaai_Additional_Fee;
      if (minValue <= price && maxValue >= price) {
        return i[item] + additional_fee;
      } else if (!maxValue) {
        return (price * 6) / 100 + additional_fee;
      }
    }
  } else {
    console.log("Enter your fee for auction");
    return 0;
  }
};

export const getAgeDifference = (year, month) => {
  let currentYear = new Date().getFullYear();
  let currentMonth = new Date().getMonth();
  const day = new Date().getDate();
  if (day <= 15) {
    if (currentMonth > 0) {
      currentMonth = currentMonth - 1;
    } else {
      currentMonth = 11;
      currentYear = currentYear - 1;
    }
  }
  const date1Format = `${year}-${Number(month) + 1}`;
  const date1 = moment(date1Format, "YYYY-M");
  const date2Format = `${currentYear}-${Number(currentMonth) + 1}`;
  const date2 = moment(date2Format, "YYYY-M");
  const diffInYears = date2.diff(date1, "year");
  return diffInYears;
};

export const bnapahpanakanHark = (
  carPrice,
  auctionFee,
  carShippingFee,
  vehicleAge
) => {
  if (Number(vehicleAge) >= 4) {
    return (
      ((Number(carPrice) + Number(auctionFee) + Number(carShippingFee)) * 2) /
      100
    );
  }
  return 0;
};

export const vehicleGazolineType = (engine, vehicleAge, vehiceType) => {
  let year = "";
  if (vehicleAge >= 0 && vehicleAge <= 2) {
    year = "0_3_year";
  } else if (vehicleAge >= 3 && vehicleAge <= 4) {
    year = "3_5_year";
  } else if (vehicleAge >= 5 && vehicleAge <= 6) {
    year = "5_7_year";
  } else {
    year = "7_high_year";
  }
  if (vehiceType === "sedan") {
    switch (year) {
      case "0_3_year":
        if (engine > 0 && engine <= 999) {
          return gasolineSedan[`${year}`]["0-999"];
        } else if (engine >= 1000 && engine <= 1499) {
          return gasolineSedan[`${year}`]["1000-1499"];
        } else if (engine >= 1500 && engine <= 1799) {
          return gasolineSedan[`${year}`]["1500-1799"];
        } else if (engine >= 1800 && engine <= 2299) {
          return gasolineSedan[`${year}`]["1800-2299"];
        } else if (engine >= 2300 && engine <= 2800) {
          return gasolineSedan[`${year}`]["2300-2800"];
        } else if (engine >= 2801 && engine <= 3000) {
          return gasolineSedan[`${year}`]["2801-3000"];
        } else {
          return gasolineSedan[`${year}`]["3001-"];
        }
      case "3_5_year":
        if (engine > 0 && engine <= 999) {
          return gasolineSedan[`${year}`]["0-999"];
        } else if (engine >= 1000 && engine <= 1499) {
          return gasolineSedan[`${year}`]["1000-1499"];
        } else if (engine >= 1500 && engine <= 1799) {
          return gasolineSedan[`${year}`]["1500-1799"];
        } else if (engine >= 1800 && engine <= 2299) {
          return gasolineSedan[`${year}`]["1800-2299"];
        } else if (engine >= 2300 && engine <= 3000) {
          return gasolineSedan[`${year}`]["2300-3000"];
        } else {
          return gasolineSedan[`${year}`]["3001-"];
        }
      case "5_7_year":
        if (engine > 0 && engine <= 999) {
          return gasolineSedan[`${year}`]["0-999"];
        } else if (engine >= 1000 && engine <= 1499) {
          return gasolineSedan[`${year}`]["1000-1499"];
        } else if (engine >= 1500 && engine <= 1799) {
          return gasolineSedan[`${year}`]["1500-1799"];
        } else if (engine >= 1800 && engine <= 2299) {
          return gasolineSedan[`${year}`]["1800-2299"];
        } else if (engine >= 2300 && engine <= 3000) {
          return gasolineSedan[`${year}`]["2300-3000"];
        } else {
          return gasolineSedan[`${year}`]["3001-"];
        }
      case "7_high_year":
        if (engine > 0 && engine <= 999) {
          return gasolineSedan[`${year}`]["0-999"];
        } else if (engine >= 1000 && engine <= 1499) {
          return gasolineSedan[`${year}`]["1000-1499"];
        } else if (engine >= 1500 && engine <= 1799) {
          return gasolineSedan[`${year}`]["1500-1799"];
        } else if (engine >= 1800 && engine <= 2299) {
          return gasolineSedan[`${year}`]["1800-2299"];
        } else if (engine >= 2300 && engine <= 3000) {
          return gasolineSedan[`${year}`]["2300-3000"];
        } else {
          return gasolineSedan[`${year}`]["3001-"];
        }
      default:
        return 0;
    }
  } else if (vehiceType === "suv") {
    // suv logic
    switch (year) {
      case "0_3_year":
        if (engine > 0 && engine <= 999) {
          return gasolineSUV[`${year}`]["0-999"];
        } else if (engine >= 1000 && engine <= 1499) {
          return gasolineSUV[`${year}`]["1000-1499"];
        } else if (engine >= 1500 && engine <= 1799) {
          return gasolineSUV[`${year}`]["1500-1799"];
        } else if (engine >= 1800 && engine <= 2299) {
          return gasolineSUV[`${year}`]["1800-2299"];
        } else if (engine >= 2300 && engine <= 2799) {
          return gasolineSUV[`${year}`]["2300-2799"];
        } else if (engine >= 2800 && engine <= 3000) {
          return gasolineSUV[`${year}`]["2800-3000"];
        } else if (engine >= 3001 && engine <= 3500) {
          return gasolineSUV[`${year}`]["3001-3500"];
        } else if (engine >= 3501 && engine <= 4200) {
          return gasolineSUV[`${year}`]["3501-4200"];
        } else {
          return gasolineSUV[`${year}`]["4201-"];
        }
      case "3_5_year":
        if (engine > 0 && engine <= 999) {
          return gasolineSUV[`${year}`]["0-999"];
        } else if (engine >= 1000 && engine <= 1499) {
          return gasolineSUV[`${year}`]["1000-1499"];
        } else if (engine >= 1500 && engine <= 1799) {
          return gasolineSUV[`${year}`]["1500-1799"];
        } else if (engine >= 1800 && engine <= 2299) {
          return gasolineSUV[`${year}`]["1800-2299"];
        } else if (engine >= 2300 && engine <= 3000) {
          return gasolineSUV[`${year}`]["2300-3000"];
        } else {
          return gasolineSUV[`${year}`]["3001-"];
        }
      case "5_7_year":
        if (engine > 0 && engine <= 999) {
          return gasolineSUV[`${year}`]["0-999"];
        } else if (engine >= 1000 && engine <= 1499) {
          return gasolineSUV[`${year}`]["1000-1499"];
        } else if (engine >= 1500 && engine <= 1799) {
          return gasolineSUV[`${year}`]["1500-1799"];
        } else if (engine >= 1800 && engine <= 2299) {
          return gasolineSUV[`${year}`]["1800-2299"];
        } else if (engine >= 2300 && engine <= 3000) {
          return gasolineSUV[`${year}`]["2300-3000"];
        } else {
          return gasolineSUV[`${year}`]["3001-"];
        }
      case "7_high_year":
        if (engine > 0 && engine <= 999) {
          return gasolineSUV[`${year}`]["0-999"];
        } else if (engine >= 1000 && engine <= 1499) {
          return gasolineSUV[`${year}`]["1000-1499"];
        } else if (engine >= 1500 && engine <= 1799) {
          return gasolineSUV[`${year}`]["1500-1799"];
        } else if (engine >= 1800 && engine <= 2299) {
          return gasolineSUV[`${year}`]["1800-2299"];
        } else if (engine >= 2300 && engine <= 3000) {
          return gasolineSUV[`${year}`]["2300-3000"];
        } else {
          return gasolineSUV[`${year}`]["3001-"];
        }
      default:
        return 0;
    }
  } else if (vehiceType === "pickup") {
    // TODO: set pickup logic
  }
};

export const vehicleDieselType = (engine, vehicleAge, vehiceType) => {
  console.log("vehiceType - ", vehiceType);
  let year = "";
  if (vehicleAge >= 0 && vehicleAge <= 2) {
    year = "0_3_year";
  } else if (vehicleAge >= 3 && vehicleAge <= 4) {
    year = "3_5_year";
  } else if (vehicleAge >= 5 && vehicleAge <= 6) {
    year = "5_7_year";
  } else {
    year = "7_high_year";
  }
  if (vehiceType === "sedan") {
    switch (year) {
      case "0_3_year":
        if (engine > 0 && engine <= 1499) {
          return dieselSedan[`${year}`]["0-1499"];
        } else if (engine >= 1500 && engine <= 2500) {
          return dieselSedan[`${year}`]["1500-2500"];
        } else {
          return dieselSedan[`${year}`]["2501-"];
        }
      case "3_5_year":
        if (engine > 0 && engine <= 1499) {
          return dieselSedan[`${year}`]["0-1499"];
        } else if (engine >= 1500 && engine <= 2500) {
          return dieselSedan[`${year}`]["1500-2500"];
        } else {
          return dieselSedan[`${year}`]["2501-"];
        }
      case "5_7_year":
        if (engine > 0 && engine <= 1499) {
          return dieselSedan[`${year}`]["0-1499"];
        } else if (engine >= 1500 && engine <= 2500) {
          return dieselSedan[`${year}`]["1500-2500"];
        } else {
          return dieselSedan[`${year}`]["2501-"];
        }
      case "7_high_year":
        if (engine > 0 && engine <= 1499) {
          return dieselSedan[`${year}`]["0-1499"];
        } else if (engine >= 1500 && engine <= 2500) {
          return dieselSedan[`${year}`]["1500-2500"];
        } else {
          return dieselSedan[`${year}`]["2501-"];
        }
      default:
        return 0;
    }
  } else if (vehiceType === "suv") {
    // TODO: diesel suv logic
    switch (year) {
      case "0_3_year":
        if (engine > 0 && engine <= 1499) {
          return dieselSUV[`${year}`]["0-1499"];
        } else if (engine >= 1500 && engine <= 2500) {
          return dieselSUV[`${year}`]["1500-2500"];
        } else {
          return dieselSUV[`${year}`]["2501-"];
        }
      case "3_5_year":
        if (engine > 0 && engine <= 1499) {
          return dieselSUV[`${year}`]["0-1499"];
        } else if (engine >= 1500 && engine <= 2500) {
          return dieselSUV[`${year}`]["1500-2500"];
        } else {
          return dieselSUV[`${year}`]["2501-"];
        }
      case "5_7_year":
        if (engine > 0 && engine <= 1499) {
          return dieselSUV[`${year}`]["0-1499"];
        } else if (engine >= 1500 && engine <= 2500) {
          return dieselSUV[`${year}`]["1500-2500"];
        } else {
          return dieselSUV[`${year}`]["2501-"];
        }
      case "7_high_year":
        if (engine > 0 && engine <= 1499) {
          return dieselSUV[`${year}`]["0-1499"];
        } else if (engine >= 1500 && engine <= 2500) {
          return dieselSUV[`${year}`]["1500-2500"];
        } else {
          return dieselSUV[`${year}`]["2501-"];
        }
      default:
        return 0;
    }
  } else if (vehiceType === "pickup") {
    // TODO: set pickup logic
  }
};

export const vehicleHybridType = () => {
  return [15];
  // let year = "";
  // if (vehicleAge >= 0 && vehicleAge <= 2) {
  //   year = "0_3_year";
  // } else if (vehicleAge >= 3 && vehicleAge <= 4) {
  //   year = "3_5_year";
  // } else if (vehicleAge >= 5 && vehicleAge <= 6) {
  //   year = "5_7_year";
  // } else {
  //   year = "7_high_year";
  // }
  // if (vehiceType === "sedan") {
  //   switch (year) {
  //     case "0_3_year":
  //       if (engine > 0 && engine <= 999) {
  //         return hybridSedan[`${year}`]["0-999"];
  //       } else if (engine >= 1000 && engine <= 1499) {
  //         return hybridSedan[`${year}`]["1000-1499"];
  //       } else if (engine >= 1500 && engine <= 1799) {
  //         return hybridSedan[`${year}`]["1500-1799"];
  //       } else if (engine >= 1800 && engine <= 2299) {
  //         return hybridSedan[`${year}`]["1800-2299"];
  //       } else if (engine >= 2300 && engine <= 2799) {
  //         return hybridSedan[`${year}`]["2300-2799"];
  //       } else if (engine >= 2800 && engine <= 3000) {
  //         return hybridSedan[`${year}`]["2800-3000"];
  //       } else {
  //         return hybridSedan[`${year}`]["3001-"];
  //       }
  //     case "3_5_year":
  //       if (engine > 0 && engine <= 999) {
  //         return hybridSedan[`${year}`]["0-999"];
  //       } else if (engine >= 1000 && engine <= 1499) {
  //         return hybridSedan[`${year}`]["1000-1499"];
  //       } else if (engine >= 1500 && engine <= 1799) {
  //         return hybridSedan[`${year}`]["1500-1799"];
  //       } else if (engine >= 1800 && engine <= 2299) {
  //         return hybridSedan[`${year}`]["1800-2299"];
  //       } else if (engine >= 2300 && engine <= 3000) {
  //         return hybridSedan[`${year}`]["2300-3000"];
  //       } else {
  //         return hybridSedan[`${year}`]["3001-"];
  //       }
  //     case "5_7_year":
  //       if (engine > 0 && engine <= 999) {
  //         return hybridSedan[`${year}`]["0-999"];
  //       } else if (engine >= 1000 && engine <= 1499) {
  //         return hybridSedan[`${year}`]["1000-1499"];
  //       } else if (engine >= 1500 && engine <= 1799) {
  //         return hybridSedan[`${year}`]["1500-1799"];
  //       } else if (engine >= 1800 && engine <= 2299) {
  //         return hybridSedan[`${year}`]["1800-2299"];
  //       } else if (engine >= 2300 && engine <= 3000) {
  //         return hybridSedan[`${year}`]["2300-3000"];
  //       } else {
  //         return hybridSedan[`${year}`]["3001-"];
  //       }
  //     case "7_high_year":
  //       if (engine > 0 && engine <= 999) {
  //         return hybridSedan[`${year}`]["0-999"];
  //       } else if (engine >= 1000 && engine <= 1499) {
  //         return hybridSedan[`${year}`]["1000-1499"];
  //       } else if (engine >= 1500 && engine <= 1799) {
  //         return hybridSedan[`${year}`]["1500-1799"];
  //       } else if (engine >= 1800 && engine <= 2299) {
  //         return hybridSedan[`${year}`]["1800-2299"];
  //       } else if (engine >= 2300 && engine <= 3000) {
  //         return hybridSedan[`${year}`]["2300-3000"];
  //       } else {
  //         return hybridSedan[`${year}`]["3001-"];
  //       }
  //     default:
  //       return 0;
  //   }
  // } else if (vehiceType === "suv") {
  //   // TODO: set suv logic
  // } else if (vehiceType === "pickup") {
  //   // TODO: set pickup logic
  // }
};

export const vehicleElectricType = (year, vehiceType) => {
  return [15];
  // if (vehiceType === "sedan") {
  //   switch (year) {
  //     case "new":
  //       return electricSedan[`${year}`];
  //     case "used":
  //       return electricSedan[`${year}`];
  //     default:
  //       return 0;
  //   }
  // } else if (vehiceType === "suv") {
  //   // TODO: set suv logic
  // } else if (vehiceType === "pickup") {
  //   // TODO: set pickup logic
  // }
};
