import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";

import { setupHttpConfig } from "./utils/http";
import Navigation from "./Navigation";
import { store } from "../src/redux/store";
import { LanguageContext } from "./utils/languageContext";
import { getLocale } from "./utils/helpers";

function App() {
  const [lang, setLang] = useState("");

  useEffect(() => {
    (async () => {
      const l = await getLocale();
      setLang(l);
    })();
  }, [lang]);

  useEffect(() => {
    setupHttpConfig();
  }, []);

  return (
    <LanguageContext.Provider value={{ lang, setLang }}>
      <Provider store={store}>
        <Navigation />
      </Provider>
    </LanguageContext.Provider>
  );
}
export default App;
