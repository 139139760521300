/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'

import variables from '../../styles/variables.scss'
import './styles.scss'

import car from '../../assets/bmwx6.png'
import Input from '../../components/input'
import Button from '../../components/button'
import { useNavigate } from 'react-router-dom'
import { translate } from '../../utils/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../../redux/auth/actions'

const Login = () => {
  const dispatch = useDispatch()
  const errorLogin = useSelector((state) => state.Auth.errors.SignIn);
  const navigate = useNavigate()
  const [showPass, setShowPass] = useState(false)
  const [values, setValues] = useState(null)
  const [error, setError] = useState('')

  const onChangeValues = (value, key) => {
    setValues({ ...values, [key]: value })
  }

  const onSubmit = () => {
    setError('')
    if(!values?.email || !values?.password){
       setTimeout(() => {
           setError(translate('forward_web_please_fill_all_fields'));
       }, 300);
    }else{
      dispatch(login(values))
    }
  }

  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        onSubmit();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [values]);


  return (
    <div className="bodyLogin">
      <div className="loginImageContainer">
        <div className="imageContainer">
           <div className="carPrice">
              {/* <div className="carContainer">
                  <div className="carImage">
                    <img src={car} alt="No pic" className="iconImage" />
                  </div>

                  <div className="carInfo">
                    <span className="carModel">BMW X6 2022</span>
                    <p className="mainText">$ 34,000/<span className="shippingCondition">on way</span></p>
                  </div>
              </div> */}
          </div>
           <div className="titleContainer">
              <div className="titleTextContainer">
                 <p className="title">{translate('forward_web_login_screen_title')}</p>
                  <p className="subtitle">{translate('forward_web_login_screen_title_description')}</p>
              </div>
          </div>
        </div>
      </div>
      <div className="loginInputContainer">
          <div className="loginContainerBack">
            <p className="signInTitle">{translate('forward_web_home_Login_title')}</p>
            {/* <p className="dontAccount">Doesn't have an account? <span onClick={() => alert('Please contact forward administrator')}>Sign Up</span></p> */}
            <Input
                  title={translate('forward_web_login_screen_form_email')}
                  type="email"
                  placeholder="test@email.com"
                  onChange={(v) => onChangeValues(v.target.value, 'email')}
            />
            <Input
                  title={translate('forward_web_login_screen_form_password')}
                  type={showPass ? 'text' : 'password'}
                  placeholder={translate('forward_web_login_screen_form_password')}
                  onChange={(v) => onChangeValues(v.target.value, 'password')}
                  onChangePassShow={() => setShowPass(!showPass)}
                  password
                  // error
            />
            {
              errorLogin?.message? <p className="forgotPassword">{translate('forward_web_status_response_inncorrect_user_name_or_password')}</p> : <p className="forgotPassword">{error}</p>
            }
            <Button name={translate('forward_web_home_Login_title')} onClick={onSubmit} style={{ backgroundColor: variables.dustRed }} />
          </div>
      </div>
    </div>
  )
}
export default React.memo(Login)
