import React, { useEffect, useRef, useContext, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { setUser } from "./redux/auth/actions";
import StorageUtils from "./utils/storage";
import { addTokenToHttp } from "./utils/http";
import { getProfile } from "./redux/app/actions";

import "./styles.scss";
import Home from "./screens/home";
import About from "./screens/about";
import Header from "./components/header";
import Calculator from "./screens/calculator";
import Services from "./screens/services";
import Login from "./screens/login";
import Footer from "./components/footer";
import FooterBottomPanel from "./components/footerBottomPanel";
import Cars from "./screens/cars";
import Users from "./screens/users";
import Editor from "./screens/editor";
import UserCars from "./screens/userCars";
import { LanguageContext } from "./utils/languageContext";
import { Backdrop, CircularProgress } from "@mui/material";

const Navigation = () => {
  const lang = useContext(LanguageContext);
  const token = useRef();
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.Auth.accessToken);
  const profile = useSelector((state) => state.App.profile);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    (async () => {
      token.current = await StorageUtils.getAccessToken();
      dispatch(setUser({}, token?.current));
      addTokenToHttp(token?.current);
      if (accessToken) {
          dispatch(getProfile())
      }
    })();
  }, [accessToken, dispatch]);

  useEffect(() => {
       setLoader(true)
       if(lang?.lang?.length){
         setTimeout(() => {
           setLoader(false)
        }, 770);
      }
  }, [lang?.lang])

  return (
    <>
      {
        !lang?.lang?.length || loader ? <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}><CircularProgress color="inherit" /></Backdrop> : null
      }
      {
        !loader ?
        <>
          <Header />
          <Routes>
            {
              accessToken && profile?.role  ?
                <Route path="/" element={<Navigate to="cars" />} />
              :
                <Route path="/" element={<Navigate to="home" />} />
            }
            {
              accessToken && profile?.role === 'admin' ?
              <>
               <Route path="cars" element={<Cars />} />
               <Route path="users" element={<Users />} />
               <Route path="editor" element={<Editor />} />
              </>
              :  accessToken && profile?.role === 'user' ?
               <Route path="cars" element={<UserCars />} />
             : <Route path="login" element={<Login />} />
            }
            <Route path="home" element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="calculator" element={<Calculator />} />
            <Route path="services" element={<Services />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <Footer />
          <FooterBottomPanel />
        </>
        : null
      }
    </>
  );
};

export default Navigation;
