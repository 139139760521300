import * as actions from './constants'

export const getProfile = () => ({
  type: actions.APP_GET_PROFILE_REQUEST,
})

export const addUsers = (values) => ({
  type: actions.APP_ADD_USERS_REQUEST,
  values
})

export const getAllUsers = () => ({
  type: actions.APP_GET_ALL_USERS_REQUEST,
})

export const editUser = (values) => ({
  type: actions.APP_EDIT_USERS_REQUEST,
  values
})

export const deleteUser = (values) => ({
  type: actions.APP_DELETE_USERS_REQUEST,
  values
})

export const resetPasswordUser = (values) => ({
  type: actions.APP_RESET_PASSWORD_REQUEST,
  values
})

export const addServices = (values) => ({
  type: actions.APP_ADD_SERVICES_REQUEST,
  values
})

export const deleteServices = (values) => ({
  type: actions.APP_DELETE_SERVICES_REQUEST,
  values
})

export const editServices = (values) => ({
  type: actions.APP_EDIT_SERVICES_REQUEST,
  values
})

export const addCalcConversion = (values) => ({
  type: actions.APP_ADD_CALC_CONVERSION_REQUEST,
  values
})

export const editCalcConversion = (values) => ({
  type: actions.APP_EDIT_CALC_CONVERSION_REQUEST,
  values
})

export const deleteCalcConversion = (values) => ({
  type: actions.APP_DELETE_CALC_CONVERSION_REQUEST,
  values
})

export const addAuctionsCars = (values) => ({
  type: actions.APP_ADD_AUCTIONS_CARS_REQUEST,
  values
})

export const deleteAuctionsCars = (values) => ({
  type: actions.APP_DELETE_AUCTIONS_CARS_REQUEST,
  values
})

export const editAuctionsCars = (values) => ({
  type: actions.APP_EDIT_AUCTIONS_CARS_REQUEST,
  values
})

export const editPagesContent = (values, id) => ({
  type: actions.APP_EDIT_PAGES_CONTENT_REQUEST,
  values, id
})

export const getCLientCarsRequest = () => ({
  type: actions.APP_GET_CLIENT_CARS_REQUEST
})

export const addClientCarsRequest = (values, token) => ({
  type: actions.APP_ADD_CLIENT_CARS_REQUEST,
  values,
  token
})

export const deleteClientCar = (id) => ({
  type: actions.APP_DELETE_CLIENT_CARS_REQUEST,
  id
})

export const editClientCarsRequest = (values, token, id) => ({
  type: actions.APP_EDIT_CLIENT_CARS_REQUEST,
  values,
  token,
  id
})

export const deleteLocalPlace = (values) => ({
  type: actions.APP_DELETE_LOCAL_PLACE_REQUEST,
  values
})

export const editLocalPlace = (values) => ({
  type: actions.APP_EDIT_LOCAL_PLACE_REQUEST,
  values
})

export const addLocalPlace = (values) => ({
  type: actions.APP_ADD_LOCAL_PLACE_REQUEST,
  values
})

export const addCopart = (values) => ({
  type: actions.APP_ADD_COPART_REQUEST,
  values
})

export const editCopart = (values) => ({
  type: actions.APP_EDIT_COPART_REQUEST,
  values
})

export const deleteCopart = (values) => ({
  type: actions.APP_DELETE_COPART_REQUEST,
  values
})

export const changeSettings = (id, values) => ({
  type: actions.APP_CONFIG_SETTINGS_REQUEST,
  id,
  values
})
