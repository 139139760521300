import React, { memo, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import './styles.scss'
import { nav_bar_list } from '../../utils/arrays'

import logoIcon from '../../assets/logo-white.png'
import fbIcon from '../../assets/facebook.png'
import instaIcon from '../../assets/instagram.png'
import { translate } from '../../utils/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { getAllPagesContent } from '../../redux/auth/actions'
import { LanguageContext } from '../../utils/languageContext'

const Footer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lang = useContext(LanguageContext);
  const pagesContentList = useSelector((state) => state.Auth.pagesContent);
  const f = pagesContentList?.length ? pagesContentList?.filter((v) => v?.pageName === 'About') : [];

  useEffect(() => {
      setTimeout(() => {
          dispatch(getAllPagesContent())
        }, 10);
  }, [dispatch])

  return (
    <div className="footerBody">
      <div className="footerBodyContainer">
            <div className="footerAboutContainer">
                <img src={logoIcon} alt="Empty" className="footerAboutContainerIcon" />
                <p>{translate('forward_web_home_about_title')}</p>
                <p>{f[0]?.description1[lang?.lang]}</p>
            </div>
           <div className="footerRightBox">
             <div className="footerCompanyContainer">
                <p>{translate('forward_web_footer_panel_company')}</p>
                {
                 nav_bar_list?.map((v) => (
                  <p key={v.path} onClick={() => navigate(v?.path)} className={ window?.location?.pathname === '/' + v?.path ? 'headerSectionSelected' : 'headerSection'}>{translate(v?.name)}</p>
                 ))
                }
            </div>
            <div className="footerContactUsContainer">
                <p>{translate('forward_web_footer_panel_contact_us')}</p>
                <p>{translate('forward_web_address_location')}</p>
                <p>{translate('forward_web_contact_phone_number_first')}</p>
                <p>{translate('forward_web_contact_phone_number_second')}</p>
            </div>
            <div className="footerSocialNetworkContainer">
                <p>{translate('forward_web_footer_panel_social_network')}</p>
                <div onClick={() => window.open('https://www.facebook.com/forward.am', '_blank', 'noreferrer')}>
                  <img src={fbIcon} alt="Empty"/>
                  <p className="socialName">Forward.am</p>
                </div>
                <div onClick={() => window.open('https://www.instagram.com/forward_logistics/', '_blank', 'noreferrer')}>
                  <img src={instaIcon} alt="Empty"/>
                  <p className="socialName">Forward.am</p>
                </div>
            </div>
           </div>
      </div>
    </div>
  )
}

export default memo(Footer)
