import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';
import { translate } from '../../utils/helpers';
const { confirm } = Modal;

export const antModalDelete = (vName, onOkPress) => {
  confirm({
    title: translate('forward_web_modal_delete_info'),
    icon: <ExclamationCircleFilled />,
    content: vName,
    okText: translate('forward_web_info_confirm_yes'),
    okType: 'danger',
    cancelText: translate('forward_web_info_confirm_no'),
    onOk: onOkPress,
    onCancel() {
      console.log('Cancel');
    },
  });
};


export const AddFormModal = ({children, isModalOpen, setIsModalOpen, title}) => {
  return(
     <Modal title={title} open={isModalOpen} onOk={() =>  setIsModalOpen(false)} onCancel={() => setIsModalOpen(false)}>{children}</Modal>
  );
};
