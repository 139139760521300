export const APP_CHANGE_LOADING_STATE = 'APP/CHANGE_LOADING_STATE'
export const APP_CLEAR_STATE = 'APP/CLEAR_STATE'

export const APP_GET_PROFILE_REQUEST = 'APP/GET_PROFILE/REQUEST'
export const APP_GET_PROFILE_SUCCESS = 'APP/GET_PROFILE/SUCCESS'
export const APP_GET_PROFILE_ERROR = 'APP/GET_PROFILE/ERROR'

export const APP_ADD_USERS_REQUEST = 'APP/ADD_USERS/REQUEST'
export const APP_ADD_USERS_SUCCESS = 'APP/ADD_USERS/SUCCESS'
export const APP_ADD_USERS_ERROR = 'APP/ADD_USERS/ERROR'

export const APP_GET_ALL_USERS_REQUEST = 'APP/GET_ALL_USERS/REQUEST'
export const APP_GET_ALL_USERS_SUCCESS = 'APP/GET_ALL_USERS/SUCCESS'
export const APP_GET_ALL_USERS_ERROR = 'APP/GET_ALL_USERS/ERROR'

export const APP_EDIT_USERS_REQUEST = 'APP/EDIT_USERS/REQUEST'
export const APP_EDIT_USERS_SUCCESS = 'APP/EDIT_USERS/SUCCESS'
export const APP_EDIT_USERS_ERROR = 'APP/EDIT_USERS/ERROR'

export const APP_DELETE_USERS_REQUEST = 'APP/DELETE_USERS/REQUEST'
export const APP_DELETE_USERS_SUCCESS = 'APP/DELETE_USERS/SUCCESS'
export const APP_DELETE_USERS_ERROR = 'APP/DELETE_USERS/ERROR'

export const APP_RESET_PASSWORD_REQUEST = 'APP/RESET_PASSWORD/REQUEST'
export const APP_RESET_PASSWORD_SUCCESS = 'APP/RESET_PASSWORD/SUCCESS'
export const APP_RESET_PASSWORD_ERROR = 'APP/RESET_PASSWORD/ERROR'

export const APP_ADD_SERVICES_REQUEST = 'APP/ADD_SERVICES/REQUEST'
export const APP_ADD_SERVICES_SUCCESS = 'APP/ADD_SERVICES/SUCCESS'
export const APP_ADD_SERVICES_ERROR = 'APP/ADD_SERVICES/ERROR'

export const APP_DELETE_SERVICES_REQUEST = 'APP/DELETE_SERVICES/REQUEST'
export const APP_DELETE_SERVICES_SUCCESS = 'APP/DELETE_SERVICES/SUCCESS'
export const APP_DELETE_SERVICES_ERROR = 'APP/DELETE_SERVICES/ERROR'

export const APP_EDIT_SERVICES_REQUEST = 'APP/EDIT_SERVICES/REQUEST'
export const APP_EDIT_SERVICES_SUCCESS = 'APP/EDIT_SERVICES/SUCCESS'
export const APP_EDIT_SERVICES_ERROR = 'APP/EDIT_SERVICES/ERROR'

export const APP_ADD_CALC_CONVERSION_REQUEST = 'APP/ADD_CALC_CONVERSION/REQUEST'
export const APP_ADD_CALC_CONVERSION_SUCCESS = 'APP/ADD_CALC_CONVERSION/SUCCESS'
export const APP_ADD_CALC_CONVERSION_ERROR = 'APP/ADD_CALC_CONVERSION/ERROR'

export const APP_EDIT_CALC_CONVERSION_REQUEST = 'APP/EDIT_CALC_CONVERSION/REQUEST'
export const APP_EDIT_CALC_CONVERSION_SUCCESS = 'APP/EDIT_CALC_CONVERSION/SUCCESS'
export const APP_EDIT_CALC_CONVERSION_ERROR = 'APP/EDIT_CALC_CONVERSION/ERROR'

export const APP_DELETE_CALC_CONVERSION_REQUEST = 'APP/DELETE_CALC_CONVERSION/REQUEST'
export const APP_DELETE_CALC_CONVERSION_SUCCESS = 'APP/DELETE_CALC_CONVERSION/SUCCESS'
export const APP_DELETE_CALC_CONVERSION_ERROR = 'APP/DELETE_CALC_CONVERSION/ERROR'

export const APP_ADD_AUCTIONS_CARS_REQUEST = 'APP/ADD_AUCTIONS_CARS/REQUEST'
export const APP_ADD_AUCTIONS_CARS_SUCCESS = 'APP/ADD_AUCTIONS_CARS/SUCCESS'
export const APP_ADD_AUCTIONS_CARS_ERROR = 'APP/ADD_AUCTIONS_CARS/ERROR'

export const APP_DELETE_AUCTIONS_CARS_REQUEST = 'APP/DELETE_AUCTIONS_CARS/REQUEST'
export const APP_DELETE_AUCTIONS_CARS_SUCCESS = 'APP/DELETE_AUCTIONS_CARS/SUCCESS'
export const APP_DELETE_AUCTIONS_CARS_ERROR = 'APP/DELETE_AUCTIONS_CARS/ERROR'

export const APP_EDIT_AUCTIONS_CARS_REQUEST = 'APP/EDIT_AUCTIONS_CARS/REQUEST'
export const APP_EDIT_AUCTIONS_CARS_SUCCESS = 'APP/EDIT_AUCTIONS_CARS/SUCCESS'
export const APP_EDIT_AUCTIONS_CARS_ERROR = 'APP/EDIT_AUCTIONS_CARS/ERROR'

export const APP_EDIT_PAGES_CONTENT_REQUEST = 'APP/EDIT_PAGES_CONTENT/REQUEST'
export const APP_EDIT_PAGES_CONTENT_SUCCESS = 'APP/EDIT_PAGES_CONTENT/SUCCESS'
export const APP_EDIT_PAGES_CONTENT_ERROR = 'APP/EDIT_PAGES_CONTENT/ERROR'

export const APP_GET_CLIENT_CARS_REQUEST = 'APP/GET_CLIENT_CARS/REQUEST'
export const APP_GET_CLIENT_CARS_SUCCESS = 'APP/GET_CLIENT_CARS/SUCCESS'
export const APP_GET_CLIENT_CARS_ERROR = 'APP/GET_CLIENT_CARS/ERROR'

export const APP_ADD_CLIENT_CARS_REQUEST = 'APP/ADD_CLIENT_CARS/REQUEST'
export const APP_ADD_CLIENT_CARS_SUCCESS = 'APP/ADD_CLIENT_CARS/SUCCESS'
export const APP_ADD_CLIENT_CARS_ERROR = 'APP/ADD_CLIENT_CARS/ERROR'

export const APP_DELETE_CLIENT_CARS_REQUEST = 'APP/DELETE_CLIENT_CARS/REQUEST'
export const APP_DELETE_CLIENT_CARS_SUCCESS = 'APP/DELETE_CLIENT_CARS/SUCCESS'
export const APP_DELETE_CLIENT_CARS_ERROR = 'APP/DELETE_CLIENT_CARS/ERROR'

export const APP_EDIT_CLIENT_CARS_REQUEST = 'APP/EDIT_CLIENT_CARS/REQUEST'
export const APP_EDIT_CLIENT_CARS_SUCCESS = 'APP/EDIT_CLIENT_CARS/SUCCESS'
export const APP_EDIT_CLIENT_CARS_ERROR = 'APP/EDIT_CLIENT_CARS/ERROR'

export const APP_ADD_LOCAL_PLACE_REQUEST = 'APP/ADD_LOCAL_PLACE/REQUEST'
export const APP_ADD_LOCAL_PLACE_SUCCESS = 'APP/ADD_LOCAL_PLACE/SUCCESS'
export const APP_ADD_LOCAL_PLACE_ERROR = 'APP/ADD_LOCAL_PLACE/ERROR'

export const APP_DELETE_LOCAL_PLACE_REQUEST = 'APP/DELETE_LOCAL_PLACE/REQUEST'
export const APP_DELETE_LOCAL_PLACE_SUCCESS = 'APP/DELETE_LOCAL_PLACE/SUCCESS'
export const APP_DELETE_LOCAL_PLACE_ERROR = 'APP/DELETE_LOCAL_PLACE/ERROR'

export const APP_EDIT_LOCAL_PLACE_REQUEST = 'APP/EDIT_LOCAL_PLACE/REQUEST'
export const APP_EDIT_LOCAL_PLACE_SUCCESS = 'APP/EDIT_LOCAL_PLACE/SUCCESS'
export const APP_EDIT_LOCAL_PLACE_ERROR = 'APP/EDIT_LOCAL_PLACE/ERROR'

export const APP_ADD_COPART_REQUEST = 'APP/ADD_COPART/REQUEST'
export const APP_ADD_COPART_SUCCESS = 'APP/ADD_COPART/SUCCESS'
export const APP_ADD_COPART_ERROR = 'APP/ADD_COPART/ERROR'

export const APP_EDIT_COPART_REQUEST = 'APP/EDIT_COPART/REQUEST'
export const APP_EDIT_COPART_SUCCESS = 'APP/EDIT_COPART/SUCCESS'
export const APP_EDIT_COPART_ERROR = 'APP/EDIT_COPART/ERROR'

export const APP_DELETE_COPART_REQUEST = 'APP/DELETE_COPART/REQUEST'
export const APP_DELETE_COPART_SUCCESS = 'APP/DELETE_COPART/SUCCESS'
export const APP_DELETE_COPART_ERROR = 'APP/DELETE_COPART/ERROR'

export const APP_CONFIG_SETTINGS_REQUEST = 'APP/CONFIG_SETTINGS/REQUEST'
export const APP_CONFIG_SETTINGS_SUCCESS = 'APP/CONFIG_SETTINGS/SUCCESS'
export const APP_CONFIG_SETTINGS_ERROR = 'APP/CONFIG_SETTINGS/ERROR'