/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddFormModal } from '../../components/antModal';
import AntTable from '../../components/antTable';
import Button from '../../components/button';
import { editPagesContent } from '../../redux/app/actions';
import { getAllPagesContent } from '../../redux/auth/actions'
import { translate } from '../../utils/helpers';
import Input from '../../components/input';

const MainEditor = ({ isOpenModal, onCloseModal }) => {
    const dispatch = useDispatch();
    const pagesContentList = useSelector((state) => state.Auth.pagesContent);
    const [homeMainId, setHomeMainId] = useState('');
    const [homeSecondId, setHomeSecondId] = useState('');
    const [calcId, setCalcId] = useState('');
    const [globalId, setGlobalId] = useState('');
    const [youtubeId, setYoutubeId] = useState('');
    const [laodingCall, setLoadingCall] = useState(false);
    const [onOpenEditModal, setOnOpenEditModal] = useState(false);
    const [onOpenEditModalImage, setOnOpenEditModalImage] = useState(false);
    const [valuesEdit, setValuesEdit] = useState({
        titleAM: '',
        titleRU: '',
        titleUS: '',
        descriptionAM: '',
        descriptionRU: '',
        descriptionUS: ''
     })
    const [uploadImageEdit, setuploadImageEdit] = useState({})

    useEffect(() => {
        dispatch(getAllPagesContent())
        setLoadingCall(false)
    }, [dispatch, laodingCall])

    const f = pagesContentList?.length ? pagesContentList?.find((v) => v?.pageName === 'HomeMain') : {}
    const a = pagesContentList?.length ? pagesContentList?.find((v) => v?.pageName === 'HomeSecond') : {}
    const cal = pagesContentList?.length ? pagesContentList?.find((v) => v?.pageName === 'CalculatorDescription') : {}
    const youtube = pagesContentList?.length ? pagesContentList?.find((v) => v?.pageName === 'youtube') : {}

    useEffect(() => {
        if(f?._id){
            setHomeMainId(f?._id)
        }
        if(a?._id){
            setHomeSecondId(a?._id)
        }
        if(cal?._id){
            setCalcId(cal?._id)
        }
        if(youtube?._id){
            setYoutubeId(youtube?._id)
        }
    }, [f, a, cal, youtube])

    const data = [
        {
            key: f._id,
            titleAM: f?.title?.am,
            titleRU: f?.title?.ru,
            titleUS: f?.title?.us,
            descriptionAM: f?.description?.am,
            descriptionRU: f?.description?.ru,
            descriptionUS: f?.description?.us,
        },
        {
            key: a._id,
            titleAM: a?.title?.am,
            titleRU: a?.title?.ru,
            titleUS: a?.title?.us,
            descriptionAM: a?.description?.am,
            descriptionRU: a?.description?.ru,
            descriptionUS: a?.description?.us,
        },
        {
            key: cal._id,
            titleAM: cal?.title?.am,
            titleRU: cal?.title?.ru,
            titleUS: cal?.title?.us,
            descriptionAM: cal?.description?.am,
            descriptionRU: cal?.description?.ru,
            descriptionUS: cal?.description?.us,
        },
        {
            key: youtube._id,
            titleAM: youtube?.title?.am,
            titleRU: youtube?.title?.ru,
            titleUS: youtube?.title?.us,
            descriptionAM: youtube?.description?.am,
            descriptionRU: youtube?.description?.ru,
            descriptionUS: youtube?.description?.us,
        },
    ]

    const columnsAbout = [
        {
            dataIndex: 'titleAM',
            title: translate('forward_web_info_content_editor_services_title') + ' AM',
        },
        {
            dataIndex: 'titleRU',
            title: translate('forward_web_info_content_editor_services_title') + ' RU',
        },
        {
            dataIndex: 'titleUS',
            title: translate('forward_web_info_content_editor_services_title') + ' US',
        },
    ];

    const onOpenEdit = (e) => {
        setOnOpenEditModal(true)
        setValuesEdit({
            id: e?.key,
            titleAM: e?.titleAM,
            titleRU: e?.titleRU,
            titleUS: e?.titleUS,
            descriptionAM: e?.descriptionAM,
            descriptionRU: e?.descriptionRU,
            descriptionUS: e?.descriptionUS
        })
    }

    const onConfirmEditServices = () => {
         if(!valuesEdit?.id || !valuesEdit?.titleAM || !valuesEdit?.titleRU || !valuesEdit?.titleUS || !valuesEdit?.descriptionAM || !valuesEdit?.descriptionRU || !valuesEdit?.descriptionUS){
           alert(translate('forward_web_please_fill_all_fields'))
        }else{
            const x = {
                title: { am: valuesEdit?.titleAM, us: valuesEdit?.titleUS, ru: valuesEdit?.titleRU  },
                description: { am: valuesEdit?.descriptionAM, us: valuesEdit?.descriptionUS, ru: valuesEdit?.descriptionRU  }
            }
            dispatch(editPagesContent(x, valuesEdit?.id))
            setOnOpenEditModal(false)
            setLoadingCall(true)
        }
    }

    const onChangeImage = (e) => {
        if(e?.key === calcId || e?.key === youtubeId){
          onCloseModal(true)
        }else{
          setGlobalId(e.key)
          setOnOpenEditModalImage(true)
        }
    }

    const onEditImage = () => {
        const xId = globalId
        const x = { icon:  uploadImageEdit?.target?.files[0] }

        if(!x.icon || !xId ){
                setuploadImageEdit(null)
                alert('ops not valid')
                setOnOpenEditModalImage(false)
        }else{
            dispatch(editPagesContent(x, xId))
            setOnOpenEditModalImage(false)
            setuploadImageEdit(null)
            setLoadingCall(true)
        }
    }

return (
    <>
        <AddFormModal isModalOpen={onOpenEditModalImage}
             setIsModalOpen={(e) => setOnOpenEditModalImage(!onOpenEditModalImage)}
             title={translate('forward_web_users_add_button')}>
            <div className='formAdd'>
                <Input title={translate('forward_web_info_home_auctions_cars_editor_image')} onChange={(v) =>  setuploadImageEdit(v)} style={stylesInput} type='file'  />
                <br/>
                <Button name={translate('forward_web_info_confirm_submit')} onClick={() => onEditImage()} style={{ backgroundColor: 'green', color: 'white'  }} />
            </div>
        </AddFormModal>

        <AddFormModal isModalOpen={onOpenEditModal}
             setIsModalOpen={(e) => setOnOpenEditModal(e)}
             title={translate('forward_web_users_edit_button')}>
            <div className='formAdd'>
                <Input title={translate('forward_web_info_content_editor_services_title') + ' AM'} onChange={(v) =>  setValuesEdit({ ...valuesEdit, titleAM:  v.target.value })} value={valuesEdit?.titleAM} style={stylesInput}  />
                <Input title={translate('forward_web_info_content_editor_services_title') + ' RU'} onChange={(v) =>  setValuesEdit({ ...valuesEdit, titleRU:  v.target.value })} value={valuesEdit?.titleRU} style={stylesInput}  />
                <Input title={translate('forward_web_info_content_editor_services_title') + ' US'} onChange={(v) =>  setValuesEdit({ ...valuesEdit, titleUS:  v.target.value })} value={valuesEdit?.titleUS} style={stylesInput}  />

                <Input title={translate('forward_web_info_content_editor_services_description') + ' AM'} onChange={(v) =>  setValuesEdit({ ...valuesEdit, descriptionAM:  v.target.value })} value={valuesEdit?.descriptionAM} style={stylesInput}  />
                <Input title={translate('forward_web_info_content_editor_services_description') + ' RU'} onChange={(v) =>  setValuesEdit({ ...valuesEdit, descriptionRU:  v.target.value })} value={valuesEdit?.descriptionRU} style={stylesInput}  />
                <Input title={translate('forward_web_info_content_editor_services_description') + ' US'} onChange={(v) =>  setValuesEdit({ ...valuesEdit, descriptionUS:  v.target.value })} value={valuesEdit?.descriptionUS} style={stylesInput}  />

                <br/>
                <Button name={translate('forward_web_info_confirm_submit')} onClick={() => onConfirmEditServices()} style={{ backgroundColor: 'green', color: 'white'  }} />
            </div>
        </AddFormModal>
        <AddFormModal isModalOpen={isOpenModal}
             setIsModalOpen={(e) => onCloseModal(e)}
             title={translate('forward_web_users_add_button')}>
            <div className='formAdd'>
                <p>{translate('forward_web_do_not_add_new_data')}</p>
            </div>
        </AddFormModal>
        <AntTable
            data={data}
            column={columnsAbout}
            onEdit={(e) => onOpenEdit(e)}
            onChangeImage={(e) => onChangeImage(e)}
            expandableFormKeys={[
                  { key: 'descriptionAM', name: translate('forward_web_info_content_editor_services_description') + ' AM' },
                  { key: 'descriptionRU', name: translate('forward_web_info_content_editor_services_description') + ' RU' },
                  { key: 'descriptionUS', name: translate('forward_web_info_content_editor_services_description') + ' US' }
            ]}
        />
    </>
  )
}
const stylesInput = { color: 'black' };

export default React.memo(MainEditor)
