const Constants = {
  ACCESS_TOKEN: "access_token",
  USER: "user",
  LANG: "lang",
};

const StorageUtils = {
  getLanguage: async () => {
    const lang = await localStorage.getItem(Constants.LANG);
    return lang;
  },

  setLanguage: async (lang) => {
    await localStorage.setItem(Constants.LANG, lang);
  },

  getAccessToken: async () => {
    const token = await localStorage.getItem(Constants.ACCESS_TOKEN);
    return token;
  },

  setAccessToken: async (token) => {
    await localStorage.setItem(Constants.ACCESS_TOKEN, token);
  },

  removeAccessToken: async () => {
    await localStorage.removeItem(Constants.ACCESS_TOKEN);
  },

  getUser: async () => {
    const user = await localStorage.getItem(Constants.USER);
    return user ? JSON.parse(user) : {};
  },

  setUser: async (user) => {
    const userObject = user ? JSON.stringify(user) : null;
    await localStorage.setItem(Constants.USER, userObject);
  },

  removeUser: async () => {
    await localStorage.removeItem(Constants.USER);
  },
};

export default StorageUtils;
