import { I18n } from "i18n-js";
import { translations } from "../translationData/translations.js";
import { RU } from "./langConstatnts.js";
import StorageUtils from "./storage.js";

export const systemAccountMgmt = "system@forward.am";
export const i18n = new I18n(translations);

export const getLocale = async () => {
  const l = await StorageUtils.getLanguage();
  i18n.locale = l || RU.toLowerCase();
  return l;
};

export const translate = (key, config) => i18n.t(key, config);
export const convertSelectedLanguage = (lang) => {
  if (lang === "us") {
    return "en";
  } else {
    return lang;
  }
};

export const removeDecimalPartsOfNumbers = (num) => {
  if (num) {
    const decimal = (Number(num) - Math.floor(Number(num)))
      .toFixed(2)
      .toString()
      .split(".")[1];
    if (decimal === "00") {
      return parseInt(num, 10).toString();
    }
    return Number(num).toFixed(2).toString();
  }
};
