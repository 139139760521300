import React, { useState } from 'react';
import Button from '../../components/button';

import './styles.scss';
import Selector from '../../components/Selector'
import { translate } from '../../utils/helpers';
import ServiceEditor from './service-editor';
import variables from "../../styles/variables.scss";
import CalcEditor from './calc-editor';
import AuctionCar from './auction-car';
import AboutEditor from './about-editor';
import MainEditor from './main-editor';
import CalcEditorCopart from './calc-editor-copart';
import LocationEditor from './location';
import { useSelector } from 'react-redux';
import { Backdrop, CircularProgress } from '@mui/material';
import Settings from './settings';

const Editor = () => {
  const tableName = [
    { name: translate('forward_web_home_home_title'), value: translate('forward_web_home_home_title')},
    { name: translate('forward_web_home_about_title'), value: translate('forward_web_home_about_title')},
    { name: translate("forward_web_home_services_title"), value: translate("forward_web_home_services_title")},
    { name: translate("forward_web_info_home_auctions_cars_editor"), value: translate("forward_web_info_home_auctions_cars_editor")},
    { name: `${translate('forward_web_home_calculator_title')} : iaai `, value: translate('forward_web_home_calculator_title')+ ' iaai'},
    { name: `${translate('forward_web_home_calculator_title')} : copart `, value: translate('forward_web_home_calculator_title')+' copart'},
    { name: translate("forward_web_do_not_add_new_data_car_order_data_location"), value: translate("forward_web_do_not_add_new_data_car_order_data_location")},
    { name: translate("forward_web_settings_page_title"), value: translate("forward_web_settings_page_title")},
  ];

  const [selectedEditor, setSelectedEditor] = useState(tableName[0].value)
  const [addOpenModal, setAddOpenModal] = useState({ services: false, calculator: false })
  const isLoading = useSelector((state) => state.App.isLoading);


  const openEditorsModals = () => {
      switch (selectedEditor) {
        case tableName[0].value:
              setAddOpenModal({...addOpenModal, main: !addOpenModal.main})
          break;
        case tableName[1].value:
              setAddOpenModal({...addOpenModal, about: !addOpenModal.about})
          break;
        case tableName[2].value:
              setAddOpenModal({...addOpenModal, services: !addOpenModal.services})
          break;
        case tableName[3].value:
              setAddOpenModal({...addOpenModal, auctionCar: !addOpenModal.auctionCar})
          break;
        case tableName[4].value:
              setAddOpenModal({...addOpenModal, calculator: !addOpenModal.calculator})
          break;
        case tableName[5].value:
              setAddOpenModal({...addOpenModal, calculator: !addOpenModal.calculator})
          break;
        case tableName[6].value:
              setAddOpenModal({...addOpenModal, location: !addOpenModal.location})
          break;
        case tableName[7].value:
              setAddOpenModal({...addOpenModal, settings: !addOpenModal.settings})
          break;
        default:
          break;
      }
  }

  return (
    <div className="bodyEditor">
       {
        isLoading ?
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            onClick={() => {}}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        : null
      }
       <div className='editorHeader'>
        <div>
            <p className='editorName'>{translate('forward_web_info_content_editor_title')}:  <b className='strongName'>{selectedEditor}</b></p>
            <Selector data={tableName} onChange={(v) => setSelectedEditor(v.target.value)} optionStyle={{
              backgroundColor: 'transparent',
              color: variables.primaryBlack
            }}/>
        </div>
         <div className='buttonContainer'>
            <Button name={"+ " + selectedEditor} onClick={() => openEditorsModals()} style={{ backgroundColor: 'green',  }} />
         </div>
       </div>
       <div className='tableContainer'>
        {
              selectedEditor === translate('forward_web_home_home_title') ?
               <MainEditor isOpenModal={addOpenModal?.main} onCloseModal={(e) => setAddOpenModal({...addOpenModal, main: e})} />
            : selectedEditor === translate('forward_web_home_about_title') ?
              <AboutEditor isOpenModal={addOpenModal?.about} onCloseModal={(e) => setAddOpenModal({...addOpenModal, about: e})} />
            : selectedEditor === translate('forward_web_home_calculator_title')+ ' iaai' ?
              <CalcEditor isOpenModal={addOpenModal?.calculator} onCloseModal={(e) => setAddOpenModal({...addOpenModal, calculator: e})} />
            : selectedEditor === translate("forward_web_home_services_title") ?
              <ServiceEditor isOpenModal={addOpenModal?.services} onCloseModal={(e) => setAddOpenModal({...addOpenModal, services: e})} />
            : selectedEditor === translate("forward_web_info_home_auctions_cars_editor") ?
              <AuctionCar isOpenModal={addOpenModal?.auctionCar} onCloseModal={(e) => setAddOpenModal({...addOpenModal, auctionCar: e})}/>
            :  selectedEditor === translate("forward_web_home_calculator_title")+' copart' ?
              <CalcEditorCopart  isOpenModal={addOpenModal?.calculator} onCloseModal={(e) => setAddOpenModal({...addOpenModal, calculator: e})}/>
            : selectedEditor === translate("forward_web_do_not_add_new_data_car_order_data_location") ?
              <LocationEditor isOpenModal={addOpenModal?.location} onCloseModal={(e) => setAddOpenModal({...addOpenModal, location: e})}/>
            :  selectedEditor === translate("forward_web_settings_page_title") ?
              <Settings isOpenModal={addOpenModal?.settings} onCloseModal={(e) => setAddOpenModal({...addOpenModal, settings: e})}/>
            : null
        }
       </div>
    </div>
  )
}

export default React.memo(Editor)
