export const nav_bar_list = [
    { name: 'forward_web_home_home_title', path: 'home' },
    { name: 'forward_web_home_about_title', path: 'about' },
    { name: 'forward_web_home_calculator_title', path: 'calculator' },
    { name: 'forward_web_home_services_title', path: 'services' },
]
export const loggedIn_nav_bar_list = [
    { name: 'forward_web_logggedin_list_menu_users', navLink: 'users', icon: 'user.png' },
    { name: 'forward_web_logggedin_list_menu_cars', navLink: 'cars', icon: 'car.png' },
    { name: 'forward_web_logggedin_list_menu_edit', navLink: 'editor', icon: 'love.png' },
    { name: 'forward_web_logggedin_list_menu_logout', navLink: 'logout', icon: 'logout.png' },
]
export const loggedIn_nav_bar_list_user = [
    { name: 'forward_web_logggedin_list_menu_cars', navLink: 'cars', icon: 'car.png' },
    { name: 'forward_web_logggedin_list_menu_logout', navLink: 'logout', icon: 'logout.png' },
]
