import React, { memo } from 'react'
import { translate } from '../../utils/helpers'

import './styles.scss'

const FooterBottomPanel = () => {
  const year = new Date().getFullYear()

return (
 <div className="footerBottomPanelBody">
   <div className="footerBottomContainer">
     <div>
        {/* <p>Privacy Policy</p>
        <p>Terms & Conditions</p> */}
    </div>
    <div>
        <p>{translate('forward_web_contact_phone_number_first')}</p>&ensp;
        &ensp;<p>© {year} Forward.am</p>
    </div>
   </div>
 </div>
  )
}
export default memo(FooterBottomPanel)
