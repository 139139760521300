import React, { memo, useEffect, useState } from "react";

import "./styles.scss";
import logoIcon from "../../assets/logo-white.png";
import burgerIcon from "../../assets/burger.png";
import Burger from "../burger";
import { loggedIn_nav_bar_list, loggedIn_nav_bar_list_user, nav_bar_list } from "../../utils/arrays";
import LangFlagSelected from "../langFlagsSelected";
import { useNavigate } from "react-router-dom";
import arrowIcon from "../../assets/arrow.png";
import { RU } from "../../utils/langConstatnts";
import StorageUtils from "../../utils/storage";
import { getLocale, translate } from "../../utils/helpers";
import { createBrowserHistory } from "history";
import { useContext } from "react";
import { LanguageContext } from "../../utils/languageContext";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/auth/actions";

const Header = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const history = createBrowserHistory();
  const profile = useSelector((state) => state.App.profile);
  const token = useSelector((state) => state.Auth.accessToken);
  const { setLang } = useContext(LanguageContext);
  const [selected, setSelected] = useState("");
  const [openBurger, setOpenBurger] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);

  useEffect(() => {
    (async () => {
      const l = await getLocale();
      setSelected(l ? l?.toUpperCase() : RU);
      if (!l) {
        await StorageUtils.setLanguage(RU.toLowerCase());
        setLang(RU.toLowerCase());
      }
    })();
  }, []);

  const onSelect = async (l) => {
    setSelected(l);
    await StorageUtils.setLanguage(l.toLowerCase());
    setLang(l.toLowerCase());
    // history.push(history?.location?.pathname);
    // history.go(0);
  };

  const onNav = (v) =>{
     if(v === 'logout'){
      dispatch(logout())
      history.push('/login');
      history.go(0);
     }
     navigate(v);
  }
  return (
    <div className="headerBody">
      {openBurger ? (
        <Burger isOpen={openBurger} onClose={() => setOpenBurger(false)} />
      ) : null}
      <div className="headerIconContainer">
        <img
          src={burgerIcon}
          alt="Empty"
          className="headerIconBurger"
          onClick={() => setOpenBurger(true)}
        />
        <img src={logoIcon} alt="Empty" className="headerIconLogo" />
        <div className="helperContainer" />
      </div>
      {
        token ?
        <div className="loggedInMobile">
        <img
          className="iconLoggedin"
          onClick={() => setOpenBurger(true)}
          src={require(`../../assets/icons/user.jpg`)}
          alt="icon"
        />
      </div> : null
      }
      <div className="headerMainContainer">
        {nav_bar_list?.map((v) => (
          <p
            key={v.name}
            onClick={() => navigate(v?.path)}
            className={
              window.location.pathname === "/" + v?.path
                ? "headerSectionSelected"
                : "headerSection"
            }
          >
            {translate(v?.name)}
          </p>
        ))}
      </div>
      <div className="headerLoginContainer">
        <LangFlagSelected selected={selected} onSelect={onSelect} />
        {token ? (
          <div className="headerLoginMenu">
            <div
              className="userNameArrowIcon"
              onClick={() => setAnchorEl(!anchorEl)}
            >
              <p onClick={() => setAnchorEl(true)}>{profile?.fullName}</p>
              <img className="iconListContainer" src={arrowIcon} alt="icon" />
            </div>
            {anchorEl ? (
              <div className="headerLoginMenuList">
                {
                  profile?.role === 'admin' ?
                    loggedIn_nav_bar_list?.map((v) => {
                        return (
                          <div
                            key={v.name}
                            className="listContainer"
                            onClick={() => {
                              onNav(v?.navLink);
                              setAnchorEl(false);
                            }}
                          >
                            <img
                              className="iconListContainer"
                              src={require(`../../assets/icons/${v.icon}`)}
                              alt="icon"
                            />
                            <p>{translate(v?.name)}</p>
                          </div>
                        );
                      })
                  : loggedIn_nav_bar_list_user?.map((v) => {
                        return (
                          <div
                            key={v.name}
                            className="listContainer"
                            onClick={() => {
                              onNav(v?.navLink);
                              setAnchorEl(false);
                            }}
                          >
                            <img
                              className="iconListContainer"
                              src={require(`../../assets/icons/${v?.icon}`)}
                              alt="icon"
                            />
                            <p>{translate(v?.name)}</p>
                          </div>
                        );
                      })
                }
              </div>
            ) : null}
          </div>
        ) : (
          <p
            onClick={() => navigate("login")}
            className={
              window.location.pathname === "/login"
                ? "headerLoginSelected"
                : "headerLogin"
            }
          >
            {translate('forward_web_home_Login_title')}
          </p>
        )}
        <p className="loginSpacer">|</p>
        <p className="headerPhone">{translate('forward_web_contact_phone_number_first')}</p>
      </div>
    </div>
  );
};

export default memo(Header);
