import React from "react";
import "./styles.scss";
import paper from '../../assets/paper.png'

const ServicesCard = ({keyid, name, description}) => {
  return (
    <div key={keyid} className='servicesCardBody'>
       <div  className="container">
         <img src={paper} className="iconServices" alt='icon'/>
         <h2>{name}</h2>
         <p>{description}</p>
       </div>
    </div>
  );
};
export default React.memo(ServicesCard);
