import React from "react";
import "./styles.scss";

const Button = ({ name, onClick, style }) => {
  return (
    <div
      style={{
        backgroundColor: style?.backgroundColor
          ? style?.backgroundColor
          : "transparent",
        borderColor: style?.borderColor ? style?.borderColor : "transparent",
        width: style?.width ? style?.width : "100%",
        borderWidth: 1,
        borderStyle: "solid",
        height: style?.height,
      }}
      className="bodyButton"
      onClick={onClick}
    >
      <p>{name}</p>
    </div>
  );
};
export default React.memo(Button);
