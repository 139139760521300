import React from "react";
import ReactFlagsSelect from "react-flags-select";
import "./styles.scss";

const LangFlagSelected = ({ selected, onSelect, burger }) => {
  const customLabels = { AM: "AM", US: "EN", RU: "RU" };

  return (
    <ReactFlagsSelect
      className={burger ? "menu-flags-class" : null}
      selectButtonClassName="menu-flags"
      selected={selected}
      onSelect={onSelect}
      showSelectedLabel
      showOptionLabel
      customLabels={customLabels}
      countries={["AM", "US", "RU"]}
      // fullWidth={false}
      // disabled
    />
  );
};
export default React.memo(LangFlagSelected);
