import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ServicesCard from '../../components/servicesCard'
import { getAllServices } from '../../redux/auth/actions'
import { translate } from '../../utils/helpers'
import { LanguageContext } from '../../utils/languageContext'
import './styles.scss'

const Services = () => {
  const dispatch = useDispatch();
  const allServices = useSelector((state) => state.Auth.services);
  const lang = useContext(LanguageContext);

  useEffect(() => {
    setTimeout(() => {
      dispatch(getAllServices())
    }, 10);
   }, [dispatch])

  const filteredData = allServices?.length ? allServices?.map((v) => {
    return {
        id: v?._id,
        name: v?.name[lang?.lang],
        description: v?.description[lang?.lang]
    }
  }) : null

  return (
        <div className="bodyServices">
                <div className="servicesContainer">
                    <h1>{translate('forward_web_home_services_title')}</h1>
                    <p>{translate('forward_web_home_services_title_description')}</p>
                    <div className='servicesListBody'>
                        {
                            filteredData?.length ? filteredData?.map((v) =>(
                                <div key={v?.id}>
                                    <ServicesCard keyid={v?.id} name={v?.name} description={v?.description} />
                                </div>
                            )) : null
                        }
                    </div>
                </div>
        </div>
  )
}
export default React.memo(Services)
