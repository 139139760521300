import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddFormModal, antModalDelete } from '../../components/antModal';
import AntTable from '../../components/antTable';
import Button from '../../components/button';
import { addLocalPlace, deleteLocalPlace, editLocalPlace } from '../../redux/app/actions';
import { getLocalPlace } from '../../redux/auth/actions'
import { translate } from '../../utils/helpers';
import Input from '../../components/input';

const LocationEditor = ({ isOpenModal, onCloseModal }) => {
    const dispatch = useDispatch();
    const localPLace = useSelector((state) => state.Auth.localPlace);
    const [laodingCall, setLoadingCall] = useState(false);
    const [onOpenEditModal, setOnOpenEditModal] = useState(false);
    const [valuesEdit, setValuesEdit] = useState({
        id: '',
        name: '',
        price: '',
    })
    const [values, setValues] = useState({
        name: '',
        price: '',
    })

    useEffect(() => {
        setTimeout(() => {
            dispatch(getLocalPlace())
            setLoadingCall(false)
        }, 900);
    }, [dispatch, laodingCall])

    const dataForCalc = localPLace?.localPlace?.length ? localPLace?.localPlace?.map((v) => ({
        key: v?._id,
        id: v?._id,
        name: v?.name,
        price: v?.price,
    })) : []

    const columnsCalc = [
        {
            dataIndex: 'name',
            title: translate('forward_web_info_content_editor_country_name_shipping'),
        },
        {
            dataIndex: 'price',
            title: translate('forward_web_info_content_editor_country_price_shipping'),
        }
    ];

    const onConfirmDelete = (e) => {
        const x = { id: e?.id }
        dispatch(deleteLocalPlace(x))
        setLoadingCall(true)
    }

    const onAddNewConversion = () => {
        if(!values?.name || !values?.price){
            alert(translate('forward_web_please_fill_all_fields'))
        }else{
            const x = {
              name: values?.name,
              price: values?.price,
            }
            dispatch(addLocalPlace(x))
            setLoadingCall(true)
            onCloseModal(false)
            setValues({
                name: '',
                price: '',
            })
        }
    }

    const onOpenEdit = (e) => {
        setOnOpenEditModal(true)
        setValuesEdit({
            id: e?.id,
            name: e?.name,
            price: e?.price,
        })
    }

    const onConfirmEditServices = () => {
         if(!valuesEdit?.name || !valuesEdit?.price){
            alert(translate('forward_web_please_fill_all_fields'))
        }else{
            const x = {
                id: valuesEdit?.id,
                name: valuesEdit?.name,
                price: valuesEdit?.price,
            }
            dispatch(editLocalPlace(x))
            setLoadingCall(true)
            setOnOpenEditModal(false)
        }
    }

return (
    <>
         <AddFormModal isModalOpen={onOpenEditModal}
             setIsModalOpen={(e) => setOnOpenEditModal(e)}
             title={translate('forward_web_users_edit_button')}>
            <div className='formAdd'>
                <Input title={translate('forward_web_info_content_editor_country_name_shipping')} onChange={(v) =>  setValuesEdit({ ...valuesEdit, name:  v.target.value })} value={valuesEdit?.name} style={stylesInput}  />
                <Input title={translate('forward_web_info_content_editor_country_price_shipping')} onChange={(v) =>  setValuesEdit({ ...valuesEdit, price:  Number(v.target.value) })} value={valuesEdit?.price} style={stylesInput} type='number'  />
                <br/>
                <Button name={translate('forward_web_info_confirm_submit')} onClick={() => onConfirmEditServices()} style={{ backgroundColor: 'green', color: 'white'  }} />
            </div>
        </AddFormModal>
        <AddFormModal isModalOpen={isOpenModal}
             setIsModalOpen={(e) => onCloseModal(e)}
             title={translate('forward_web_users_add_button')}>
            <div className='formAdd'>
                <Input title={translate('forward_web_info_content_editor_country_name_shipping')} onChange={(v) =>  setValues({ ...values, name: v.target.value })} value={values?.name} style={stylesInput}  />
                <Input title={translate('forward_web_info_content_editor_country_price_shipping')} onChange={(v) =>  setValues({ ...values, price: Number(v.target.value) })} value={values?.price} style={stylesInput} type='number'  />
                <br/>
                <Button name={translate('forward_web_info_confirm_submit')} onClick={() => onAddNewConversion()} style={{ backgroundColor: 'green', color: 'white'  }} />
            </div>
        </AddFormModal>
        <AntTable
            data={dataForCalc}
            column={columnsCalc}
            onEdit={(e) => onOpenEdit(e)}
            onDelete={(e) => antModalDelete(e?.id, () => onConfirmDelete(e))}
            expandableFormKeys={[
                {key: 'id', name: 'Id'},
            ]}
        />
    </>
  )
}
const stylesInput = { color: 'black' };

export default React.memo(LocationEditor)
