/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useEffect, useState } from "react";
import "./styles.scss";
import Input from "../../components/input";
import iaai from "../../assets/iaai.png";
import copart from "../../assets/copart.png";
import Selector from "../../components/Selector";
import Button from "../../components/button";
import variables from "../../styles/variables.scss";
import { useDispatch, useSelector } from "react-redux";
import { removeDecimalPartsOfNumbers, translate } from "../../utils/helpers";
import { makePDF } from "../../utils/pdf";
import ColoredCircle from "../../components/coloredCircle";
import {
  AAH,
  maxaturq,
  achurdiMijnordavchar,
  forPDF,
  vehicleGazolineType,
  vehicleHybridType,
  vehicleDieselType,
  vehicleElectricType,
  getAgeDifference,
  bnapahpanakanHark,
} from "../../calculatorCount";
import {
  getAllCalculatorConversion,
  getAllPagesContent,
  getCopart,
  getExchangeRate,
  getLocalPlace,
  getSettings,
} from "../../redux/auth/actions";
import { LanguageContext } from "../../utils/languageContext";
import { createBrowserHistory } from "history";
import { Backdrop, CircularProgress } from "@mui/material";

const Calculator = () => {
  const dispatch = useDispatch();
  const history = createBrowserHistory();
  const lang = useContext(LanguageContext);
  const isLoading = useSelector((state) => state.App.isLoading);
  const iaaiList = useSelector((state) => state.Auth.calculatorConversion);
  const copartList = useSelector((state) => state.Auth.copart);
  const { localPlace } = useSelector((state) => state.Auth.localPlace);
  const pagesContentList = useSelector((state) => state.Auth.pagesContent);
  const exchangeRate = useSelector((state) => state.Auth.exchangeRate);

    const settingsData = useSelector((state) => state.Auth.settings);
    const filteredDataSettings = settingsData?.length  ?  settingsData?.filter((v) => v.settingsName === 'calculator') : []

  const f = pagesContentList?.length
    ? pagesContentList?.filter((v) => v?.pageName === "CalculatorDescription")
    : [];
  const [currency, setCurrency] = useState({});
  const [carPrice, setCarPrice] = useState();
  const [auction, setAuction] = useState("");
  const [auctionFee, setAuctionFee] = useState(0);
  const [carShippingFee, setCarShippingFee] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [vehicleAge, setVehicleAge] = useState();
  const [year, setYear] = useState();
  const [month, setMonth] = useState();
  const [engine, setEngine] = useState();
  const [exchanges, setExchanges] = useState({
    USD: 0,
    AMD: 0,
    RUB: 0,
  });
  const [fuelType, setFuelType] = useState("");
  const [maqsayinRate, setMaqsayinRate] = useState();
  const [maqsayin, setMaqsayin] = useState(0);
  const [aah, setAah] = useState(0);
  const [bnapahpanHark, setBnapahpanHark] = useState(0);

  const findCityById = (id) => localPlace?.find((v) => v?._id === id);

  useEffect(() => {
    setTimeout(() => {
      dispatch(getExchangeRate());
      dispatch(getAllCalculatorConversion());
      dispatch(getCopart());
      dispatch(getLocalPlace());
       dispatch(getSettings());
    }, 1);
  }, []);

  const optionsYear = () => {
    const currentYear = new Date().getFullYear();
    let arr = [
      {
        name:
          translate("forward_web_status_choose") +
          " " +
          translate("forward_web_calculator_car_age"),
        value: null,
      },
    ];
    for (let i = 0; i <= 6; i++) {
      arr.push({
        name: `${currentYear - i}`,
        value: currentYear - i,
      });
    }
    return arr;
  };

  const optionsMonth = [
    {
      name:
        translate("forward_web_status_choose") +
        " " +
        translate(
          "forward_web_home_about_title_section_name_map_import_cars_history_month"
        ),
      value: null,
    },
    { name: translate("forward_web_status_month_list_january"), value: 0 },
    { name: translate("forward_web_status_month_list_february"), value: 1 },
    { name: translate("forward_web_status_month_list_march"), value: 2 },
    { name: translate("forward_web_status_month_list_april"), value: 3 },
    { name: translate("forward_web_status_month_list_may"), value: 4 },
    { name: translate("forward_web_status_month_list_june"), value: 5 },
    { name: translate("forward_web_status_month_list_july"), value: 6 },
    { name: translate("forward_web_status_month_list_august"), value: 7 },
    { name: translate("forward_web_status_month_list_september"), value: 8 },
    { name: translate("forward_web_status_month_list_october"), value: 9 },
    { name: translate("forward_web_status_month_list_november"), value: 10 },
    { name: translate("forward_web_status_month_list_december"), value: 11 },
  ];

  const optionsFuel = [
    {
      name:
        translate("forward_web_status_choose") +
        " " +
        translate("forward_web_calculator_car_fuel_type"),
      value: null,
    },
    {
      name: translate("forward_web_calculator_selected_car_fuel_gasoline"),
      value: "gasoline",
    },
    {
      name: translate("forward_web_calculator_selected_car_fuel_hybrid"),
      value: "hybrid",
    },
    {
      name: translate("forward_web_calculator_selected_car_fuel_electric"),
      value: "electric",
    },
    {
      name: translate("forward_web_calculator_selected_car_fuel_diesel"),
      value: "diesel",
    },
  ];

  const optionsCarType = [
    {
      name:
        translate("forward_web_status_choose") +
        " " +
        translate("forward_web_calculator_car_vehicle_type"),
      value: null,
    },
    {
      name: translate("forward_web_calculator_selected_car_type_sedan"),
      value: "sedan",
    },
    {
      name: translate("forward_web_calculator_selected_car_type_suv"),
      value: "suv",
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      fetch("https://cb.am/latest.json.php")
        .then((response) => {
          return response.json();
        })
        .then((v) => {
          if (v) {
            setCurrency(v);
            // console.log("updated from CB.");
          } else {
            dispatch(getExchangeRate());
          }
        })
        .catch((error) => {
          if (error) {
            dispatch(getExchangeRate());
          }
        });

      dispatch(getAllCalculatorConversion());
    }, 100);

    setTimeout(() => {
      dispatch(getAllPagesContent());
    }, 10);
  }, [dispatch]);

  useEffect(() => {
    if (currency?.updated) {
      // console.log(moment(currency?.updated).format("MMMM Do YYYY, h:mm:ss a"));
      // console.log(moment(currency?.updated).startOf("hour").fromNow());
    }
  }, [currency?.updated]);

  useEffect(() => {
    makeCopartList();
    makeIaaiList();
  }, [copartList, iaaiList]);

  const makeCopartList = useCallback(() => {
    let tempList = [];
    copartList?.length > 0 &&
      copartList?.map((item) => {
        tempList.push({
          name: item?.name.toUpperCase(),
          value:  item?._id,
        });
        return {
          name: item?.name.toUpperCase(),
          value: item?._id,
        };
      });
    tempList.sort((a, b) => a?.name?.localeCompare(b?.name));
    tempList.unshift({ name: "-- Choose Copart Location -- ", value: null });
    return tempList;
  }, [copartList, localPlace]);

  const makeIaaiList = useCallback(() => {
    let tempList = [];
    iaaiList?.length > 0 &&
      iaaiList?.map((item) => {
        tempList.push({
          name: item?.name.toUpperCase(),
          value: item?._id,
        });
        return {
          name: item?.name.toUpperCase(),
          value: item?._id,
        };
      });
    tempList.sort((a, b) => a?.name?.localeCompare(b?.name));
    tempList.unshift({ name: "-- Choose IAAI Location -- ", value: null });
    return tempList;
  }, [iaaiList, localPlace]);

  const options = () => {
    if (auction === "copart") {
      return makeCopartList();
    } else if (auction === "iaai") {
      return makeIaaiList();
    } else if (auction === "other") {
      return [{ name: "Other", value: "" }];
    } else {
      return [
        {
          name:
            translate("forward_web_status_choose") +
            " " +
            translate("forward_web_calculator_car_select_location"),
          value: "",
        },
      ];
    }
  };

  useEffect(() => {
    setCurrency(exchangeRate?.exchangeRates);
  }, [exchangeRate?.exchangeRates]);

  const onAuctionChoose = (v) => {
    setAuctionFee(0);
    setAuction(v);
    setCarShippingFee("");
    if ((v === "copart" || v === "iaai") && carPrice) {
      setAuctionFee(achurdiMijnordavchar(carPrice, v));
    }
  };

  const onCarPriceChange = (v) => {
    setCarPrice(v.target.value);
    if (auction === "copart" || auction === "iaai") {
      setAuctionFee(achurdiMijnordavchar(v.target.value, auction));
    }
  };

  const onLocationChange = (v) => {
    if (auction === "iaai"){
      const temp = iaaiList?.find((element) => element?._id === v)
      const localPricex = findCityById(temp?.localPlaceId)?.price || 0;
      setSelectedId(v)
      setCarShippingFee(Number(temp?.price) + Number(localPricex));
    }else if(auction === "copart"){
      const temp = copartList?.find((element) => element?._id === v)
      const localPricex = findCityById(temp?.localPlaceId)?.price || 0;
      setSelectedId(v)
      setCarShippingFee(Number(temp?.price) + Number(localPricex));
    }
  };

  const onChangeVehicleType = (v) => {
    setVehicleType(v);
  };

  const onChangeYear = (v) => {
    setYear(v);
    if (v && month) {
      const ageDifference = getAgeDifference(v, month);
      setVehicleAge(ageDifference);
    }
  };

  const onChangeMonth = (v) => {
    setMonth(v);
    if (v && year) {
      const ageDifference = getAgeDifference(year, v);
      setVehicleAge(ageDifference);
    }
  };

  const onChangeEngine = (v) => {
    const max = "1234";
    const maxLength = max.toString().length;
    const newVal =
      v.length < max.length
        ? v
        : parseInt(v.toString().substring(0, maxLength));
    setEngine(newVal);
  };

  const onChangeFuelType = (v) => {
    if (v === "electric") {
      setEngine();
    }
    setFuelType(v);
  };

  const onSubmit = () => {
    if (vehicleType && vehicleAge) {
      let maxayinRate = 0;
      const euroToUsd = Number(
        (
          Number(exchangeRate.exchangeRates.EUR) /
          Number(exchangeRate.exchangeRates.USD)
        ).toFixed(2)
      );
      if (fuelType === "gasoline" && engine) {
        maxayinRate = vehicleGazolineType(
          Number(engine),
          vehicleAge,
          vehicleType
        );
      } else if (fuelType === "hybrid" && engine) {
        maxayinRate = vehicleHybridType();
      } else if (fuelType === "diesel" && engine) {
        maxayinRate = vehicleDieselType(
          Number(engine),
          vehicleAge,
          vehicleType
        );
      } else if (fuelType === "electric") {
        maxayinRate = vehicleElectricType();
      }
      setMaqsayinRate(maxayinRate);
      const tempMaqsayin = maxaturq(
        carPrice,
        auctionFee,
        carShippingFee,
        maxayinRate,
        engine,
        euroToUsd
      );
      setMaqsayin(tempMaqsayin);
      const tempAAH = AAH(carPrice, auctionFee, carShippingFee, tempMaqsayin);
      setAah(tempAAH);
      const tempBnapahpanakan = bnapahpanakanHark(
        carPrice,
        auctionFee,
        carShippingFee,
        vehicleAge
      );
      setBnapahpanHark(tempBnapahpanakan);
      const tempTotalAmount =
        Number(carPrice) +
        Number(auctionFee) +
        Number(carShippingFee) +
        Number(tempMaqsayin) +
        Number(tempAAH) +
        Number(tempBnapahpanakan);
      const AMDTotal = currency.USD * tempTotalAmount;
      const RUBTotlal = currency.RUB * AMDTotal;
      setExchanges({
        USD: tempTotalAmount.toFixed(2),
        AMD: AMDTotal.toFixed(2),
        RUB: RUBTotlal.toFixed(2),
      });
    }
  };

  return (
    <>
      {isLoading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
          onClick={() => {}}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <div className="bodyCalculator">
          <div className="calculatorContainerTitle">
            <h1>{translate("forward_web_home_calculator_title")}</h1>
            <p>{f[0]?.description[lang?.lang]}</p>
            <p>
              1$ ={" "}
              {currency?.USD
                ? Math.round(currency?.USD) + "֏"
                : "loading exchange rate"}
            </p>
            <p>
              1€ ={" "}
              {currency?.EUR
                ? Math.round(currency?.EUR) + "֏"
                : "loading exchange rate"}
            </p>
            <p>
              1₽ ={" "}
              {currency?.RUB
                ? Math.round(currency?.RUB) + "֏"
                : "loading exchange rate"}
            </p>
          </div>
          <div className="calcLogic">
            <div className="calcContainer">
              <Input
                title={translate("forward_web_calculator_car_price")}
                placeholder={translate("forward_web_calculator_car_price")}
                type="number"
                valure={carPrice}
                onChange={onCarPriceChange}
              />

                  <div className="selectAuctions">
                <p>{translate("forward_web_calculator_car_auctions")}</p>
                <div className="selectAuctionsContainer">
                  {
                     filteredDataSettings[0]?.configs?.iaai ?
                      <div
                        onClick={() => onAuctionChoose("iaai")}
                        className={
                          auction === "iaai" ? "selectedDiv" : "notSelected"
                          }
                        >
                        <img src={iaai} alt="" />
                      </div> : null
                  }
                  {
                     filteredDataSettings[0]?.configs?.copart ?
                        <div
                        onClick={() => onAuctionChoose("copart")}
                        className={
                          auction === "copart" ? "selectedDiv" : "notSelected"
                        }
                         >
                          <img src={copart} alt="" />
                        </div>
                      : null
                  }
                  <div
                    onClick={() => onAuctionChoose("other")}
                    className={
                      auction === "other" ? "selectedDiv" : "notSelected"
                    }
                  >
                    <p>{translate("forward_web_calculator_car_other")}</p>
                  </div>
                  {auction === "other" ? (
                    <Input
                      placeholder="Other Price"
                      type="number"
                      valure={auctionFee}
                      onChange={(v) => setCarShippingFee(v.target.value)}
                      style={{
                        width: "100%",
                        padding: "4px",
                      }}
                    />
                  ) : null}
                </div>
              </div>

              <Selector
                data={options()}
                title={translate("forward_web_calculator_car_select_location")}
                onChange={(v) => onLocationChange(v.target.value)}
                selected={selectedId}
                optionStyle={{
                  backgroundColor: "transparent",
                  color: variables.primaryBlack,
                }}
              />
              <Input
                title={translate("forward_web_calculator_car_shipping")}
                placeholder={translate("forward_web_calculator_car_shipping")}
                value={carShippingFee}
                // disabled={filteredDataSettings[0]?.configs?.copart &&  filteredDataSettings[0]?.configs?.iaai ? true : false}
                disabled={true}
              />
              <Selector
                data={optionsFuel}
                title={translate("forward_web_calculator_car_fuel_type")}
                onChange={(v) => onChangeFuelType(v.target.value)}
                optionStyle={{
                  backgroundColor: "transparent",
                  color: variables.primaryBlack,
                }}
              />
              <Selector
                data={optionsYear()}
                title={translate("forward_web_calculator_car_age")}
                onChange={(v) => onChangeYear(v.target.value)}
                optionStyle={{
                  backgroundColor: "transparent",
                  color: variables.primaryBlack,
                }}
              />
              <Selector
                data={optionsMonth}
                title={translate(
                  "forward_web_home_about_title_section_name_map_import_cars_history_month"
                )}
                onChange={(v) => onChangeMonth(v.target.value)}
                optionStyle={{
                  backgroundColor: "transparent",
                  color: variables.primaryBlack,
                }}
              />
              <Input
                title={translate("forward_web_calculator_car_engine")}
                placeholder={translate("forward_web_calculator_car_engine")}
                type="number"
                value={fuelType !== "electric" ? engine : undefined}
                onChange={(v) => onChangeEngine(v.target.value)}
                disabled={fuelType === "electric"}
              />
              <Selector
                data={optionsCarType}
                title={translate("forward_web_calculator_car_vehicle_type")}
                onChange={(v) => onChangeVehicleType(v.target.value)}
                optionStyle={{
                  backgroundColor: "transparent",
                  color: variables.primaryBlack,
                }}
              />
              <br />
              <br />
              <Button
                name={translate("forward_web_calculator_car_calculate")}
                onClick={onSubmit}
                style={{
                  backgroundColor: variables.dustRed,
                }}
              />
            </div>
            <div className="space" />

            <div className="resultContainer">
              <p>{translate("forward_web_calculator_car_total_price")}</p>
              <div className="priceContainer">
                <p>{removeDecimalPartsOfNumbers(exchanges.AMD) || 0} ֏</p>
                <p>{removeDecimalPartsOfNumbers(exchanges.USD) || 0} $</p>
                <p>{removeDecimalPartsOfNumbers(exchanges.RUB) || 0} ₽</p>
              </div>
              <div className="resultBox">
                <div className="result">
                  <div>
                    <div>
                      <ColoredCircle color={variables.dustRed} />
                      <p> {translate("forward_web_calculator_car_price")}</p>
                    </div>
                    <p>{carPrice ? `$${carPrice}` : 0}</p>
                  </div>
                  <div>
                    <div>
                      <ColoredCircle color={variables.dustRed} />
                      <p>
                        {" "}
                        {translate("forward_web_calculator_car_auctions_fee")}
                      </p>
                    </div>
                    <p>{auctionFee ? `$${auctionFee}` : 0}</p>
                  </div>
                  <div>
                    <div>
                      <ColoredCircle color={variables.sunsetOrange} />
                      <p> {translate("forward_web_calculator_car_shipping")}</p>
                    </div>
                    <p>{carShippingFee ? `$${carShippingFee}` : 0}</p>
                  </div>
                </div>
                <div className="tax">
                  <div>
                    <div>
                      <ColoredCircle color={variables.sunriseYellow} />
                      <p>
                        {" "}
                        {translate("forward_web_calculator_car_custom_duty")}
                      </p>
                    </div>
                    <p>{maqsayin ? `$${maqsayin}` : 0}</p>
                  </div>
                  <div>
                    <div>
                      <ColoredCircle color={variables.sunriseYellow} />
                      <p> {translate("forward_web_calculator_car_vat")}</p>
                    </div>
                    <p>{aah ? `$${aah}` : 0}</p>
                  </div>
                  <div>
                    <div>
                      <ColoredCircle color={variables.sunriseYellow} />
                      <p> {translate("forward_web_calculator_car_eco_tax")}</p>
                    </div>
                    <p>{bnapahpanHark ? `$${bnapahpanHark}` : 0}</p>
                  </div>
                </div>
              </div>
              <div className="buttonContainer">
                <Button
                  name={translate("forward_web_calculator_car_button_donwload")}
                  onClick={() => {
                    makePDF(
                      forPDF(
                        auction,
                        carPrice,
                        auctionFee,
                        carShippingFee,
                        maqsayin,
                        aah,
                        bnapahpanHark,
                        removeDecimalPartsOfNumbers(exchanges.AMD),
                        removeDecimalPartsOfNumbers(exchanges.USD),
                        removeDecimalPartsOfNumbers(exchanges.RUB)
                      )
                    );
                  }}
                  style={{ backgroundColor: variables.dustRed, width: "44%" }}
                />
                <Button
                  name={translate("forward_web_users_delete_button")}
                  onClick={() => {
                    history.push("/calculator");
                    history.go(0);
                  }}
                  style={{
                    backgroundColor: "black",
                    border: 1,
                    borderColor: "white",
                    width: "44%",
                  }}
                />
              </div>
              <div className="infoContainer">
                <div className="infoBody">
                  <ColoredCircle color={variables.dustRed} />
                  <p>
                    {translate("forward_web_calculator_car_buying_one_day")}
                  </p>
                </div>
                <div className="infoBody">
                  <ColoredCircle color={variables.sunsetOrange} />
                  <p>
                    {translate(
                      "forward_web_calculator_car_buying_forty_five_day"
                    )}
                  </p>
                </div>
                <div className="infoBody">
                  <ColoredCircle color={variables.sunriseYellow} />
                  <p>
                    {translate("forward_web_calculator_car_buying_three_day")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default React.memo(Calculator);
