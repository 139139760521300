import React, { useContext, useEffect } from 'react'

import './styles.scss'
import map from '../../assets/worldMap.png'
import { useDispatch, useSelector } from 'react-redux'
import { getAllPagesContent } from '../../redux/auth/actions'
import { translate } from '../../utils/helpers'
import { LanguageContext } from '../../utils/languageContext'
import { BASE_URL } from '../../utils/http'

const About = () => {
  const dispatch = useDispatch();
  const lang = useContext(LanguageContext);
  const pagesContentList = useSelector((state) => state.Auth.pagesContent);
  const f = pagesContentList?.length ? pagesContentList?.filter((v) => v?.pageName === 'About') : []

  useEffect(() => {
      setTimeout(() => {
          dispatch(getAllPagesContent())
        }, 10);
    }, [dispatch])

  return (
    <div className="bodyAbout">
     <div className='aboutMapContainer'>
        <div className='titleContainer'>
                <h1>{f[0]?.title1[lang?.lang]}</h1>
                <p>{f[0]?.description1[lang?.lang]}</p>
                <br/>
                <h1>{f[0]?.title2[lang?.lang]}</h1>
                <p>{f[0]?.description2[lang?.lang]}</p>
        </div>
        <div className='aboutContainer'>
            <img src={map} className="titleMap" alt='car'/>
            <div className='infoCar'>
             <div className='controllerInfo'>
                {/* <div className='infoMapPrice'>
                    <p>{translate('forward_web_home_about_title_section_name_map_import_cars')}</p>
                    <p>{translate('forward_web_home_about_title_section_name_map_import_cars_history_copart')}</p>
                </div>
                <div className='infoMapPrice'>
                    <p>{translate('forward_web_home_about_title_section_name_map_import_duration')}</p>
                    <p>0-2 {translate('forward_web_home_about_title_section_name_map_import_cars_history_month')}</p>
                </div>
                <div className='infoMapPrice'>
                    <p>{translate('forward_web_home_about_title_section_name_map_import_cars_history')}</p>
                    <p>{translate('forward_web_home_about_title_section_name_map_import_cars_history_yes')}</p>
                </div> */}
             </div>
            </div>
        </div>
     </div>
     <div className='ourTeam'>
        <h1>{translate('forward_web_home_about_title_our_team_section_name')}</h1>
        <div className='teamImageContainer'>
            <img className='teamImage' src={`${BASE_URL}/api/uploads/`+f[0]?.icon?.filename} alt='' />
        </div>
     </div>
    </div>
  )
}
export default React.memo(About)
