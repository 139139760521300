import * as actions from './constants'

export const login = (values) => ({
  type: actions.AUTH_LOGIN_REQUEST,
  values
})

export const logout = () => ({
  type: actions.AUTH_LOGOUT
})

export const getAllServices = () => ({
  type: actions.AUTH_GET_ALL_SERVICES_REQUEST
})

export const getAllCalculatorConversion = () => ({
  type: actions.AUTH_GET_ALL_CALC_CONVERSION_REQUEST
})

export const getAllAuctionsCar = () => ({
  type: actions.AUTH_GET_ALL_AUCTIONS_CARS_REQUEST
})

export const getAllPagesContent = () => ({
  type: actions.AUTH_GET_ALL_PAGES_CONTENT_REQUEST
})

export const getExchangeRate = () => ({
  type: actions.AUTH_GET_EXCHANGE_RATE_REQUEST
})

export const getLocalPlace = () => ({
  type: actions.AUTH_GET_LOCAL_PLACE_REQUEST
})

export const getCopart = () => ({
  type: actions.AUTH_GET_COPART_REQUEST
})

export const getSettings = () => ({
  type: actions.AUTH_GET_SETTINGS_REQUEST
})

export const setUser = (user, token) => ({
  type: actions.AUTH_USER,
  user,
  token
})
