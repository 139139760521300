import React from "react";
import "./styles.scss";
import x6 from '../../assets/bmwx6.png'

const CarCard = ({title, icon, subTitle, currentPrice, endingTime, currentPriceTitle, endingTimeTitle}) => {
  return (
    <div className="carHeaderPart">
      <div className="headerTitle">
        <p className="title">{title}</p>
        { subTitle ? <p className="subtitle">{subTitle}</p> : null }
      </div>
     <div className="iconContainer">
        {
          icon ? <img src={icon} alt="x" className="icon" /> :  <img src={x6} alt="x" className="icon" />
        }
     </div>
     <div className="infoContainer">
        <div>
            {
              currentPrice ?
             <>
              <p>{currentPriceTitle}</p>
              <p>{currentPrice}</p>
             </> : null
            }
        </div>
         <div>
           {
            endingTime ?
            <>
             <p>{endingTimeTitle}</p>
             <p>{endingTime}</p>
            </> : null
           }
        </div>
     </div>
    </div>
  );
};

export default React.memo(CarCard);