import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddFormModal, antModalDelete } from '../../components/antModal';
import AntTable from '../../components/antTable';
import Button from '../../components/button';
import { addAuctionsCars, deleteAuctionsCars, editAuctionsCars } from '../../redux/app/actions';
import { getAllAuctionsCar } from '../../redux/auth/actions'
import { translate } from '../../utils/helpers';
import Input from '../../components/input';

const AuctionCar = ({ isOpenModal, onCloseModal }) => {
    const dispatch = useDispatch();
    const getAuctionsCarsList = useSelector((state) => state.Auth.auctionsCarsList);
    const [laodingCall, setLoadingCall] = useState(false);
    const [onOpenEditModal, setOnOpenEditModal] = useState(false);
    const [uploadImageAdd, setuploadImageAdd] = useState({})
    const [uploadImageEdit, setuploadImageEdit] = useState({})
    const [valuesEdit, setValuesEdit] = useState({
        name: '',
        description: '',
        currentPrice: '',
        endingIn: '',
        image:  uploadImageEdit?.target?.files[0]
    })
    const [values, setValues] = useState({
        title: '',
        description: '',
        currentPrice: '',
        endingIn: '',
        image:  uploadImageAdd?.target?.files[0]
    })

    useEffect(() => {
        setValues({...values, image:  uploadImageAdd?.target?.files[0] })
        setValuesEdit({...valuesEdit, image:  uploadImageEdit?.target?.files[0] })
    }, [uploadImageAdd, uploadImageEdit])

    useEffect(() => {
        setTimeout(() => {
            dispatch(getAllAuctionsCar())
            setLoadingCall(false)
        }, 10);
    }, [dispatch, laodingCall])

    const dataForCalc = getAuctionsCarsList?.length ? getAuctionsCarsList?.map((v) => ({
        key: v?._id,
        id: v?._id,
        title: v?.title,
        description: v?.description,
        currentPrice: v?.currentPrice,
        endingIn: v?.endingIn,
        image: v?.image?.filename
    })) : []

    const columnsCalc = [
        {
            dataIndex: 'title',
            title: translate('forward_web_info_home_auctions_cars_editor_name'),
        },
        {
            dataIndex: 'image',
            title: 'Image',
        },
        {
            dataIndex: 'description',
            title: translate('forward_web_info_content_editor_services_description'),
        },
        {
            dataIndex: 'currentPrice',
            title: translate('forward_web_info_home_auctions_current_price'),
        },
        {
            dataIndex: 'endingIn',
            title: translate('forward_web_info_home_auctions_ending_in'),
        }
    ];

    const onConfirmDelete = (e) => {
        const x = { carsId: e?.id }
        dispatch(deleteAuctionsCars(x))
        setLoadingCall(true)
    }

    const onAddNewCars = () => {
        if(!values?.title || !values?.description){
            alert(translate('forward_web_please_fill_all_fields'))
        }else{
            dispatch(addAuctionsCars(values))
            setLoadingCall(true)
            onCloseModal(false)
        }
    }

    const onOpenEdit = (e) => {
        setOnOpenEditModal(true)
        setValuesEdit({
                    id: e?.id,
                    title: e?.title,
                    description: e?.description,
                    currentPrice: e?.currentPrice,
                    endingIn :e?.endingIn
        })
    }

    const onConfirmEditServices = () => {
         if(!valuesEdit?.title || !valuesEdit?.description){
            alert(translate('forward_web_please_fill_all_fields'))
        }else{
            dispatch(editAuctionsCars(valuesEdit))
            setLoadingCall(true)
            setOnOpenEditModal(false)
        }
    }

return (
    <>
        <AddFormModal isModalOpen={onOpenEditModal}
             setIsModalOpen={(e) => setOnOpenEditModal(e)}
             title={translate('forward_web_users_edit_button')}>
            <div className='formAdd'>
                <Input title={translate('forward_web_info_home_auctions_cars_editor_name')} onChange={(v) =>  setValuesEdit({ ...valuesEdit, title:  v.target.value })} value={valuesEdit?.title} style={stylesInput}  />
                <Input title={translate('forward_web_info_content_editor_services_description')} onChange={(v) =>  setValuesEdit({ ...valuesEdit, description:  v.target.value })} value={valuesEdit?.description} style={stylesInput}  />
                <Input title={translate('forward_web_info_home_auctions_current_price')} onChange={(v) =>  setValuesEdit({ ...valuesEdit, currentPrice:  v.target.value })} value={valuesEdit?.currentPrice} style={stylesInput}  />
                <Input title={translate('forward_web_info_home_auctions_ending_in')} onChange={(v) =>  setValuesEdit({ ...valuesEdit, endingIn:  v.target.value })} value={valuesEdit?.endingIn} style={stylesInput}  />
                <Input title={translate('forward_web_info_home_auctions_cars_editor_image')} onChange={(v) =>  setuploadImageEdit(v)} style={stylesInput} type='file'  />
                <br/>
                <Button name={translate('forward_web_info_confirm_submit')} onClick={() => onConfirmEditServices()} style={{ backgroundColor: 'green', color: 'white'  }} />
            </div>
        </AddFormModal>
        <AddFormModal isModalOpen={isOpenModal}
             setIsModalOpen={(e) => onCloseModal(e)}
             title={translate('forward_web_users_add_button')}>
            <div className='formAdd'>
                <Input title={translate('forward_web_info_home_auctions_cars_editor_name') + ': ( Bmw x6 2022 )'} onChange={(v) =>  setValues({ ...values, title: v.target.value })} value={values?.title} style={stylesInput}  />
                <Input title={translate('forward_web_info_content_editor_services_description')} onChange={(v) =>  setValues({ ...values, description: v.target.value })} value={values?.description} style={stylesInput}  />
                <Input title={translate('forward_web_info_home_auctions_current_price')} onChange={(v) =>  setValues({ ...values, currentPrice:  Number(v.target.value) })} value={values?.currentPrice} style={stylesInput} type='number'  />
                <Input title={translate('forward_web_info_home_auctions_ending_in')} onChange={(v) =>  setValues({ ...values, endingIn:  v.target.value })} value={values?.endingIn} style={stylesInput}  />
                <Input title={translate('forward_web_info_home_auctions_cars_editor_image')} onChange={(v) => setuploadImageAdd(v)} style={stylesInput} type='file'  />
                <br/>
                <Button name={translate('forward_web_info_confirm_submit')} onClick={() => onAddNewCars()} style={{ backgroundColor: 'green', color: 'white'  }} />
            </div>
        </AddFormModal>
        <AntTable
            data={dataForCalc.reverse()}
            column={columnsCalc}
            onEdit={(e) => onOpenEdit(e)}
            onDelete={(e) => antModalDelete(e?.id, () => onConfirmDelete(e))}
            expandableFormKeys={[
                {key: 'id', name: 'Id'},
            ]}
        />
    </>
  )
}
const stylesInput = { color: 'black' };

export default React.memo(AuctionCar)
