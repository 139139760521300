import {
  all, takeLatest, put, call
} from 'redux-saga/effects'

import {
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_ERROR,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT,
  AUTH_GET_ALL_SERVICES_SUCCESS,
  AUTH_GET_ALL_SERVICES_ERROR,
  AUTH_GET_ALL_SERVICES_REQUEST,
  AUTH_GET_ALL_CALC_CONVERSION_SUCCESS,
  AUTH_GET_ALL_CALC_CONVERSION_ERROR,
  AUTH_GET_ALL_CALC_CONVERSION_REQUEST,
  AUTH_GET_ALL_AUCTIONS_CARS_SUCCESS,
  AUTH_GET_ALL_AUCTIONS_CARS_ERROR,
  AUTH_GET_ALL_AUCTIONS_CARS_REQUEST,
  AUTH_GET_ALL_PAGES_CONTENT_ERROR,
  AUTH_GET_ALL_PAGES_CONTENT_SUCCESS,
  AUTH_GET_ALL_PAGES_CONTENT_REQUEST,
  AUTH_GET_EXCHANGE_RATE_REQUEST,
  AUTH_GET_EXCHANGE_RATE_SUCCESS,
  AUTH_GET_EXCHANGE_RATE_ERROR,
  AUTH_GET_LOCAL_PLACE_SUCCESS,
  AUTH_GET_LOCAL_PLACE_ERROR,
  AUTH_GET_LOCAL_PLACE_REQUEST,
  AUTH_GET_COPART_SUCCESS,
  AUTH_GET_COPART_ERROR,
  AUTH_GET_COPART_REQUEST,
  AUTH_GET_SETTINGS_SUCCESS,
  AUTH_GET_SETTINGS_ERROR,
  AUTH_GET_SETTINGS_REQUEST,
} from './constants'
import { request, addTokenToHttp } from '../../utils/http'
import StorageUtils from '../../utils/storage'
import { APP_CLEAR_STATE } from '../app/constants'


function sendLogin ({ values }) {
  return request.post('/api/user/login/', values)
}

function * handleLogin ({ values }) {
  try {
    const { status, data } = yield call(sendLogin, { values })
    if (status === 200) {
      yield put({
        type: AUTH_LOGIN_SUCCESS,
        accessToken: data.token
      })

      StorageUtils.setAccessToken(data.token)
      addTokenToHttp(data.token)
      // yield put(getProfile())
    } else {
      yield put({
        type: AUTH_LOGIN_ERROR,
        error: 'Unknown Error'
      })
    }
  } catch (error) {
    yield put({
      type: AUTH_LOGIN_ERROR,
      error: error.response.data
    })
    // console.log('Error Login', error.response.data)
  }
}

function getAllServices () {
  return request.get('/api/service/')
}

function * handleGetAllServices () {
  try {
    const { status, data } = yield call(getAllServices)
    if (status === 200) {
      yield put({
        type: AUTH_GET_ALL_SERVICES_SUCCESS,
        payload: data?.services
      })
    } else {
      yield put({
        type: AUTH_GET_ALL_SERVICES_ERROR,
        error: 'Unknown Error'
      })
    }
  } catch (error) {
    yield put({
      type: AUTH_GET_ALL_SERVICES_ERROR,
      error: error.response.data
    })
  }
}

function getAllCalculatorConversion () {
  return request.get('/api/iaai')
}

function * handleGetAllCalculatorServices () {
  try {
    const { status, data } = yield call(getAllCalculatorConversion)
    if (status === 200) {
      yield put({
        type: AUTH_GET_ALL_CALC_CONVERSION_SUCCESS,
        payload: data?.iaai
      })
    } else {
      yield put({
        type: AUTH_GET_ALL_CALC_CONVERSION_ERROR,
        error: 'Unknown Error'
      })
    }
  } catch (error) {
    yield put({
      type: AUTH_GET_ALL_CALC_CONVERSION_ERROR,
      error: error.response.data
    })
  }
}

function getAllAuctionsCars () {
  return request.get('/api/auctions/cars/')
}

function * handleGetAllAuctionsCars () {
  try {
    const { status, data } = yield call(getAllAuctionsCars)
    if (status === 200) {
      yield put({
        type: AUTH_GET_ALL_AUCTIONS_CARS_SUCCESS,
        payload: data?.auctionCars
      })
    } else {
      yield put({
        type: AUTH_GET_ALL_AUCTIONS_CARS_ERROR,
        error: 'Unknown Error'
      })
    }
  } catch (error) {
    yield put({
      type: AUTH_GET_ALL_AUCTIONS_CARS_ERROR,
      error: error.response.data
    })
  }
}

function getAllPagesContent () {
  return request.get('/api/pageTexts/')
}

function * handleGetAllPagesContent () {
  try {
    const { status, data } = yield call(getAllPagesContent)
    if (status === 200) {
      yield put({
        type:  AUTH_GET_ALL_PAGES_CONTENT_SUCCESS,
        payload: data?.pages
      })
    } else {
      yield put({
        type:  AUTH_GET_ALL_PAGES_CONTENT_ERROR,
        error: 'Unknown Error'
      })
    }
  } catch (error) {
    yield put({
      type:  AUTH_GET_ALL_PAGES_CONTENT_ERROR,
      error: error.response.data
    })
  }
}

function getExchangeRate () {
  return request.get('/api/exchangeRate')
}

function * handleGetExchangeRate () {
  try {
    const { status, data } = yield call(getExchangeRate)
    if (status === 200) {
      yield put({
        type:  AUTH_GET_EXCHANGE_RATE_SUCCESS,
        payload: data
      })
    } else {
      yield put({
        type:  AUTH_GET_EXCHANGE_RATE_ERROR,
        error: 'Unknown Error'
      })
    }
  } catch (error) {
    yield put({
      type:  AUTH_GET_EXCHANGE_RATE_ERROR,
      error: error.response.data
    })
    // console.log('Error Login', error.response.data)
  }
}

function getLocalPlace () {
  return request.get('/api/calculator/localplace')
}

function * handleGetLocalPlace () {
  try {
    const { status, data } = yield call(getLocalPlace)
    if (status === 200) {
      yield put({
        type:  AUTH_GET_LOCAL_PLACE_SUCCESS,
        payload: data
      })
    } else {
      yield put({
        type:  AUTH_GET_LOCAL_PLACE_ERROR,
        error: 'Unknown Error'
      })
    }
  } catch (error) {
    yield put({
      type:  AUTH_GET_LOCAL_PLACE_ERROR,
      error: error.response.data
    })
  }
}

function getCopart () {
  return request.get('/api/copart')
}

function * handleGetCopart () {
  try {
    const { status, data } = yield call(getCopart)
    if (status === 200) {
      yield put({
        type:  AUTH_GET_COPART_SUCCESS,
        payload: data?.conversions
      })
    } else {
      yield put({
        type:  AUTH_GET_COPART_ERROR,
        error: 'Unknown Error'
      })
    }
  } catch (error) {
    yield put({
      type:  AUTH_GET_COPART_ERROR,
      error: error.response.data
    })
  }
}

function getSettings () {
  return request.get('/api/web/settings/')
}

function * handleGetSettings () {
  try {
    const { status, data } = yield call(getSettings)
    if (status === 200) {
      yield put({
        type:  AUTH_GET_SETTINGS_SUCCESS,
        payload: data?.settings
      })
    } else {
      yield put({
        type:  AUTH_GET_SETTINGS_ERROR,
        error: 'Unknown Error'
      })
    }
  } catch (error) {
    yield put({
      type:  AUTH_GET_SETTINGS_ERROR,
      error: error.response.data
    })
  }
}


function * handleLogout () {
  try {
    StorageUtils.removeAccessToken()
    StorageUtils.removeUser()
    addTokenToHttp('')
    yield put({
      type: APP_CLEAR_STATE
    })
  } catch (error) {
    console.log('error :>> ', error)
  }
}

export default all([
  takeLatest(AUTH_LOGIN_REQUEST, handleLogin),
  takeLatest(AUTH_GET_ALL_SERVICES_REQUEST, handleGetAllServices),
  takeLatest(AUTH_GET_ALL_CALC_CONVERSION_REQUEST, handleGetAllCalculatorServices),
  takeLatest(AUTH_GET_ALL_AUCTIONS_CARS_REQUEST, handleGetAllAuctionsCars),
  takeLatest(AUTH_GET_ALL_PAGES_CONTENT_REQUEST, handleGetAllPagesContent),
  takeLatest(AUTH_GET_EXCHANGE_RATE_REQUEST, handleGetExchangeRate),
  takeLatest(AUTH_GET_LOCAL_PLACE_REQUEST, handleGetLocalPlace),
  takeLatest(AUTH_GET_COPART_REQUEST, handleGetCopart),
  takeLatest(AUTH_GET_SETTINGS_REQUEST, handleGetSettings),
  takeLatest(AUTH_LOGOUT, handleLogout),
])
