/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddFormModal } from '../../components/antModal';
import AntTable from '../../components/antTable';
import Button from '../../components/button';
import { editPagesContent } from '../../redux/app/actions';
import { getAllPagesContent } from '../../redux/auth/actions'
import { translate } from '../../utils/helpers';
import Input from '../../components/input';

const AboutEditor = ({ isOpenModal, onCloseModal }) => {
    const dispatch = useDispatch();
    const pagesContentList = useSelector((state) => state.Auth.pagesContent);
    const [aboutId, setAboutId] = useState('');
    const [laodingCall, setLoadingCall] = useState(false);
    const [onOpenEditModal, setOnOpenEditModal] = useState(false);
    const [valuesEdit, setValuesEdit] = useState({
        titleAM: '',
        titleRU: '',
        titleUS: '',
        descriptionAM: '',
        descriptionRU: '',
        descriptionUS: ''
     })
    const [uploadImageEdit, setuploadImageEdit] = useState({});

    useEffect(() => {
        dispatch(getAllPagesContent())
        setLoadingCall(false)
    }, [dispatch, laodingCall])

    const f = pagesContentList?.length ? pagesContentList?.find((v) => v?.pageName === 'About') : {}

    useEffect(() => {
        if(f?._id){
            setAboutId(f?._id)
        }
    }, [f])

    const data = [
        {
            key: 1,
            titleAM: f?.title1?.am,
            titleRU: f?.title1?.ru,
            titleUS: f?.title1?.us,
            descriptionAM: f?.description1?.am,
            descriptionRU: f?.description1?.ru,
            descriptionUS: f?.description1?.us
        },
        {
            key: 2,
            titleAM: f?.title2?.am,
            titleRU: f?.title2?.ru,
            titleUS: f?.title2?.us,
            descriptionAM: f?.description2?.am,
            descriptionRU: f?.description2?.ru,
            descriptionUS: f?.description2?.us
        },
    ]

    const columnsAbout = [
        {
            dataIndex: 'titleAM',
            title: translate('forward_web_info_content_editor_services_title') + ' AM',
        },
        {
            dataIndex: 'titleRU',
            title: translate('forward_web_info_content_editor_services_title') + ' RU',
        },
        {
            dataIndex: 'titleUS',
            title: translate('forward_web_info_content_editor_services_title') + ' US',
        },
    ];

    const onAddNewConversion = () => {
            const xId = aboutId;
            const x = { icon:  uploadImageEdit?.target?.files[0] }
            if(!x.icon || !xId ){
                alert('ops not valid')
            }else{
                dispatch(editPagesContent(x, xId))
                setLoadingCall(true)
                onCloseModal(false)
            }
    }

    const onOpenEdit = (e) => {
        setOnOpenEditModal(true)
        setValuesEdit({
            id: e?.key,
            titleAM: e?.titleAM,
            titleRU: e?.titleRU,
            titleUS: e?.titleUS,
            descriptionAM: e?.descriptionAM,
            descriptionRU: e?.descriptionRU,
            descriptionUS: e?.descriptionUS
        })
    }

    const onConfirmEditServices = () => {
         if(!valuesEdit?.id || !valuesEdit?.titleAM || !valuesEdit?.titleRU || !valuesEdit?.titleUS || !valuesEdit?.descriptionAM || !valuesEdit?.descriptionRU || !valuesEdit?.descriptionUS){
            alert(translate('forward_web_please_fill_all_fields'))
        }else{
            const xId = aboutId;
            const x = {
                ['title' + valuesEdit?.id]:{ am: valuesEdit?.titleAM, us: valuesEdit?.titleUS, ru: valuesEdit?.titleRU  },
                ['description' + valuesEdit?.id]:{ am: valuesEdit?.descriptionAM, us: valuesEdit?.descriptionUS, ru: valuesEdit?.descriptionRU  }
            }
            dispatch(editPagesContent(x, xId))
            setLoadingCall(true)
            setOnOpenEditModal(false)
        }
    }

return (
    <>
        <AddFormModal isModalOpen={onOpenEditModal}
             setIsModalOpen={(e) => setOnOpenEditModal(e)}
             title={translate('forward_web_users_edit_button')}>
            <div className='formAdd'>
                <Input title={translate('forward_web_info_content_editor_services_title') + ' AM'} onChange={(v) =>  setValuesEdit({ ...valuesEdit, titleAM:  v.target.value })} value={valuesEdit?.titleAM} style={stylesInput}  />
                <Input title={translate('forward_web_info_content_editor_services_title') + ' RU'} onChange={(v) =>  setValuesEdit({ ...valuesEdit, titleRU:  v.target.value })} value={valuesEdit?.titleRU} style={stylesInput}  />
                <Input title={translate('forward_web_info_content_editor_services_title') + ' US'} onChange={(v) =>  setValuesEdit({ ...valuesEdit, titleUS:  v.target.value })} value={valuesEdit?.titleUS} style={stylesInput}  />

                <Input title={translate('forward_web_info_content_editor_services_description') + ' AM'} onChange={(v) =>  setValuesEdit({ ...valuesEdit, descriptionAM:  v.target.value })} value={valuesEdit?.descriptionAM} style={stylesInput}  />
                <Input title={translate('forward_web_info_content_editor_services_description') + ' RU'} onChange={(v) =>  setValuesEdit({ ...valuesEdit, descriptionRU:  v.target.value })} value={valuesEdit?.descriptionRU} style={stylesInput}  />
                <Input title={translate('forward_web_info_content_editor_services_description') + ' US'} onChange={(v) =>  setValuesEdit({ ...valuesEdit, descriptionUS:  v.target.value })} value={valuesEdit?.descriptionUS} style={stylesInput}  />

                <br/>
                <Button name={translate('forward_web_info_confirm_submit')} onClick={() => onConfirmEditServices()} style={{ backgroundColor: 'green', color: 'white'  }} />
            </div>
        </AddFormModal>
        <AddFormModal isModalOpen={isOpenModal}
             setIsModalOpen={(e) => onCloseModal(e)}
             title={translate('forward_web_users_add_button')}>
            <div className='formAdd'>
                <Input title={translate('forward_web_info_home_auctions_cars_editor_image')} onChange={(v) =>  setuploadImageEdit(v)} style={stylesInput} type='file'  />
                <br/>
                <Button name={translate('forward_web_info_confirm_submit')} onClick={() => onAddNewConversion()} style={{ backgroundColor: 'green', color: 'white'  }} />
            </div>
        </AddFormModal>
        <AntTable
            data={data}
            column={columnsAbout}
            onEdit={(e) => onOpenEdit(e)}
            expandableFormKeys={[
                  { key: 'descriptionAM', name: translate('forward_web_info_content_editor_services_description') + ' AM' },
                  { key: 'descriptionRU', name: translate('forward_web_info_content_editor_services_description') + ' RU' },
                  { key: 'descriptionUS', name: translate('forward_web_info_content_editor_services_description') + ' US' }
            ]}
        />
    </>
  )
}
const stylesInput = { color: 'black' };

export default React.memo(AboutEditor)
