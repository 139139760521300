import React, { useContext, useEffect } from "react";

import "./styles.scss";
import car from "../../assets/bmw.png";
import tesla from "../../assets/tesla.png";
import CarCard from "../../components/carCard";
import Services from "../services";
import { translate } from "../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { getAllAuctionsCar } from "../../redux/auth/actions";
import { LanguageContext } from "../../utils/languageContext";
import { BASE_URL } from "../../utils/http";

const Home = () => {
  const dispatch = useDispatch();
  const lang = useContext(LanguageContext);
  const getAuctionsCarsList = useSelector(
    (state) => state.Auth.auctionsCarsList
  );
  const getPagesList = useSelector((state) => state.Auth.pagesContent);
  const filteredHomeMain = getPagesList?.length
    ? getPagesList?.find((v) => v?.pageName === "HomeMain")
    : null;
  const filteredHomeSecond = getPagesList?.length
    ? getPagesList?.find((v) => v?.pageName === "HomeSecond")
    : null;
  const youtubeLinks = getPagesList?.length
    ? getPagesList?.find((v) => v?.pageName === "youtube")
    : null;

  useEffect(() => {
    setTimeout(() => {
      dispatch(getAllAuctionsCar());
    }, 10);
  }, [dispatch]);

  return (
    <div className="bodyHome">
      <div className="homeContainer">
        <div className="titleContainer">
          <h1>{filteredHomeMain?.title[lang?.lang]}</h1>
          <p>{filteredHomeMain?.description[lang?.lang]}</p>
        </div>
        <div className="carContainer">
          <img
            src={
              filteredHomeMain?.icon?.filename
                ? `${BASE_URL}/api/uploads/${filteredHomeMain?.icon?.filename}`
                : car
            }
            className="titleCar"
            alt=""
          />
          <div className="infoCar">
            {/* <div className="controllerCar">
              <div className="infoCarPrice">
                <p>BMW X3 2022</p>
                <p>$36.000$</p>
              </div>
              <div className="infoCarContainer">
                <div className="infoCarUSA">
                  <p>Price in USA</p>
                  <p>$36.000$</p>
                </div>
                <div className="space" />
                <div className="infoCarAM">
                  <p>Price in Armenia</p>
                  <p>$36.000$</p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="inventoryContainer">
        <div className="titleInventor">
          <h2>{translate("forward_web_home_home_title_name_lots")}</h2>
          {/* <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry`s standard dummy.
          </p> */}
        </div>
        <div className="lotList">
          {getAuctionsCarsList?.length
            ? getAuctionsCarsList?.map((v) => {
                return (
                  <div className="lotListSpace" key={v?._id}>
                    <CarCard
                      icon={
                        `${BASE_URL}/api/uploads/` +
                        v?.image?.filename
                      }
                      title={v?.title}
                      subTitle={v?.description}
                      currentPrice={v?.currentPrice + " $"}
                      endingTime={v?.endingIn}
                      currentPriceTitle={translate(
                        "forward_web_info_home_auctions_current_price"
                      )}
                      endingTimeTitle={translate(
                        "forward_web_info_home_auctions_ending_in"
                      )}
                    />
                  </div>
                );
              })
            : null}
        </div>
      </div>
      <div className="homeContainer">
        <div className="titleContainer">
          <h1>{filteredHomeSecond?.title[lang?.lang]}</h1>
          <p>{filteredHomeSecond?.description[lang?.lang]}</p>
          <h1>{filteredHomeMain?.title[lang?.lang]}</h1>
          <p>{filteredHomeMain?.description[lang?.lang]}</p>
        </div>
        <div className="carContainer">
          <img
            src={
              filteredHomeSecond?.icon?.filename
                ? `${BASE_URL}/api/uploads/${filteredHomeSecond?.icon?.filename}`
                : tesla
            }
            className="titleCar"
            alt=""
          />
          <div className="infoCar">
            {/* <div className="controllerCar">
              <div className="infoCarPrice">
                <p>396Mi</p>
                <p>Range ( EPA est.)</p>
              </div>
              <div className="infoCarPrice">
                <p>1.99s</p>
                <p>0-60 mph*</p>
              </div>
              <div className="infoCarPrice">
                <p>200Mph</p>
                <p>Top Speedd+</p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="videoContainer">
        <h1>
          {translate(
            "forward_web_calculator_car_container_loading_video_title"
          )}
        </h1>
        <div className="videoFrame">
          <iframe
            width="100%"
            height={window.innerHeight / 2}
            src={youtubeLinks?.description[lang?.lang]}
            title="How to Load Cars into Containers"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <Services />
    </div>
  );
};
export default React.memo(Home);
