import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

export const makePDF = (obj) => {
  const doc = new jsPDF();
  const arr = Object.keys(obj);

  const createObjectsArray = (key) => {
    if (key !== "body") {
      const x = arr
        .map((item) => {
          if (key === item && key !== "body") {
            return Object.values(obj[item]);
          }
          return null;
        })
        .filter((el) => el)[0];
      return x;
    } else if (key === "body") {
      const y = arr.map((item) => {
        if (key === item) {
          let temp = Object.entries(obj[item]).map((el) => {
            el.splice(1, 0, " ");
            return el;
          });
          return temp;
        }
        return null;
      });
      return y.filter((el) => el)[0];
    }
  };

  doc.text(createObjectsArray("title"), 15, 20);
  autoTable(doc, {
    head: [createObjectsArray("header")],
    headStyles: {
      fontSize: 14,
      fontStyle: {
        fontWeight: "normal",
      },
      cellPadding: {
        top: 3,
        bottom: 3,
        left: 2,
        right: 2,
      },
    },
    bodyStyles: {
      fontSize: 11,
    },
    body: createObjectsArray("body"),
    foot: [createObjectsArray("footer")],
    footStyles: {
      fontSize: 11,
      fontStyle: {
        fontWeight: "normal",
      },
    },
    margin: {
      top: 42,
    },
  });
  doc.save("invoice.pdf");
};
