import React from 'react';
import  { Button, Space, Table  } from 'antd';
import './styles.scss';

import { translate } from '../../utils/helpers';
import { BASE_URL } from '../../utils/http';

const AntTable = ({ data, column, onEdit, onDelete, onResetPassword, onDownload, expandableFormKeys, onChangeImage, onAddFile}) => {

  const expandable = {
    expandedRowRender: (record) => {
        return(
         <div className='antDescriptionBody'>
            <div className='container'>
                <div className='data'>{expandableFormKeys?.map((v) => ( <div key={v?.key}><strong>{v?.name}</strong><p>{record[v?.key]}</p></div> ))}</div>
                <Space className="site-button-ghost-wrapper">
                   { onDownload && record?.zips?.length > 0 ?
                      record?.zips?.map((v) => {
                        return(
                        <Button key={v?._id} onClick={() => {}} style={{ borderColor: "gray", color: "gray" }}  type="primary" ghost>
                            <a href={BASE_URL+"/"+v?.downloadLink}  download rel="noreferrer">{translate('forward_web_users_download_button')}</a>
                        </Button>
                      )})
                    : null}
                   { onEdit ? <Button onClick={() => onEdit(record)} type="primary" ghost>{translate('forward_web_users_edit_button')}</Button> : null}
                   { onResetPassword ? <Button onClick={() => onResetPassword(record)} type="primary" style={{ borderColor: "orange", color: "orange" }} ghost>{translate('forward_web_users_reset_password_button')}</Button> : null}
                   { onAddFile && record?.zips?.length <= 4 ? <Button onClick={() => onAddFile(record)} type="primary" ghost>{translate('forward_web_do_not_add_new_data_car_order_data_lot_upload')}</Button> : null}
                   { onDelete ? <Button onClick={() => onDelete(record)} type="primary" danger ghost>{translate('forward_web_users_delete_button')}</Button> : null}
                   { onChangeImage ? <Button onClick={() => onChangeImage(record)} type="primary" danger ghost>{translate('forward_web_info_home_auctions_cars_editor_image')}</Button> : null}
                </Space>
            </div>
         </div>
    )},
  };

  const tableColumns = column.map((item) => ({ ...item }));
  const tableProps = {
    loading: false,
    expandable,
    showHeader: true,
    rowSelection: false,
  };

  return (
      <Table
        rowClassName='highlight-bottom-border highlight-top-border'
        {...tableProps}
        scroll={{ x: 1920, y: 1200 }}
        pagination={{
          position: ['bottomRight'],
        }}
        columns={tableColumns}
        dataSource={data?.length ? data : []}
        key={(item) => item.id}
      />
  );
};

export default AntTable;
