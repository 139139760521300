export const AUTH_LOGIN_REQUEST = 'AUTH/LOGIN/REQUEST'
export const AUTH_LOGIN_SUCCESS = 'AUTH/LOGIN/SUCCESS'
export const AUTH_LOGIN_ERROR = 'AUTH/LOGIN/ERROR'

export const AUTH_GET_ALL_SERVICES_REQUEST = 'AUTH/GET_ALL_SERVICES/REQUEST'
export const AUTH_GET_ALL_SERVICES_SUCCESS = 'AUTH/GET_ALL_SERVICES/SUCCESS'
export const AUTH_GET_ALL_SERVICES_ERROR = 'AUTH/GET_ALL_SERVICES/ERROR'

export const AUTH_GET_ALL_CALC_CONVERSION_REQUEST = 'AUTH/GET_ALL_CALC_CONVERSION/REQUEST'
export const AUTH_GET_ALL_CALC_CONVERSION_SUCCESS = 'AUTH/GET_ALL_CALC_CONVERSION/SUCCESS'
export const AUTH_GET_ALL_CALC_CONVERSION_ERROR = 'AUTH/GET_ALL_CALC_CONVERSION/ERROR'

export const AUTH_GET_ALL_AUCTIONS_CARS_REQUEST = 'AUTH/GET_ALL_AUCTIONS_CARS/REQUEST'
export const AUTH_GET_ALL_AUCTIONS_CARS_SUCCESS = 'AUTH/GET_ALL_AUCTIONS_CARS/SUCCESS'
export const AUTH_GET_ALL_AUCTIONS_CARS_ERROR = 'AUTH/GET_ALL_AUCTIONS_CARS/ERROR'

export const AUTH_GET_ALL_PAGES_CONTENT_REQUEST = 'AUTH/GET_ALL_PAGES_CONTENT/REQUEST'
export const AUTH_GET_ALL_PAGES_CONTENT_SUCCESS = 'AUTH/GET_ALL_PAGES_CONTENT/SUCCESS'
export const AUTH_GET_ALL_PAGES_CONTENT_ERROR = 'AUTH/GET_ALL_PAGES_CONTENT/ERROR'

export const AUTH_GET_EXCHANGE_RATE_REQUEST = 'AUTH/GET_EXCHANGE_RATE/REQUEST'
export const AUTH_GET_EXCHANGE_RATE_SUCCESS = 'AUTH/GET_EXCHANGE_RATE/SUCCESS'
export const AUTH_GET_EXCHANGE_RATE_ERROR = 'AUTH/GET_EXCHANGE_RATE/ERROR'

export const AUTH_GET_LOCAL_PLACE_REQUEST = 'AUTH/GET_LOCAL_PLACE/REQUEST'
export const AUTH_GET_LOCAL_PLACE_SUCCESS = 'AUTH/GET_LOCAL_PLACE/SUCCESS'
export const AUTH_GET_LOCAL_PLACE_ERROR = 'AUTH/GET_LOCAL_PLACE/ERROR'

export const AUTH_GET_COPART_REQUEST = 'AUTH/GET_COPART/REQUEST'
export const AUTH_GET_COPART_SUCCESS = 'AUTH/GET_COPART/SUCCESS'
export const AUTH_GET_COPART_ERROR = 'AUTH/GET_COPART/ERROR'

export const AUTH_GET_SETTINGS_REQUEST = 'AUTH/GET_SETTINGS/REQUEST'
export const AUTH_GET_SETTINGS_SUCCESS = 'AUTH/GET_SETTINGS/SUCCESS'
export const AUTH_GET_SETTINGS_ERROR = 'AUTH/GET_SETTINGS/ERROR'

export const AUTH_LOGOUT = 'AUTH/LOGOUT'
export const AUTH_USER = 'AUTH/USER'
