import React, { useEffect, useState } from 'react';
import Button from '../../components/button';

import './styles.scss';
import Input from '../../components/input';
import AntTable from '../../components/antTable';
import {  optionsAccountStatus, optionsRole } from './data';
import { AddFormModal, antModalDelete } from '../../components/antModal';
import { useDispatch, useSelector } from 'react-redux';
import { addUsers, deleteUser, editUser, getAllUsers, resetPasswordUser } from '../../redux/app/actions';
import { systemAccountMgmt, translate } from '../../utils/helpers';
import { Backdrop, CircularProgress } from '@mui/material';

const Users = () => {
  const dispatch = useDispatch()
  // const lang = useContext(LanguageContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
  const isLoading = useSelector((state) => state.App.isLoading);
  const addUserResponse = useSelector((state) => state.App.addUser);
  const getUsers = useSelector((state) => state.App.getUsers);
  const addUserErrorResponse = useSelector((state) => state.App.errors.addUser);
  const editUsersSuccess = useSelector((state) => state.App.editUsers);
  const editUsersError = useSelector((state) => state.App.errors.editUsers);

  const resetUserPassSuccess = useSelector((state) => state.App.resetUserPass);
  const resetUserPassError = useSelector((state) => state.App.errors.resetUserPass);

  const [errorMessage, setErrorMessage] = useState('');
  const [isModalOpenResetPass, setIsModalOpenResetPass] = useState(false);
  const [showPass, setShowPass] = useState(false)
  const [currentUser, setCurrentUser] = useState({
      id: '',
      fullName: '',
      email: '',
      isUserActive: '',
      key: '',
      lastLogin: '',
      phone: '',
      notes: '',
      role: ''
  })
  const [editModalData, setEditModalData] = useState({
      id: '',
      fullName: '',
      email: '',
      phone: '',
      notes: '',
      role: ''
  })
  const [userResetPassword, setUserResetPassword] = useState({
    newPassword: ''
  })
  const [values, setValues] = useState({
    fullName: '',
    email: '',
    password: '',
    role: optionsRole[0]?.value,
    isUserActive: optionsAccountStatus[0]?.value,
  })

  const columnsUser = [
  {
    dataIndex: 'fullName',
    title: translate('forward_web_info_full_name'),
  },
  {
    dataIndex: 'email',
    title: translate("forward_web_info_email"),
  },
  {
    dataIndex: 'lastLogin',
    title: translate('forward_web_info_last_login'),
  },
  {
    dataIndex: 'role',
    title: translate('forward_web_info_role'),
  },
  {
    dataIndex: 'isUserActive',
    title: translate('forward_web_info_user_status'),
  },
];

  const onChangeValues = (key, value) => {
    setValues({ ...values, [key]: value })
  }
   const onChangeValuesEdit = (key, value) => {
    setEditModalData({ ...editModalData, [key]: value })
  }

  useEffect(() => {
      dispatch(getAllUsers())
  }, [dispatch])

  const onSubmit = () => {
    setErrorMessage('')
    if(!values?.email || !values?.password || !values?.fullName){
        setErrorMessage(translate('forward_web_please_fill_all_fields'))
    }else{
      dispatch(addUsers(values))
    }
  }

  const onConfirmReset = () => {
      setErrorMessage('')
      if(!userResetPassword?.newPassword){
          setErrorMessage(translate('forward_web_please_fill_new_password_require'))
      }else{
          dispatch(resetPasswordUser(userResetPassword))
      }
  }

  const dataUser = getUsers?.users?.length ? getUsers?.users?.map((v) =>({
    key: v?._id,
    id: v?._id,
    notes: v?.notes,
    fullName: v?.fullName,
    email: v?.email,
    phone: v?.phone,
    lastLogin: v?.lastLogin,
    role: v?.role,
    isUserActive: v?.isUserActive ? 'active' : 'deActive',
  })) : []

  useEffect(() => {
      if(addUserResponse === 'OK'){
            setIsModalOpen(false)
      }else if(addUserErrorResponse){
          const l = JSON.parse(addUserErrorResponse)
          setErrorMessage(l?.message)
      }
  }, [addUserResponse, addUserErrorResponse])

  useEffect(() => {
      if(resetUserPassSuccess === 'OK'){
            setIsModalOpenResetPass(false)
      }else if(resetUserPassError){
          const l = JSON.parse(resetUserPassError)
          setErrorMessage(l?.message)
      }
  }, [resetUserPassSuccess, resetUserPassError])

   useEffect(() => {
      if(editUsersSuccess?.updated){
          setIsModalOpenEdit(false)
      }else if(editUsersError){
          const l = JSON.parse(editUsersError)
          setErrorMessage(l?.message)
      }
  }, [editUsersSuccess, editUsersError])

  const onOkPress = (e) => {
    if(e?.email === systemAccountMgmt){
        alert(translate('forward_web_do_not_permission_this_task_delete'))
    }else{
       dispatch(deleteUser({
         userId: e?.id
    }))
    }
  }

  const odEditUser = (e) => {
      setCurrentUser(e)
      setEditModalData({
        id: e?.id,
        fullName: e?.fullName,
        email: e?.email,
        phone: e?.phone,
        notes: e?.notes,
        role: e?.role,
        // isUserActive: false //FIXME:
      })
  }

  const onConfirmEdit = () => {
      setErrorMessage('')
      const y = editModalData?.email === currentUser?.email
      const x = y ? {
          userId: editModalData?.id,
          fullName: editModalData?.fullName,
          phone: editModalData?.phone,
          notes: editModalData?.notes,
          role: editModalData?.role,
      } : {
          userId: editModalData?.id,
          fullName: editModalData?.fullName,
          email: editModalData?.email.replace(/\s/g, ""),
          phone: editModalData?.phone,
          notes: editModalData?.notes,
          role: editModalData?.role,
      }
      dispatch(editUser(x))
  }

  return (
    <div className="bodyUsers">
        {
        isLoading ?
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            onClick={() => {}}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        : null
      }
       <div className='usersHeader'>
         <h2>{translate('forward_web_logggedin_list_menu_users')}</h2>
         <div className='buttonContainer'>
            <Button name={'+ ' + translate('forward_web_users_add_button')} onClick={() => {
              setErrorMessage('')
              setIsModalOpen(true)
            }} style={{ backgroundColor: 'green',  }} />
         </div>
       </div>
       <div className='tableContainer'>
        <AntTable
          data={dataUser?.reverse()}
          column={columnsUser}
          onEdit={(e) => {
             setErrorMessage('')
             setIsModalOpenEdit(true)
             odEditUser(e)
          }}
          onDelete={(e) => antModalDelete(e?.fullName, () => onOkPress(e))}
          onResetPassword={(e) => {
            setUserResetPassword({
              ...userResetPassword,
              userId: e?.id
            })
            setErrorMessage('')
            setIsModalOpenResetPass(true)
          }}
          expandableFormKeys={[
            {name: 'id', key: 'id'},
            {name: translate("forward_web_info_phone"), key: 'phone'},
            {name: translate("forward_web_info_notes"), key: 'notes'},
          ]}
        />
       </div>
       <AddFormModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} title={translate('forward_web_users_add_button')}>
        <div className='formAdd'>
            <Input title={translate('forward_web_info_full_name')} placeholder={translate('forward_web_info_full_name')} onChange={(v) => onChangeValues('fullName', v.target.value)} value={values?.fullName} style={stylesInput}  />
            <Input title={translate("forward_web_info_email")} placeholder={translate("forward_web_info_email")} onChange={(v) => onChangeValues('email', v.target.value)} value={values?.email} style={stylesInput} />
            <Input title={translate("forward_web_info_passwors")} placeholder={translate("forward_web_info_passwors")} password type={showPass ? 'text' : 'password'} onChange={(v) => onChangeValues('password', v.target.value)} value={values?.password} onChangePassShow={() => setShowPass(!showPass)} style={stylesInput} />
            {errorMessage ? <p className='formErrorMessage'>{errorMessage}</p> : <br/>}
            <Button name={translate('forward_web_info_confirm_submit')} onClick={() => onSubmit()} style={{ backgroundColor: 'green', color: 'white'  }} />
        </div>
       </AddFormModal>
       <AddFormModal isModalOpen={isModalOpenEdit} setIsModalOpen={setIsModalOpenEdit} title={translate('forward_web_users_edit_button')}>
        <div className='formAdd'>
            <Input title={translate('forward_web_info_full_name')} placeholder={translate('forward_web_info_full_name')} onChange={(v) => onChangeValuesEdit('fullName', v.target.value)} value={editModalData?.fullName} style={stylesInput}  />
            <Input title={translate("forward_web_info_email")} placeholder={translate("forward_web_info_email")} onChange={(v) => onChangeValuesEdit('email', v.target.value)} value={editModalData?.email} style={stylesInput} disabled={ editModalData?.email === systemAccountMgmt ? true : false} />
            <Input title={translate("forward_web_info_phone")} placeholder='090-00-00-00' onChange={(v) => onChangeValuesEdit('phone', v.target.value)} value={editModalData?.phone} style={stylesInput} />
            <Input title={translate("forward_web_info_notes")} placeholder={translate("forward_web_info_notes")} onChange={(v) => onChangeValuesEdit('notes', v.target.value)} value={editModalData?.notes} style={stylesInput} />
            {/* <Input title='Role' placeholder='user or admin' onChange={(v) => onChangeValuesEdit('role', v.target.value)} value={editModalData?.role} style={stylesInput} /> */}
            {errorMessage ? <p className='formErrorMessage'>{errorMessage}</p> : <br/>}
            <Button name={translate('forward_web_info_confirm_submit')} onClick={() => onConfirmEdit()} style={{ backgroundColor: 'green', color: 'white'  }} />
        </div>
       </AddFormModal>
        <AddFormModal isModalOpen={isModalOpenResetPass} setIsModalOpen={setIsModalOpenResetPass} title={translate('forward_web_users_reset_password_button')}>
        <div className='formAdd'>
            <Input title={translate("forward_web_info_new_password")} placeholder={translate("forward_web_info_passwors")} password type={showPass ? 'text' : 'password'} onChange={(v) => setUserResetPassword({...userResetPassword, newPassword: v.target.value})} value={userResetPassword?.newPassword} onChangePassShow={() => setShowPass(!showPass)} style={stylesInput} />
            {errorMessage ? <p className='formErrorMessage'>{errorMessage}</p> : <br/>}
            <Button name={translate('forward_web_info_confirm_submit')} onClick={() => onConfirmReset()} style={{ backgroundColor: 'green'  }} />
        </div>
       </AddFormModal>
    </div>
  )
}

const stylesInput = { color: 'black' };

export default React.memo(Users)
