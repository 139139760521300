import React from "react";
import "./styles.scss";

import showPassIcon from "../../assets/showPass.png";

const Input = ({
  value,
  placeholder,
  type,
  onChange,
  error,
  title,
  onChangePassShow,
  password,
  style,
  disabled,
  maxLength,
}) => (
  <div className={error ? "bodyInputError" : "bodyInput"}>
    {title ? (
      <p className="inputTitle" style={style}>
        {title}
      </p>
    ) : null}
    <div className="inputContainer">
      <input
        className="input"
        style={style}
        value={value}
        placeholder={placeholder}
        type={type}
        onChange={onChange}
        disabled={disabled}
        maxLength={maxLength}
      />
      {password ? (
        <img
          src={showPassIcon}
          alt="x"
          className="showPassIcon"
          onClick={onChangePassShow}
        />
      ) : null}
    </div>
  </div>
);
export default React.memo(Input);
