import React, { useEffect, useState } from "react";
import Button from "../../components/button";

import "./styles.scss";
import Input from "../../components/input";
import AntTable from "../../components/antTable";
import { AddFormModal } from "../../components/antModal";
import Selector from "../../components/Selector";
import { translate } from "../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import {  addClientCarsRequest, deleteClientCar, editClientCarsRequest, getCLientCarsRequest } from '../../redux/app/actions';
import { Backdrop, CircularProgress } from "@mui/material";

const UserCars = () => {
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.Auth.accessToken);
  const onGetClientCarsList = useSelector((state) => state.App.getClientCarsList);
  const addClientCarMessage = useSelector((state) => state.App.addClientCars);
  const editClientCar = useSelector((state) => state.App.editClientCar);
  const profile = useSelector((state) => state.App.profile);
  const isLoading = useSelector((state) => state.App.isLoading);
  const [selectedCurrentUserId, setSelectedCurrentUserId] = useState('');
  const [submitEdit, setSubmitEdit] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadZipModalOpen, setUploadZipModalOpen] = useState(false);
  const [uploadFile, setUploadFile] = useState();
  const [uploadFileOrderId, setUploadFileOrderId] = useState();
  const maxFileSize = 96 * 1024 * 1024;
  const [values, setValues] = useState({
    key: '',
    id: '',
    userId: '',
    branch: '',
    userName: '',
    orderDate: '',
    shipingLine: '',
    bookingNumber: '',
    containerNumber: '',
    finalPort: '',
    consignee: '',
    saleOrigin: '',
    location: '',
    buyerCode: '',
    loadNumber: '',
    VIN: '',
    year: '',
    make: '',
    model: '',
    notes: '',
    zips: []
  });

  useEffect(() => {
      dispatch(getCLientCarsRequest())
  }, [dispatch])

  const userList = profile?._id ?  [{
    name:  profile?.fullName,
    value: profile?._id
  }] : null

  const dataCar = onGetClientCarsList?.clientCars?.length ? onGetClientCarsList?.clientCars?.map((v) => ({
        key: v?._id,
        id: v?._id,
        userId: v?.userId,
        branch: v?.branch,
        userName: profile?.fullName,
        userNameCurrentId: v?.userName,
        orderDate: v?.orderDate,
        shipingLine: v?.shipingLine,
        bookingNumber: v?.bookingNumber,
        containerNumber: v?.containerNumber,
        finalPort: v?.finalPort,
        consignee: v?.consignee,
        saleOrigin: v?.saleOrigin,
        location: v?.location,
        buyerCode: v?.buyerCode,
        loadNumber: v?.loadNumber,
        VIN: v?.VIN,
        year: v?.year,
        make: v?.make,
        model: v?.model,
        notes: v?.notes,
        zips: v?.zips
      })) : []


  const columnsCar = [
  {
    dataIndex: 'userName',
    title: translate('forward_web_logggedin_list_menu_users'),
  },
  {
    dataIndex: 'branch',
    title: translate('forward_web_do_not_add_new_data_car_branch'),
  },
  {
    dataIndex: 'orderDate',
    title: translate('forward_web_do_not_add_new_data_car_order_data'),
  },
   {
    dataIndex: 'shipingLine',
    title: translate('forward_web_do_not_add_new_data_car_order_data_shipp'),
  },
  {
    dataIndex: 'bookingNumber',
    title: translate('forward_web_do_not_add_new_data_car_order_data_booking'),
  },
  {
    dataIndex: 'containerNumber',
    title: translate('forward_web_do_not_add_new_data_car_order_data_container'),
  },
  {
    dataIndex: 'finalPort',
    title: translate('forward_web_do_not_add_new_data_car_order_data_final'),
  },
   {
    dataIndex: 'consignee',
    title: translate('forward_web_do_not_add_new_data_car_order_data_consignee'),
  },
  {
    dataIndex: 'saleOrigin',
    title: translate('forward_web_do_not_add_new_data_car_order_data_sale_origin'),
  },
  {
    dataIndex: 'location',
    title: translate('forward_web_do_not_add_new_data_car_order_data_location'),
  },
  {
    dataIndex: 'buyerCode',
    title: translate('forward_web_do_not_add_new_data_car_order_data_location'),
  },
  {
    dataIndex: 'loadNumber',
    title: translate('forward_web_do_not_add_new_data_car_order_data_lot_no'),
  }, {
    dataIndex: 'VIN',
    title: translate('forward_web_do_not_add_new_data_car_order_data_lot_vin'),
  }, {
    dataIndex: 'year',
    title: translate('forward_web_do_not_add_new_data_car_order_data_lot_year'),
  }, {
    dataIndex: 'make',
    title: translate('forward_web_do_not_add_new_data_car_order_data_lot_make'),
  }, {
    dataIndex: 'model',
    title: translate('forward_web_do_not_add_new_data_car_order_data_lot_model'),
  }, {
    dataIndex: 'notes',
    title: translate('forward_web_do_not_add_new_data_car_order_data_lot_notes'),
  }
];

  const onChangeValues = (key, value) => {
    setValues({ ...values, [key]: value });
  };

  const temapRequired =  [
      'userName',
      'orderDate',
      'finalPort',
      'consignee',
      'saleOrigin',
      'location',
      'buyerCode',
      'VIN',
      'year',
      'make',
  ];

  const onSubmit = () => {
    let alertShow = 0;

    temapRequired.map((key) => {
    // Object.keys(values).map((key) => {
        if(!values[key]){
            if(alertShow > 0 ) { return null }
            else {
              alertShow = 1
            }}
    })

    if(alertShow === 1){
       alertShow = 0
       alert(translate('forward_web_please_fill_required_fields'))
    }else{
        if(!submitEdit){
          dispatch(addClientCarsRequest(values, accessToken))
          setIsModalOpen(false)
        }else if(submitEdit){
          const x = {
            userId: values?.userId,
            branch: values?.branch,
            userNameCurrentId: values?.userNameCurrentId,
            orderDate: values?.orderDate,
            shipingLine: values?.shipingLine,
            bookingNumber: values?.bookingNumber,
            containerNumber: values?.containerNumber,
            finalPort: values?.finalPort,
            consignee: values?.consignee,
            saleOrigin: values?.saleOrigin,
            location: values?.location,
            buyerCode: values?.buyerCode,
            loadNumber: values?.loadNumber,
            VIN: values?.VIN,
            year: values?.year,
            make: values?.make,
            model: values?.model,
            notes: values?.notes,
          }
          dispatch(editClientCarsRequest(x, accessToken, values?.id))
          setIsModalOpen(false)
        }
    }

  };

  const onEditData = (e, edit) => {
    setSubmitEdit(edit?.edit)
    setValues(e)
    setIsModalOpen(true)
    setSelectedCurrentUserId(e?.userNameCurrentId);
  }

  useEffect(() => {
      if(isModalOpen === true && selectedCurrentUserId === ''){
          setSelectedCurrentUserId('default')
      }else if(isModalOpen === false){
        setSelectedCurrentUserId('default')
        setSubmitEdit(false)
      }
  }, [isModalOpen, values, selectedCurrentUserId])

  const onConfirmDelete = (e) => {
    dispatch(deleteClientCar(e))
  }

  const onUploadZip = (e) => {
    setUploadZipModalOpen(true)
    setUploadFileOrderId(e?.id)
  }

  const confirmUpload = () => {
    const file = uploadFile?.target?.files[0]?.size;
    if(file > maxFileSize){
        alert(translate('forward_web_please_fill_new_file_large_message') + ' ' + maxFileSize / 1024 / 1024 + 'MB')
    }else if(!file){
          alert(translate('forward_web_please_fill_new_file_large_message'))
    }else{
        const x = {
          zip: uploadFile?.target?.files[0]
        }
        dispatch(editClientCarsRequest(x, accessToken, uploadFileOrderId))
        setUploadFileOrderId()
        setUploadFile()
        setUploadZipModalOpen(false)

    }
  }

  return (
    <div className="bodyCars">
      {
        isLoading ?
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            onClick={() => {}}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        : null
      }
      <div className="carsHeader">
        <h2>{translate('forward_web_home_about_title_section_name_map_import_cars_history')} ( user )</h2>
        <div className="buttonContainer">
          <Button
            name={translate('forward_web_users_add_button')}
            onClick={() => {
              setValues({
                branch: '', userName: '', orderDate: '', shipingLine: '', bookingNumber: '', containerNumber: '', finalPort: '', consignee: '', saleOrigin: '', location: '', buyerCode: '', loadNumber: '', VIN: '', year: '', make: '', model: '', notes: '',
              })
              setIsModalOpen(true)
            }}
            style={{ backgroundColor: "green" }}
          />
        </div>
      </div>
      <div className="tableContainer">
        <AntTable
          data={dataCar.reverse()}
          column={columnsCar}
          expandableFormKeys={[
            { key: 'id', name: 'ID' },
            { key: 'VIN', name: 'VIN:' },
          ]}
          onEdit={(e) => onEditData(e, { edit: true })}
        //   onDelete={(e) => antModalDelete(e?.id, () => onConfirmDelete(e?.id))}
        //   onAddFile={(e) => onUploadZip(e)}
          onDownload={true}
        />
      </div>
      <AddFormModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        title={translate('forward_web_users_add_button')}
      >
        <div className="modalBodyAddCars">
          <Selector
            data={userList}
            title={translate('forward_web_logggedin_list_menu_users') + ' *'}
            onChange={(v) => {
              onChangeValues("userName", v.target.value)
              setSelectedCurrentUserId(v.target.value)
            }}
            style={stylesInput}
            selected={selectedCurrentUserId}
          />
          <Input
            title={translate('forward_web_do_not_add_new_data_car_branch')}
            placeholder={translate('forward_web_do_not_add_new_data_car_branch')}
            style={stylesInput}
            onChange={(v) => onChangeValues("branch", v.target.value)}
            value={values?.branch}
            />
          <Input
            title={translate('forward_web_do_not_add_new_data_car_order_data') + ' *'}
            placeholder={translate('forward_web_do_not_add_new_data_car_order_data')}
            style={stylesInput}
            onChange={(v) => onChangeValues("orderDate", v.target.value)}
            value={values?.orderDate}
          />
          <Input
            title={translate('forward_web_do_not_add_new_data_car_order_data_shipp')}
            placeholder={translate('forward_web_do_not_add_new_data_car_order_data_shipp')}
            style={stylesInput}
            onChange={(v) => onChangeValues("shipingLine", v.target.value)}
            value={values?.shipingLine}
          />
          <Input
             title={translate('forward_web_do_not_add_new_data_car_order_data_booking')}
            placeholder={translate('forward_web_do_not_add_new_data_car_order_data_booking')}
            style={stylesInput}
            onChange={(v) => onChangeValues("bookingNumber", v.target.value)}
            value={values?.bookingNumber}
          />
          <Input
            title={translate('forward_web_do_not_add_new_data_car_order_data_container')}
            placeholder={translate('forward_web_do_not_add_new_data_car_order_data_container')}
            style={stylesInput}
            onChange={(v) => onChangeValues("containerNumber", v.target.value)}
            value={values?.containerNumber}
          />
          <Input
            title={translate('forward_web_do_not_add_new_data_car_order_data_final') + ' *'}
            placeholder={translate('forward_web_do_not_add_new_data_car_order_data_final')}
            style={stylesInput}
            onChange={(v) => onChangeValues("finalPort", v.target.value)}
            value={values?.finalPort}
          />
          <Input
            title={translate('forward_web_do_not_add_new_data_car_order_data_consignee') + ' *'}
            placeholder={translate('forward_web_do_not_add_new_data_car_order_data_consignee')}
            style={stylesInput}
            onChange={(v) => onChangeValues("consignee", v.target.value)}
            value={values?.consignee}
          />
          <Input
             title={translate('forward_web_do_not_add_new_data_car_order_data_sale_origin') + ' *'}
            placeholder={translate('forward_web_do_not_add_new_data_car_order_data_sale_origin')}
            style={stylesInput}
            onChange={(v) => onChangeValues("saleOrigin", v.target.value)}
            value={values?.saleOrigin}
          />
          <Input
            title={translate('forward_web_do_not_add_new_data_car_order_data_location') + ' *'}
            placeholder={translate('forward_web_do_not_add_new_data_car_order_data_location')}
            style={stylesInput}
            onChange={(v) => onChangeValues("location", v.target.value)}
            value={values?.location}
            />
          <Input
            title={translate('forward_web_do_not_add_new_data_car_order_data_buyer_code') + ' *'}
            placeholder={translate('forward_web_do_not_add_new_data_car_order_data_buyer_code')}
            style={stylesInput}
            onChange={(v) => onChangeValues("buyerCode", v.target.value)}
            value={values?.buyerCode}
          />
          <Input
            title={translate('forward_web_do_not_add_new_data_car_order_data_lot_no')}
            placeholder={translate('forward_web_do_not_add_new_data_car_order_data_lot_no')}
            style={stylesInput}
            onChange={(v) => onChangeValues("loadNumber", v.target.value)}
            value={values?.loadNumber}
          />
          <Input
            title={translate('forward_web_do_not_add_new_data_car_order_data_lot_vin') + ' *'}
            placeholder={translate('forward_web_do_not_add_new_data_car_order_data_lot_vin')}
            style={stylesInput}
            onChange={(v) => onChangeValues("VIN", v.target.value)}
            value={values?.VIN}
          />
          <Input
            title={translate('forward_web_do_not_add_new_data_car_order_data_lot_year') + ' *'}
            placeholder={translate('forward_web_do_not_add_new_data_car_order_data_lot_year')}
            style={stylesInput}
            onChange={(v) => onChangeValues("year", v.target.value)}
            value={values?.year}
            />
          <Input
             title={translate('forward_web_do_not_add_new_data_car_order_data_lot_make') + ' *'}
            placeholder={translate('forward_web_do_not_add_new_data_car_order_data_lot_make')}
            style={stylesInput}
            onChange={(v) => onChangeValues("make", v.target.value)}
            value={values?.make}
            />
          <Input
            title={translate('forward_web_do_not_add_new_data_car_order_data_lot_model')}
            placeholder={translate('forward_web_do_not_add_new_data_car_order_data_lot_model')}
            style={stylesInput}
            onChange={(v) => onChangeValues("model", v.target.value)}
            value={values?.model}
            />
          <Input
            title={translate('forward_web_do_not_add_new_data_car_order_data_lot_notes')}
            placeholder={translate('forward_web_do_not_add_new_data_car_order_data_lot_notes')}
            style={stylesInput}  onChange={(v) => onChangeValues("notes", v.target.value)} value={values?.notes}/>
          <br />
          <Button
            name={translate('forward_web_info_confirm_submit')}
            onClick={() => onSubmit()}
            style={{ backgroundColor: "green", height: 40 }}
          />
        </div>
      </AddFormModal>
      <AddFormModal
        isModalOpen={uploadZipModalOpen}
        setIsModalOpen={setUploadZipModalOpen}
        title={translate('forward_web_users_add_button')}
      >
        <div className="modalBodyAddCars">
          <Input
            onChange={(v) =>  setUploadFile(v)}
            title={translate('forward_web_do_not_add_new_data_car_order_data_lot_upload') + ' ( max: 96 MB )'}
            placeholder="upload"
            type="file"
            style={stylesInput}
          />
          <br/>
          <Button
            name={translate('forward_web_info_confirm_submit')}
            onClick={() => confirmUpload()}
            style={{ backgroundColor: "green", height: 40 }}
          />
        </div>
      </AddFormModal>
    </div>
  );
};

const stylesInput = { color: "black" };

export default React.memo(UserCars);
