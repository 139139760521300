import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddFormModal, antModalDelete } from '../../components/antModal';
import AntTable from '../../components/antTable';
import Button from '../../components/button';
import { addServices, deleteServices, editServices } from '../../redux/app/actions';
import { getAllServices } from '../../redux/auth/actions'
import { translate } from '../../utils/helpers';
import Input from '../../components/input';

const ServiceEditor = ({ isOpenModal, onCloseModal }) => {
    const dispatch = useDispatch();
    const allServicesList = useSelector((state) => state.Auth.services);
    const [laodingCall, setLoadingCall] = useState(false);
    const [onOpenEditModal, setOnOpenEditModal] = useState(false);
    const [valuesEdit, setValuesEdit] = useState({
        serviceId: '',
            icon: "",
            name: { am: "", us: "", ru: "" },
            description: { am: "", us: "", ru: "" }
    })
    const [values, setValues] = useState({
        icon: "",
        name: { am: "", us: "", ru: "" },
        description: { am: "", us: "", ru: "" }
    })

    useEffect(() => {
        dispatch(getAllServices())
        setLoadingCall(false)
    }, [dispatch, laodingCall])

    const dataForServices = allServicesList?.length ? allServicesList?.map((v) => ({
        key: v?._id,
        id: v?._id,
        amTitle: v?.name?.am,
        ruTitle: v?.name?.ru,
        usTitle: v?.name?.us,
        amDescription: v?.description?.am,
        ruDescription: v?.description?.ru,
        usDescription: v?.description?.us
    })) : []

    const columnsServices = [
        {
            dataIndex: 'amTitle',
            title: translate('forward_web_info_content_editor_services_title') + ' AM',
        },
         {
            dataIndex: 'ruTitle',
            title: translate('forward_web_info_content_editor_services_title') + ' RU',
        },
         {
            dataIndex: 'usTitle',
            title: translate('forward_web_info_content_editor_services_title') + ' US',
        },
    ];

    const onConfirmDelete = (e) => {
        const x = { serviceId: e?.id }
        dispatch(deleteServices(x))
        setLoadingCall(true)
    }

    const onAddNewServices = () => {
        if(!values?.name?.am || !values?.name?.us || !values?.name?.ru || !values?.description?.am || !values?.description?.us || !values?.description?.ru){
            alert(translate('forward_web_please_fill_all_fields'))
        }else{
            dispatch(addServices(values))
            setLoadingCall(true)
            onCloseModal(false)
        }
    }

    const onOpenEdit = (e) => {
        setOnOpenEditModal(true)
        setValuesEdit({
                serviceId: e?.id,
                    icon: "",
                    name: { am: e?.amTitle, us: e?.usTitle, ru: e?.ruTitle },
                    description: { am: e?.amDescription, us: e?.usDescription, ru: e?.ruDescription }
        })
    }

    const onConfirmEditServices = () => {
         if(!valuesEdit?.name?.am || !valuesEdit?.name?.us || !valuesEdit?.name?.ru || !valuesEdit?.description?.am || !valuesEdit?.description?.us || !valuesEdit?.description?.ru){
            alert(translate('forward_web_please_fill_all_fields'))
        }else{
            dispatch(editServices(valuesEdit))
            setLoadingCall(true)
            setOnOpenEditModal(false)
        }
    }

return (
    <>
         <AddFormModal isModalOpen={onOpenEditModal}
             setIsModalOpen={(e) => setOnOpenEditModal(e)}
             title={translate('forward_web_users_edit_button')}>
            <div className='formAdd'>
                <Input title={translate('forward_web_info_content_editor_services_title') + ' AM'} onChange={(v) =>  setValuesEdit({ ...valuesEdit, name: {...valuesEdit?.name, am: v.target.value }})} value={valuesEdit?.name?.am} style={stylesInput}  />
                <Input title={translate('forward_web_info_content_editor_services_title') + ' RU'} onChange={(v) =>  setValuesEdit({ ...valuesEdit, name: {...valuesEdit?.name, ru: v.target.value }})} value={valuesEdit?.name?.ru} style={stylesInput}  />
                <Input title={translate('forward_web_info_content_editor_services_title') + ' US'} onChange={(v) =>  setValuesEdit({ ...valuesEdit, name: {...valuesEdit?.name, us: v.target.value }})} value={valuesEdit?.name?.us} style={stylesInput}  />
                <Input title={translate('forward_web_info_content_editor_services_description') + ' AM'} onChange={(v) =>  setValuesEdit({ ...valuesEdit, description: {...valuesEdit?.description, am: v.target.value }  })} value={valuesEdit?.description?.am} style={stylesInput}  />
                <Input title={translate('forward_web_info_content_editor_services_description') + ' RU'} onChange={(v) =>  setValuesEdit({ ...valuesEdit, description: {...valuesEdit?.description, ru: v.target.value }  })} value={valuesEdit?.description?.ru} style={stylesInput}  />
                <Input title={translate('forward_web_info_content_editor_services_description') + ' US'} onChange={(v) =>  setValuesEdit({ ...valuesEdit, description: {...valuesEdit?.description, us: v.target.value }  })} value={valuesEdit?.description?.us} style={stylesInput}  />
                <br/>
                <Button name={translate('forward_web_info_confirm_submit')} onClick={() => onConfirmEditServices()} style={{ backgroundColor: 'green', color: 'white'  }} />
            </div>
        </AddFormModal>
        <AddFormModal isModalOpen={isOpenModal}
             setIsModalOpen={(e) => onCloseModal(e)}
             title={translate('forward_web_users_add_button')}>
            <div className='formAdd'>
                <Input title={translate('forward_web_info_content_editor_services_title') + ' AM'} onChange={(v) =>  setValues({ ...values, name:{ ...values?.name, am: v.target.value }})} value={values?.name?.am} style={stylesInput}  />
                <Input title={translate('forward_web_info_content_editor_services_title') + ' RU'} onChange={(v) =>  setValues({ ...values, name:{ ...values?.name, ru: v.target.value }})} value={values?.name?.ru} style={stylesInput}  />
                <Input title={translate('forward_web_info_content_editor_services_title') + ' US'} onChange={(v) =>  setValues({ ...values, name:{ ...values?.name, us: v.target.value }})} value={values?.name?.us} style={stylesInput}  />
                <Input title={translate('forward_web_info_content_editor_services_description') + ' AM'} onChange={(v) =>  setValues({ ...values, description:{ ...values?.description, am: v.target.value }})} value={values?.description?.am} style={stylesInput}  />
                <Input title={translate('forward_web_info_content_editor_services_description') + ' RU'} onChange={(v) =>  setValues({ ...values, description:{ ...values?.description, ru: v.target.value }})} value={values?.description?.ru} style={stylesInput}  />
                <Input title={translate('forward_web_info_content_editor_services_description') + ' US'} onChange={(v) =>  setValues({ ...values, description:{ ...values?.description, us: v.target.value }})} value={values?.description?.us} style={stylesInput}  />
                <br/>
                <Button name={translate('forward_web_info_confirm_submit')} onClick={() => onAddNewServices()} style={{ backgroundColor: 'green', color: 'white'  }} />
            </div>
        </AddFormModal>
        <AntTable
            data={dataForServices}
            column={columnsServices}
            onEdit={(e) => onOpenEdit(e)}
            onDelete={(e) => antModalDelete(e?.id, () => onConfirmDelete(e))}
            expandableFormKeys={[
                {key: 'amDescription', name: translate('forward_web_info_content_editor_services_description') + ' AM'},
                {key: 'ruDescription', name: translate('forward_web_info_content_editor_services_description') + ' RU'},
                {key: 'usDescription', name: translate('forward_web_info_content_editor_services_description') + ' US'},
                {key: 'id', name: 'Id'},
            ]}
        />
    </>
  )
}
const stylesInput = { color: 'black' };

export default React.memo(ServiceEditor)
