import * as actions from './constants'

const initialState = {
  isLoading: false,
  profile: {},
  addUser:{},
  getUsers:{},
  editUsers:{},
  resetUserPass:{},
  addServices:{},
  addCalcConversion: {},
  getClientCarsList: {},
  addClientCars:{},
  deleteClientCar: {},
  editClientCar: {},
  errors: {
    profile: null,
    addUser: null,
    editUsers: null,
    resetUserPass: null,
    deleteClientCar: null
  }
}

export const AppReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.APP_EDIT_CLIENT_CARS_SUCCESS:
      return{
        ...state,
        editClientCar: action.payload
      }
    case actions.APP_DELETE_CLIENT_CARS_ERROR:
      return{
        ...state,
        errors: { deleteClientCar: null },
        deleteClientCar: {}
      }
    case actions.APP_DELETE_CLIENT_CARS_SUCCESS:
      return{
        ...state,
        deleteClientCar: action.payload
      }
    case actions.APP_ADD_CLIENT_CARS_SUCCESS:
      return{
        ...state,
        addClientCars: action.payload
      }
     case actions.APP_GET_CLIENT_CARS_SUCCESS:
      return {
        ...state,
        getClientCarsList: action.payload
      }
     case actions.APP_ADD_CALC_CONVERSION_SUCCESS:
      return {
        ...state,
        addCalcConversion: action.payload,
      }
     case actions.APP_ADD_SERVICES_SUCCESS:
      return {
        ...state,
        addServices: action.payload,
      }
     case actions.APP_RESET_PASSWORD_REQUEST:
      return {
        ...state,
       errors: { resetUserPass: null },
       resetUserPass: {},
      }
     case actions.APP_RESET_PASSWORD_ERROR:
      return {
        ...state,
       errors: { resetUserPass: action.error },
       resetUserPass: {},
      }
     case actions.APP_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetUserPass: action.payload,
        errors: { resetUserPass: null }
      }
     case actions.APP_EDIT_USERS_REQUEST:
      return {
        ...state,
       errors: { editUsers: null },
       editUsers: {},
      }
     case actions.APP_EDIT_USERS_ERROR:
      return {
        ...state,
       errors: { editUsers: action.error },
       editUsers: {},
      }
     case actions.APP_EDIT_USERS_SUCCESS:
      return {
        ...state,
        errors: { editUsers: null },
        editUsers: action.payload
      }
     case actions.APP_GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        getUsers: action.payload,
      }
    case actions.APP_ADD_USERS_SUCCESS:
      return {
        ...state,
        addUser: action.payload,
        errors: { addUser: null }
      }
    case actions.APP_ADD_USERS_ERROR :
      return {
        ...state,
        errors: { addUser: action.error },
        addUser: {},
      }
    case actions.APP_GET_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload,
        errors: { Profile: null }
      }
    case actions.APP_GET_PROFILE_ERROR:
      return {
        ...state,
        errors: { Profile: action.error }
      }
    case actions.APP_CHANGE_LOADING_STATE:
      return { ...state,
        isLoading: action.payload };
    case actions.APP_CLEAR_STATE:
      return initialState;
    default:
      return state
  }
}
