import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddFormModal, antModalDelete } from '../../components/antModal';
import AntTable from '../../components/antTable';
import Button from '../../components/button';
import { addCalcConversion, deleteCalcConversion, editCalcConversion } from '../../redux/app/actions';
import { getAllCalculatorConversion, getLocalPlace } from '../../redux/auth/actions'
import { translate } from '../../utils/helpers';
import Input from '../../components/input';
import Selector from "../../components/Selector";

const CalcEditor = ({ isOpenModal, onCloseModal }) => {
    const dispatch = useDispatch();
    const calculatorConversion = useSelector((state) => state.Auth.calculatorConversion);
    const localPLace = useSelector((state) => state.Auth.localPlace);
    const [laodingCall, setLoadingCall] = useState(false);
    const [onOpenEditModal, setOnOpenEditModal] = useState(false);
    const [selectList, setSelectList] = useState([]);
    const [selectedList, setSelectedList] = useState('default');
    const [valuesEdit, setValuesEdit] = useState({
        id: '',
        name: '',
        price: '',
        localPlaceId: ''
    })
    const [values, setValues] = useState({
        name: '',
        price: '',
        localPlaceId: ''
    })
     const findPirce = (id) => {
        const x = localPLace?.localPlace?.find((v) => v?._id === id)
        if(x?.price){
            return x
        }else{
            return {
                name: 'default',
                price: 0
            }
        }
    }

    useEffect(() => {
        setTimeout(() => {
            dispatch(getAllCalculatorConversion())
            dispatch(getLocalPlace())
            setLoadingCall(false)
        }, 900);
    }, [dispatch, laodingCall])

    const dataForCalc = calculatorConversion?.length ? calculatorConversion?.map((v) => ({
        key: v?._id,
        id: v?._id,
        name: v?.name,
        price: v?.price,
        localPlaceId: v?.localPlaceId,
        priceTransfer: findPirce(v?.localPlaceId)?.name + ': ' + findPirce(v?.localPlaceId)?.price
    })) : []

    const columnsCalc = [
        {
            dataIndex: 'name',
            title: translate('forward_web_info_content_editor_country_name_shipping'),
        },
        {
            dataIndex: 'price',
            title: translate('forward_web_info_content_editor_country_price_shipping'),
        },
        {
            dataIndex: 'priceTransfer',
            title: translate('forward_web_info_content_editor_country_name_shipping') + ' / ' +translate("forward_web_info_content_editor_country_price_shipping"),
        },
        {
            dataIndex: 'localPlaceId',
            title: 'local Place id',
        }
    ];

    const onConfirmDelete = (e) => {
        const x = { id: e?.id }
        dispatch(deleteCalcConversion(x))
        setLoadingCall(true)
    }

    const onAddNewConversion = () => {
        if(!values?.name || !values?.price || !selectedList){
            alert(translate('forward_web_please_fill_all_fields'))
        }else{
            const x = {
              name: values?.name,
              price: values?.price,
              localPlaceId: selectedList
            }
            dispatch(addCalcConversion(x))
            setLoadingCall(true)
            onCloseModal(false)
            setValues({
                name: '',
                price: '',
                setSelectedList: ''
            })
        }
    }

    const onOpenEdit = (e) => {
        setOnOpenEditModal(true)
        setSelectedList(e?.localPlaceId)
        setValuesEdit({
            id: e?.id,
            name: e?.name,
            price: e?.price,
            localPlaceId: e?.localPlaceId
        })
    }

    const onConfirmEditServices = () => {
         if(!valuesEdit?.name || !valuesEdit?.price){
            alert(translate('forward_web_please_fill_all_fields'))
        }else{
            const x = {
                id: valuesEdit?.id,
                name: valuesEdit?.name,
                price: valuesEdit?.price,
                localPlaceId: selectedList ? selectedList : valuesEdit?.localPlaceId
            }
            dispatch(editCalcConversion(x))
            setLoadingCall(true)
            setOnOpenEditModal(false)
        }
    }

    useEffect(() => {
       if(localPLace?.localPlace?.length > 0){
         const x = localPLace?.localPlace?.map((v) => ({
            id: v?._id,
            name: v?.name,
            value: v?._id
        }))
        setSelectList(x)
       }
    }, [localPLace])

return (
    <>
         <AddFormModal isModalOpen={onOpenEditModal}
             setIsModalOpen={(e) => setOnOpenEditModal(e)}
             title={translate('forward_web_users_edit_button')}>
            <div className='formAdd'>
                <Input title={translate('forward_web_info_content_editor_country_name_shipping')} onChange={(v) =>  setValuesEdit({ ...valuesEdit, name:  v.target.value })} value={valuesEdit?.name} style={stylesInput}  />
                <Input title={translate('forward_web_info_content_editor_country_price_shipping')} onChange={(v) =>  setValuesEdit({ ...valuesEdit, price:  Number(v.target.value) })} value={valuesEdit?.price} style={stylesInput} type='number'  />
                <Selector
                    data={selectList}
                    title={translate('forward_web_info_content_editor_country_name_shipping')}
                    onChange={(v) => {
                        setSelectedList(v.target.value)
                    }}
                    style={stylesInput}
                    selected={selectedList}
                />
                <Input disabled={true} title={translate('forward_web_calculator_car_service_fee')} value={findPirce(selectedList)?.price} style={stylesInput}  />
                <br/>
                <Button name={translate('forward_web_info_confirm_submit')} onClick={() => onConfirmEditServices()} style={{ backgroundColor: 'green', color: 'white'  }} />
            </div>
        </AddFormModal>
        <AddFormModal isModalOpen={isOpenModal}
             setIsModalOpen={(e) => onCloseModal(e)}
             title={translate('forward_web_users_add_button')}>
            <div className='formAdd'>
                <Input title={translate('forward_web_info_content_editor_country_name_shipping')} onChange={(v) =>  setValues({ ...values, name: v.target.value })} value={values?.name} style={stylesInput}  />
                <Input title={translate('forward_web_info_content_editor_country_price_shipping')} onChange={(v) =>  setValues({ ...values, price: Number(v.target.value) })} value={values?.price} style={stylesInput} type='number'  />
                <Selector
                    data={selectList}
                    title={translate('forward_web_info_content_editor_country_name_shipping')}
                    onChange={(v) => {
                        setSelectedList(v.target.value)
                    }}
                    style={stylesInput}
                    selected={selectedList}
                />
                <Input disabled={true} title={translate('forward_web_calculator_car_service_fee')} value={findPirce(selectedList)?.price} style={stylesInput}  />
                <br/>
                <Button name={translate('forward_web_info_confirm_submit')} onClick={() => onAddNewConversion()} style={{ backgroundColor: 'green', color: 'white'  }} />
            </div>
        </AddFormModal>
        <AntTable
            data={dataForCalc}
            column={columnsCalc}
            onEdit={(e) => onOpenEdit(e)}
            onDelete={(e) => antModalDelete(e?.id, () => onConfirmDelete(e))}
            expandableFormKeys={[
                {key: 'id', name: 'Id'},
            ]}
        />
    </>
  )
}
const stylesInput = { color: 'black' };

export default React.memo(CalcEditor)
