/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/button';
import { translate } from '../../utils/helpers';
import { AddFormModal } from '../../components/antModal';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { getSettings } from '../../redux/auth/actions';
import { changeSettings } from '../../redux/app/actions';

const Settings = ({ isOpenModal, onCloseModal }) => {
    const dispatch = useDispatch();
    const settingsData = useSelector((state) => state.Auth.settings);
    const filteredData = settingsData?.length  ?  settingsData?.filter((v) => v.settingsName === 'calculator') : []
    const [check, setCheck] = useState({
        copart: false,
        iaai: false
    })
    useEffect(() => {
         setTimeout(() => {
            dispatch(getSettings())
         }, 2);
    }, [])

    useEffect(() => {
        if(filteredData[0]?.configs){
            setCheck({
                iaai: filteredData[0]?.configs?.iaai,
                copart: filteredData[0]?.configs?.copart
             })
        }
    }, [settingsData])

    const handleChange = (key, value) => {
        setCheck(check => ({
            ...check,
            [key]: value
        }))
    }

    const handleChangeConfigs = () => {
        const d = {
            configs: check
        }
        dispatch(changeSettings(filteredData[0]?._id, d))
        setTimeout(() => {
            dispatch(getSettings())
        }, 500);
    }
    return (
        <div style={{ padding: 16, minHeight: '60vh' }}>
            <div style={{
                padding: 16,
                maxWidth: 330,
                borderWidth: 2,
                borderStyle: 'solid',
                borderColor: 'black',
            }}>
                <h4>{translate('forward_web_settings_page_switch_button_change_settings_show_copart_iaai_title')}</h4>
                <FormGroup>
                    <FormControlLabel control={<Switch checked={check.iaai}  onChange={(e) => handleChange('iaai', e.target.checked)} /> } label={translate('forward_web_settings_page_switch_button_change_settings_show_iaai')} />
                    <FormControlLabel control={<Switch checked={check.copart} onChange={(e) => handleChange('copart', e.target.checked)}/> } label={translate('forward_web_settings_page_switch_button_change_settings_show_copart')}/>
                    <br/>
                    <Button name={translate('forward_web_settings_page_title_button_change_settings')} onClick={() => handleChangeConfigs()} style={{ backgroundColor: 'green', color: 'white'  }} />
                </FormGroup>
            </div>
            <AddFormModal isModalOpen={isOpenModal}
                setIsModalOpen={(e) => onCloseModal(e)}
                title={translate('forward_web_users_add_button')}>
                <div className='formAdd'>
                    <p>{translate('forward_web_do_not_add_new_data')}</p>
                </div>
            </AddFormModal>
        </div>
    )
}

export default React.memo(Settings)
