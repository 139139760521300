import React, { useContext, useEffect, useRef, useState } from 'react'

import variables from '../../styles/variables.scss'
import './styles.scss'
import { loggedIn_nav_bar_list, loggedIn_nav_bar_list_user, nav_bar_list } from '../../utils/arrays'
import Button from '../button'
import LangFlagsSelected from '../langFlagsSelected'
import { useNavigate } from 'react-router-dom'
import { getLocale, translate } from '../../utils/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../redux/auth/actions'
import { createBrowserHistory } from 'history'
import StorageUtils from '../../utils/storage'
import { RU } from '../../utils/langConstatnts'
import { LanguageContext } from '../../utils/languageContext'

const Burger = ({ isOpen, onClose }) => {
  const dispatch = useDispatch()
  const history = createBrowserHistory();
  const navigate = useNavigate()
  const [selected, setSelected] = useState('')
  const { setLang } = useContext(LanguageContext);
  const token = useSelector((state) => state.Auth.accessToken);
  const profile = useSelector((state) => state.App.profile);
  const windowSize = useRef([window.innerWidth, window.innerHeight])

  useEffect(() => {
    (async () => {
      const l = await getLocale();
      setSelected(l ? l?.toUpperCase() : RU);
      if (!l) {
        await StorageUtils.setLanguage(RU.toLowerCase());
        setLang(RU.toLowerCase());
      }
    })();
  }, []);

  const onSelect = async (l) => {
    setSelected(l);
    await StorageUtils.setLanguage(l.toLowerCase());
    setLang(l.toLowerCase());
  };

  const checkScreen = () => {
    if (windowSize.current[0] <= 640) {
      return '100%'
    } else if (windowSize.current[0] <= 1200) {
      return '44%'
    }
  }

  const onNav = (v) =>{
     if(v === 'logout'){
      dispatch(logout())
      history.push('/login');
      history.go(0);
     }
     navigate(v);
  }
  return (
    <div id="myNav" style={{ width: isOpen ? checkScreen() : null }} className="overlay">
        <p className="closebtn" onClick={onClose}>&times;</p>
        <div className="overlay-content">
          {
                token ?
                 <div className='navList'>
                  <div className='listContainer'>
                    {
                      profile?.role === 'admin' ?
                      loggedIn_nav_bar_list?.map((v) => {
                        return(
                          <div key={v.name} onClick={() => {
                              onNav(v?.navLink);
                              onClose();
                          }}>
                              <img className='iconListContainer' src={require(`../../assets/icons/${v.icon}`)} alt='icon' />
                              <p>{translate(v?.name)}</p>
                          </div>
                      )})
                      : loggedIn_nav_bar_list_user?.map((v) => {
                        return(
                          <div key={v.name} onClick={() => {
                              onNav(v?.navLink);
                              onClose();
                          }}>
                              <img className='iconListContainer' src={require(`../../assets/icons/${v.icon}`)} alt='icon' />
                              <p>{translate(v?.name)}</p>
                          </div>
                      )})
                    }
                    </div>
                  </div> : null
          }
          <div className='navList'>
          {
            nav_bar_list?.map((v) => (
              <p key={v.name} onClick={() => {
                onClose()
                navigate(v.path)
              }} className={ window.location.pathname === '/' + v.path ? 'headerSectionSelected' : 'headerSection'}>{translate(v.name)}</p>
            ))
          }
          <br/>
           <LangFlagsSelected
                burger
                selected={selected}
                onSelect={onSelect}
            />
          </div>
         <div className='buttonContainer'>
            { !token ?
             <Button name='Login' onClick={() => {
               onClose()
               navigate('login')
             }}
                style={{
                  backgroundColor: variables.dustRed,
                  borderColor: variables.primaryWhite
                }} /> : null }
         </div>
        </div>
    </div>
  )
}
export default React.memo(Burger)
