import React from "react";
import "./styles.scss";

const Selector = ({ title, data, onChange, style, selected, optionStyle, defaultTitle }) => {
  return (
    <div className="selectorBody">
      { title ? <p style={style} className="title">{title}</p> : null }
      <select className="selector" onChange={onChange} style={style} value={selected}>
        <option disabled value='default'>{defaultTitle ? defaultTitle : "-- select an option --"}</option>
        {data?.map((item, index) => {
          return (
            <option
              className="option"
              style={optionStyle ? optionStyle : null}
              value={item.value}
              key={index}
            >{item.name}</option>
          );
        })}
      </select>
    </div>
  );
};

export default React.memo(Selector);
